<template>
	<b-card>
		<template slot="header">xPhy</template>
        <div class="text-center" v-b-tooltip.hover title="Dato non disponibile per il Giocatore / Competizione di riferimento">
			<Progress :transitionDuration="1000" :radius="70" :strokeWidth="20" :value="0" strokeColor="#999999">
				<div class="content" style="font-size:2rem"> N/A </div>
				<template v-slot:footer>
					<span class="text-muted small">- / -</span>
				</template>
			</Progress>
		</div>
	</b-card>
</template>

<script>

import Progress from "easy-circular-progress";

export default {
	components: { Progress },
	computed: {},
}

</script>