<template>
	<div>
		<b-nav vertical>
			<b-nav-item :to="{ name: 'PlayerPersonaDashboard' }" :style="isSelected('PlayerPersonaDashboard') ? navActiveStyle : ''">
				Dashboard
			</b-nav-item>
			
			<b-nav-item 
				:to="{ name: 'PlayerPersonaAnagraficaGenerale', query: { tab: 'anagrafica-generale' } }"  
				:style="isSelected('PlayerPersonaAnagraficaGenerale', 'anagrafica-generale') ? navActiveStyle : ''"
			>
				Anagrafica Generale
			</b-nav-item>
			
			<b-nav-item 
				:to="{ name: 'PlayerPersonaAnagraficaGenerale', query: { tab: 'body-metrics' } }" 
				:style="isSelected('PlayerPersonaAnagraficaGenerale', 'body-metrics') ? navActiveStyle : ''"
			>
				Body Metrics
			</b-nav-item>
			
			<b-nav-item 
				:to="{ name: 'PlayerPersonaAnagraficaGenerale', query: { tab: 'carriera' } }" 
				:style="isSelected('PlayerPersonaAnagraficaGenerale', 'carriera') ? navActiveStyle : ''"
			>
				Carriera
			</b-nav-item>
			
			<b-nav-item 
				:to="{ name: 'PlayerPersonaAnagraficaGenerale', query: { tab: 'dati-economici' } }" 
				:style="isSelected('PlayerPersonaAnagraficaGenerale', 'dati-economici') ? navActiveStyle : ''"
			>
				Dati Economici
			</b-nav-item>
						
			<b-nav-item 
				:to="{ name: 'PlayerPersonaAnagraficaGenerale', query: { tab: 'providers' } }" 
				:style="isSelected('PlayerPersonaAnagraficaGenerale', 'providers') ? navActiveStyle : ''"
			>
				Providers
			</b-nav-item>

			<b-nav-item 
				:to="{ name: 'PlayerPersonaAnagraficaGenerale', query: { tab: 'documenti' } }" 
				:style="isSelected('PlayerPersonaAnagraficaGenerale', 'documenti') ? navActiveStyle : ''"
				v-if="this.auth.isUserEnable('scout_edit_player') || auth.isUserEnable('rosa_tab_documenti')"
			>
				Documenti
			</b-nav-item>
			
			<b-nav-item 
				:to="{ name: 'PlayerPersonaAnagraficaGenerale', query: { tab: 'parenti' } }" 
				:style="isSelected('PlayerPersonaAnagraficaGenerale', 'parenti') ? navActiveStyle : ''"
				v-if="this.auth.isUserEnable('scout_edit_player') || auth.isUserEnable('rosa_tab_parenti')"
			>
				Parenti
			</b-nav-item>
			
			<b-nav-item 
				:to="{ name: 'PlayerPersonaAnagraficaGenerale', query: { tab: 'player-access' } }" 
				:style="isSelected('PlayerPersonaAnagraficaGenerale', 'player-access') ? navActiveStyle : ''"
				v-if="this.auth.isUserEnable('scout_edit_player') || auth.isUserEnable('rosa_tab_player_access')"
			>
				Player Access
			</b-nav-item>
			
			<b-nav-item 
				:to="{ name: 'PlayerPersonaAnagraficaGenerale', query: { tab: 'whiteboard' } }" 
				:style="isSelected('PlayerPersonaAnagraficaGenerale', 'whiteboard') ? navActiveStyle : ''"
				v-if="this.auth.isUserEnable('scout_edit_player') || auth.isUserEnable('rosa_tab_whiteboard')"
			>
				Whiteboard
			</b-nav-item>
			
			<b-nav-item
				:to="{ name: 'PlayerPersonaAnagraficaGenerale', query: { tab: 'medical' } }" 
				:style="isSelected('PlayerPersonaAnagraficaGenerale', 'medical') ? navActiveStyle : ''"
				v-if="this.auth.isUserEnable('scout_edit_player') || auth.isUserEnable('rosa_tab_medical')"
			>
				Medical
			</b-nav-item>
			
			<b-nav-item 
				:to="{ name: 'PlayerPersonaAnagraficaGenerale', query: { tab: 'lingue' } }" 
				:style="isSelected('PlayerPersonaAnagraficaGenerale', 'lingue') ? navActiveStyle : ''"
				v-if="this.auth.isUserEnable('scout_edit_player') || auth.isUserEnable('rosa_tab_lingue')"
			>
				Lingue
			</b-nav-item>
		
		</b-nav>
	</div>
</template>

<script>

const Auth = require('../../../auth.js');

export default {
	data: function() {
		return {
			navActiveStyle: 'background-color: white; border-top-left-radius: 10px; border-bottom-left-radius: 10px;'
		}
	},
	methods: {
		isSelected: function(name, tab = null) {
			return name === this.$route.name && (tab === null || this.$route.query.tab === tab);
		}
	},
	computed: {
		auth: function() {
            return Auth;
        },
	}
}

</script>