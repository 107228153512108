<template>
	<b-overlay :show="xPhysicalIndexes === null || xTechnicalIndexes === null || loading">
			<!-- Select Role -->
			<b-row class="justify-content-center mb-4">
				<b-col cols="8">
					<div class="text-center mb-1 font-bold">
						{{ selectedMaxPercRole ? selectedMaxPercRole.ruolo : '' }}
					</div>
					<div class="text-center">
						<b-form-select 
							class="w-50"
							v-model="season_ruoli_sel" 
							:options="filtered_season_ruoli_options" 
							v-if="filtered_season_ruoli_options && filtered_season_ruoli_options.length" size="sm"
						>
						</b-form-select>
					</div>
				</b-col>
				<b-col cols="4"></b-col>
			</b-row>

			<!-- Graphs -->
			<b-row>
				<!-- Xtech -->
				<b-col cols="4" v-if="xTechnical" @click="openXTech" style="cursor:pointer;">
					<TechnicalIndex :dataset="xTechnical"></TechnicalIndex>
				</b-col>
				<b-col cols="4" v-else>
					<EmptyTechnicalIndex />
				</b-col>
				
				<!-- XPhy -->
				<b-col cols="4" v-if="xPhysical" @click="openXPhy" style="cursor:pointer;">
					<PhysicalIndex :dataset="xPhysical"></PhysicalIndex>
				</b-col>
				<b-col cols="4" v-else>
					<EmptyPhysicalIndex />
				</b-col>

				<b-col cols="4" v-if="player">
					<DoughnutValutazioni
						:key="doughnutValutazioniRefresh"
						@openEvaluation="openEvaluationDetail"
						:playerId="player.persona.id"
					/>
				</b-col>
			</b-row>

			<!-- <div v-if="xTechnical && xPhysical"> -->
				<!-- Show Technical Index Details -->
				<b-card v-if="showTechIndexDetail" class="mt-3">
					<template slot="header">{{ _.startCase(_.replace(xTechnical.gruppo, /\_/g, ' ')) }}</template>
					<b-row>
						<b-col cols="4">
							<b-card style="height:50vh;overflow-y:scroll;">
								<b-table-simple small>
									<b-tbody>
										<b-tr v-for="rank, i in xTechnical.ranking" :variant=" rank.persona.id == player.persona.id ? 'warning' : ''">
											<b-td style="vertical-align: middle;">{{ i + 1 }}</b-td>
											<b-td style="vertical-align: middle;">
												<router-link :to="{ name: 'PlayerPersonaDashboard', params: { persona_id: rank.persona.id }}" target="_blank" style="text-decoration: none;color:#212529;">
													<template v-if="rank.persona">
														<b-img :src="rank.persona.avatar_url" :height="40"></b-img> 
													</template>
													<template v-if="rank.squadra">
														<b-img :src="rank.squadra.logo_url" :height="20"></b-img>
													</template>
													{{ rank.persona.cognome }} {{ rank.persona.nome }}
												</router-link>
											</b-td>
											<b-td style="vertical-align: middle;">{{ rank.xTech }}</b-td>
										</b-tr>
									</b-tbody>
								</b-table-simple>
							</b-card>
						</b-col>	
						<b-col>
							<b-card style="height:50vh;overflow-y:scroll;">
								<b-table-simple small>
									<b-thead>
										<b-tr>
											<b-th>Category</b-th>
											<b-th>Statistic</b-th>
											<b-th class="text-center">Bracket</b-th>
											<b-th class="text-center">Value</b-th>
											<b-th class="text-center">Points</b-th>
										</b-tr>
									</b-thead>
									<b-tbody>
										<b-tr v-for="stat, kpi, idx in statsXTech" v-if="typeof stat === 'object'">
											<b-td>{{ getLabelCategory(_.values(_.cloneDeep(statsXTech)), idx) }}</b-td>
											<b-td v-b-popover.hover.top="{ html: true, content: legendHtml(stat) }" style="cursor:pointer;">{{ stat.label }}</b-td>
											<b-td class="text-center">{{ getBracket(stat.range, stat.rangeIndex) }}</b-td>
											<b-td class="text-center">{{ stat.value }}</b-td>
											<b-td class="text-center">{{ stat.points }}/{{ stat.maxPoints }}</b-td>
										</b-tr>
									</b-tbody>
									<b-tfoot>
										<b-tr>
											<b-th colspan="4" class="text-right">Total of points</b-th>
											<b-th class="text-center">{{ statsXTech.totalPoint }}</b-th>
										</b-tr>
										<b-tr>
											<b-th colspan="4" class="text-right">Total maximum</b-th>
											<b-th class="text-center">{{ statsXTech.totalMaximum }}</b-th>
										</b-tr>
										<b-tr>
											<b-th colspan="4" class="text-right">Calculation of the index</b-th>
											<b-th class="text-center">({{ statsXTech.totalPoint }} / {{ statsXTech.totalMaximum }}) x 100</b-th>
										</b-tr>
										<b-tr>
											<b-th colspan="4" class="text-right">xTech</b-th>
											<b-th class="text-center">{{ statsXTech.xTech }}</b-th>
										</b-tr>
									</b-tfoot>
								</b-table-simple>	
							</b-card>	
						</b-col>
					</b-row>
				</b-card>
				
				<!-- Show Physical Index Details -->
				<b-card v-if="showPhyIndexDetail" class="mt-3">
					<template slot="header">{{ _.startCase(_.replace(xPhysical.gruppo, /\_/g, ' ')) }}</template>
					<b-row>
						<b-col cols="4">
							<b-card style="height:50vh;overflow-y:scroll;">
								<b-table-simple small>
									<b-tbody>
										<b-tr v-for="rank, i in xPhysical.ranking" :variant=" rank.persona.id == player.persona.id ? 'warning' : ''">
											<b-td style="vertical-align: middle;">{{ i + 1 }}</b-td>
											<b-td style="vertical-align: middle;"><router-link :to="{ name: 'PlayerPersonaDashboard', params: { persona_id: rank.persona.id }}" target="_blank" style="text-decoration: none;color:#212529;"><b-img :src="rank.persona.avatar_url" :height="40"></b-img> {{ rank.persona.cognome }} {{ rank.persona.nome }}</router-link></b-td>
											<b-td style="vertical-align: middle;">{{ rank.xPhy }}</b-td>
										</b-tr>
									</b-tbody>
								</b-table-simple>
							</b-card>
						</b-col>	
						<b-col>
							<b-card style="height:50vh;overflow-y:scroll;">
								<b-table-simple small>
									<b-thead>
										<b-tr>
											<b-th>Category</b-th>
											<b-th>Statistic</b-th>
											<b-th class="text-center">Bracket</b-th>
											<b-th class="text-center">Value</b-th>
											<b-th class="text-center">Points</b-th>
										</b-tr>
									</b-thead>
									<b-tbody>
										<b-tr v-for="stat, kpi, idx in statsXPhy" v-if="typeof stat === 'object'">
											<b-td>{{ getLabelCategory(_.values(_.cloneDeep(statsXPhy)), idx) }}</b-td>
											<b-td v-b-popover.hover.top="{ html: true, content: legendHtml(stat) }" style="cursor:pointer;">{{ stat.label }}</b-td>
											<b-td class="text-center">{{ getBracket(stat.range, stat.rangeIndex) }}</b-td>
											<b-td class="text-center">{{ stat.value }}</b-td>
											<b-td class="text-center">{{ stat.points }}/{{ stat.maxPoints }}</b-td>
										</b-tr>
									</b-tbody>
									<b-tfoot>
										<b-tr>
											<b-th colspan="4" class="text-right">Total of points</b-th>
											<b-th class="text-center">{{ statsXPhy.totalPoint }}</b-th>
										</b-tr>
										<b-tr>
											<b-th colspan="4" class="text-right">Total maximum</b-th>
											<b-th class="text-center">{{ statsXPhy.totalMaximum }}</b-th>
										</b-tr>
										<b-tr>
											<b-th colspan="4" class="text-right">Calculation of the index</b-th>
											<b-th class="text-center">({{ statsXPhy.totalPoint }} / {{ statsXPhy.totalMaximum }}) x 100</b-th>
										</b-tr>
										<b-tr>
											<b-th colspan="4" class="text-right">xPhy</b-th>
											<b-th class="text-center">{{ statsXPhy.xPhy }}</b-th>
										</b-tr>
									</b-tfoot>
								</b-table-simple>	
							</b-card>	
						</b-col>
					</b-row>
				</b-card>
				
				<!-- Show Evaluation Details -->
				<b-card v-if="showEvalutaionDetail" class="mt-3">
					<template slot="header">
						<div class="d-flex justify-content-between align-items-center">
							<div>
								Valutazioni da {{ evaluationFilters.total_from }} - {{ evaluationFilters.total_to }}
							</div> 
							<div class="text-right my-1">
								<b-button size="sm" @click.stop="showEvalutaionDetail = false">
									Chiudi
								</b-button>
							</div>
						</div>
					</template>
					<div>
						<PageValutazioni 
							@evalutationDeleted="doughnutValutazioniRefresh++"
							:player="player" 
							:evaluationFilters="evaluationFilters" 
						/>
					</div>
				</b-card>
			<!-- </div> -->
	</b-overlay>
</template>

<script>

export default {
	components: {
		PhysicalIndex: require('@/components/v2/Player/comp-xPhysicalIndex.vue').default,
		TechnicalIndex: require('@/components/v2/Player/comp-xTechIndex.vue').default,
		EmptyPhysicalIndex: require('@/components/v2/Player/Chart/EmptyXPhysicalIndex.vue').default,
		EmptyTechnicalIndex: require('@/components/v2/Player/Chart/EmptyXTechnicalIndex.vue').default,
		DoughnutValutazioni: require('@/components/v2/Player/Chart/DoughnutQuantitaValutazioni.vue').default,
		PageValutazioni: require('@/components/v2/Player/page-valutazioni.vue').default
	},
	props: [ 'player' ],
	data: function() {
		return {
			xPhysicalSelected: null,
			xPhysicalIndexes: null,
			xTechnicalSelected: null,
			xTechnicalIndexes: null,
			showPhyIndexDetail: false,
			showTechIndexDetail: false,
			showEvalutaionDetail: false,
			evaluationFilters: null,
			doughnutValutazioniRefresh: 0,
			season_ruoli_options: null,
			season_ruoli_sel: null,
			season_ruoli_sel_season_id: null,
			season_ruoli_perc: null,
			loading: null,
		}
	},
	computed: {
		_: function() {
			return _;
		},
		selectedSeasonId: function() {			
			return (this.season_ruoli_perc && this.season_ruoli_sel) ? this.season_ruoli_perc[this.season_ruoli_sel][0]['stagione_id'] : null
		},
		selectedTeamId: function() {
			return (this.season_ruoli_perc && this.season_ruoli_sel ) ? this.season_ruoli_perc[this.season_ruoli_sel][0]['squadra_id'] : null
		},
		xPhysical: function() {
			return this.xPhysicalIndexes ? this.xPhysicalIndexes[this.xPhysicalSelected] : null;
		},
		xTechnical: function() {
			return this.xTechnicalIndexes ? this.xTechnicalIndexes[this.xTechnicalSelected] : null;
		},
		statsXPhy: function() {
			return this.xPhysical ? this.xPhysical.index : null;
		},
		statsXTech: function() {
			return this.xTechnical ? this.xTechnical.index : null;
		},
		selectedMaxPercRole: function() {
			this.loading = true
			const roles = this.season_ruoli_perc !== null ? this.season_ruoli_perc[this.season_ruoli_sel] : null

			let maxRole = null
			
			if( roles && this.xPhysicalIndexes && this.xTechnicalIndexes ){
				maxRole = roles.reduce(
					(max, role) => (role.percentuale > max.percentuale ? role : max), 
					roles[0]
				);

				this.xPhysicalSelected = this.xPhysicalIndexes.findIndex( ( xPhy ) => xPhy.stagione_id === this.selectedSeasonId )
				this.xTechnicalSelected = this.xTechnicalIndexes.findIndex( ( xTech ) => {					
					return xTech.stagione_id === this.selectedSeasonId && xTech.squadra_id === this.selectedTeamId
				})
	
				this.showTechIndexDetail = false
				this.showPhyIndexDetail = false
				this.showEvalutaionDetail = false;
			}
			
			this.loading = false
			return maxRole
		},

		filtered_season_ruoli_options: function() {
			var filtered_options = this.season_ruoli_options;

			if(this.xPhysicalIndexes && this.xTechnicalIndexes && filtered_options ) {
				filtered_options = filtered_options.filter( ( option ) => {

					const option_stagione_id = this.season_ruoli_perc[option][0]['stagione_id'];
					const option_squadra_id = this.season_ruoli_perc[option][0]['squadra_id'];

					const isInXPhyIndex = this.xPhysicalIndexes.find( ( index ) => {
						return option_stagione_id === index.stagione_id;
					})
					
					const isInXTechIndex = this.xTechnicalIndexes.find( ( index ) => {	
						return option_stagione_id === index.stagione_id && option_squadra_id === index.squadra_id;
					})

					return !!isInXPhyIndex || !!isInXTechIndex;
				});
			}

			if(filtered_options && filtered_options.length > 0) {
				this.season_ruoli_sel = filtered_options[0];
			}
			return filtered_options;
		}

	},
	watch: {
		player: {
			immediate: true,
			handler: async function() {
				await this.fetchXPhyIndex();
				await this.fetchXTechIndex();
				this.fetchRuoli();
			}
		}
	},
	methods: {
		fetchRuoli: function() {
			this.$http.get(this.$store.state.apiEndPoint + '/statsbomb/roles/percentage/' + this.player.persona.id).then(function(data) {
				this.season_ruoli_perc = data.data;
				this.season_ruoli_options = _.keys(this.season_ruoli_perc);
			});
		},
		fetchXPhyIndex: function() {
			return new Promise( ( resolve ) => {
				this.$http.get(this.$store.state.apiEndPoint + '/skillcorner/xPhysical/' + this.player.persona.id + '/test')
				.then(function(data) {
					this.xPhysicalIndexes = data.data;
					this.xPhysicalSelected = _.size(data.data) - 1;
				}).finally(() => {
					resolve(true)
				});
			})
		},
		fetchXTechIndex: function() {
			return new Promise( ( resolve ) => {
				this.$http.get(this.$store.state.apiEndPoint + '/statsbomb/get_xtech_seasons_stats/' + this.player.persona.id )
					.then(function(data) {
						this.xTechnicalIndexes = data.data;
						this.xTechnicalSelected = _.size(data.data) - 1;				
					}).finally(() => {
						resolve(true)
					});
			})
		},
		getBracket: function(range, index) {
			var bracket = '-';
			if(index != -1) {
				var interval = range[index];
				if(interval.min && interval.max) {
					bracket = 'beetween ' + interval.min + ' and ' + interval.max;
				} else if(interval.min && !interval.max) {
					bracket = '> ' + interval.min;
				} else if(!interval.min && interval.max) {
					bracket = '< ' + interval.max;
				}
			}
			return bracket;
		},
		getLabelCategory: function(array_values, idx) {
			var cat = array_values[idx].category;
			if(idx > 0) {
				var prevCat = array_values[idx - 1].category;
				if(cat == prevCat) {
					cat = '';
				}
			}
			return cat;
		},
		legendHtml: function(stat) {
			var html = [];
			html.push('<div><strong>' + stat.label + '</strong></div>');
			_.forEach(stat.range, function(item) {
				var label = '';
				if(item.min && item.max) {
					label = 'beetween ' + item.min + ' and ' + item.max;
				} else if(item.min && !item.max) {
					label = '> ' + item.min;
				} else if(!item.min && item.max) {
					label = '< ' + item.max;
				}
				label += ' : +' + item.points;
				html.push('<div>' + label + '</div>');
			});

			return html.join("\n");
		},
		openEvaluationDetail: function( evalutionValuesGroup ) {
			
			let from=0, to=0

			switch ( evalutionValuesGroup ) {
				case 'qty_val_0_1':
					from = 0
					to = 1
					break;
				case 'qty_val_1_2':
					from = 1
					to = 2
					break;
				case 'qty_val_2_3':
					from = 2
					to = 3
					break;
				case 'qty_val_3_4':
					from = 3
					to = 4
					break;
				case 'qty_val_4_5':
					from = 4
					to = 5
					break;
			}

			this.evaluationFilters = {
				'total_from': from,
				'total_to': to
			};

			this.showPhyIndexDetail = false;
			this.showTechIndexDetail = false;
			this.showEvalutaionDetail = true;
		},
		openXPhy: function() {
			this.showPhyIndexDetail = !this.showPhyIndexDetail
			this.showTechIndexDetail = false;
			this.showEvalutaionDetail = false;
		},
		openXTech: function() {
			this.showTechIndexDetail = !this.showTechIndexDetail
			this.showPhyIndexDetail = false
			this.showEvalutaionDetail = false;
		}
	}
}

</script>