<template id="form">
    <div class="card border-0 rounded-0">
        <b-overlay :show="loading">
            <div class="card-body">
                <b-container fluid>
                    <b-row>
                        <b-col :cols="['body-metrics'].includes($route.query.tab) ? 9 : 12">
                            <form v-on:submit.prevent="checkBeforeSubmit()" action="#" method="POST">
                                <div class="content giocatore">
                                    
                                    <!-- Anagrafica Generale -->
                                    <fieldset v-show="$route.query.tab == 'anagrafica-generale'">
                                        
                                        <!-- View -->
                                        <div v-show="!enableEdit">
                                            <table class="table table-sm table-giocatore mb-0 p-0">
                                                <tr> 
                                                    <td>Nome</td> 
                                                    <td>{{ currentPlayer.persona.nome }}</td> 
                                                </tr>
                                                <tr> 
                                                    <td>Cognome</td> 
                                                    <td>{{ currentPlayer.persona.cognome }}</td> 
                                                </tr>
                                                <tr> 
                                                    <td>Data di nascita</td> 
                                                    <td> {{ currentPlayer.persona.nascita_data | formatDate }}</td> 
                                                </tr>
                                                <tr v-if="currentPlayer.persona.nascita_paese"> 
                                                    <td>Paese di nascita</td> 
                                                    <td>
                                                        <flag :country="currentPlayer.persona.nascita_paese.sigla" :size="16"></flag>
                                                        {{ currentPlayer.persona.nascita_paese.paese }}
                                                    </td>
                                                </tr>
                                                <tr> 
                                                    <td>Luogo di nascita</td> 
                                                    <td>
                                                        {{ currentPlayer.persona.info_extra ? currentPlayer.persona.info_extra.nascita_comune : ''}} {{ currentPlayer.persona.nascita_paese_id == 118 && currentPlayer.persona.info_extra && currentPlayer.persona.info_extra.nascita_provincia ? '(' + currentPlayer.persona.info_extra.nascita_provincia + ')' : '' }}
                                                    </td> 
                                                </tr>
                                                
                                                <!-- Anagrafica Generale - View - Football fields -->
                                                <template v-if="auth.isUserEnable('rosa_tab_generali')">
                                                    <tr v-if="residenza && residenza.paese"> 
                                                        <td>Paese di residenza</td> 
                                                        <td>
                                                            <flag :country="residenza.paese.sigla" :size="16"></flag> 
                                                            {{ residenza.paese.paese }}
                                                        </td> 
                                                    </tr>
                                                    <tr v-if="residenza"> 
                                                        <td>Luogo di residenza</td> 
                                                        <td>
                                                            {{residenza.citta}} {{ residenza.paese_id == 118 && residenza.cap && residenza.provincia ? ', ' + residenza.cap +' (' + residenza.provincia + ')' : '' }}
                                                        </td> 
                                                    </tr>
                                                    <tr v-if="residenza">
                                                        <td>Indirizzo di residenza</td> 
                                                        <td>{{residenza.indirizzo }} </td> 
                                                    </tr>
                                                    <tr v-if="domicilio && domicilio.paese"> 
                                                        <td>Paese di domicilio</td> 
                                                        <td>
                                                            <flag :country="domicilio.paese.sigla" :size="16"></flag> 
                                                            {{ domicilio.paese.paese }}
                                                        </td> 
                                                    </tr>
                                                    <tr v-if="domicilio"> 
                                                        <td>Luogo di domicilio</td> 
                                                        <td>
                                                            {{domicilio.citta}} {{ domicilio.paese_id == 118 && domicilio.cap && domicilio.provincia ? ', ' + domicilio.cap +' (' + domicilio.provincia + ')' : '' }}
                                                        </td>
                                                    </tr>
                                                    <tr v-if="domicilio"> 
                                                        <td>Indirizzo di domicilio</td> 
                                                        <td>
                                                            {{domicilio.indirizzo }}
                                                        </td> 
                                                    </tr>
                                                    <tr v-if="email"> 
                                                        <td>Email</td> 
                                                        <td>
                                                            {{ email }}
                                                        </td> 
                                                    </tr>
                                                    <tr v-if="pec"> 
                                                        <td>Email PEC</td> 
                                                        <td>
                                                            {{ pec }}
                                                        </td> 
                                                    </tr>
                                                    <tr v-for="(numero, index) in telefoni"> 
                                                        <td>Telefono {{ index > 0 ? index + 1 : '' }}</td> 
                                                        <td>{{ numero }}</td> 
                                                    </tr>
                                                    <tr v-if="this.currentPlayer.persona.giocatore && this.currentPlayer.persona.giocatore.id_gps"> 
                                                        <td>ID GPS</td> 
                                                        <td>{{this.currentPlayer.persona.giocatore.id_gps}}</td> 
                                                    </tr>
                                                </template>
                                                <!-- Anagrafica Generale - View - Football fields END -->
                                            </table>
                                        </div>
                                        
                                        <!-- Edit -->
                                        <div v-show="enableEdit">

                                            <b-form-group id="nomeGroup" horizontal label="Nome" :label-cols="3" label-for="nome">
                                                <b-form-input id="nome" type="text" v-model="form.nome" :required="true"></b-form-input>
                                            </b-form-group>
                                            
                                            <b-form-group id="cognomeGroup" horizontal label="Cognome" :label-cols="3" label-for="cognome">
                                                <b-input-group>
                                                    <b-form-input id="cognome" type="text" v-model="form.cognome" :required="true"></b-form-input>
                                                    <b-input-group-append>
                                                        <b-button variant="secondary" @click="searchTeam" title="Cerca per squadra"><i class="fa fa-users" aria-hidden="true"></i></b-button>
                                                        <b-button variant="primary" @click="searchPlayer" title="Cerca per nome"><i class="fa fa-search fa-fw" aria-hidden="true"></i></b-button>
                                                    </b-input-group-append>
                                                </b-input-group>
                                            </b-form-group>
                                            
                                            <b-form-group id="dataDiNascitaGroup" horizontal label="Data di nascita" :label-cols="3" label-for="data_nascita">
                                                <b-form-input id="data_nascita" type="date" v-model="form.nascita_data"></b-form-input>
                                            </b-form-group>
                                            
                                            <b-form-group id="paeseNascitaGroup" horizontal label="Paese di nascita" :label-cols="3" label-for="paese_nascita">
                                                <autocomplete-paese :suggestions="paesi" id="paese_nascita" ref="paese_nascita" @select="onPaeseNascitaSelect" @change="onPaeseNascitaChange" v-model="form.nascita_paese_id"></autocomplete-paese>
                                            </b-form-group>
    
                                            <b-form-group horizontal label="Luogo di nascita" :label-cols="3">
                                                <autocomplete-comune v-show="form.nascita_paese_id == 118" id="comune" ref="comune_nascita" @select="onComuneNascitaSelect" @change="onComuneNascitaChange"></autocomplete-comune>
                                                <b-form-input v-show="form.nascita_paese_id != 118" id="comune" v-model="form.info_extra.nascita_comune" placeholder="Città di nascita"></b-form-input>
                                            </b-form-group>
                                            
                                            <!-- Anagrafica Generale - Edit - Football fields -->
                                            <div v-show="auth.isUserEnable('rosa_tab_generali_edit')">
                                                <b-form-group id="paeseResidenzaGroup" horizontal label="Paese di residenza" :label-cols="3" label-for="paese_residenza">
                                                    <autocomplete-paese :suggestions="paesi" id="paese_residenza" ref="paese_residenza" @select="onPaeseResidenzaSelect" @change="onPaeseResidenzaChange" v-model="form.residenza.paese"></autocomplete-paese>
                                                </b-form-group>
                                                
                                                <b-form-group horizontal label="Luogo di residenza" :label-cols="3">
                                                    <autocomplete-comune v-show="form.residenza.paese == 118" id="comune_residenza" ref="comune_residenza" @select="onComuneResidenzaSelect" @change="onComuneResidenzaChange" @changeCap="onCapResidenzaChange" :show_cap="true"></autocomplete-comune>
                                                    <b-form-input v-show="form.residenza.paese != 118" id="citta_residenza" v-model="form.residenza.citta" placeholder="Città di residenza"></b-form-input>
                                                </b-form-group>
                                                
                                                <b-form-group id="indirizzoResGroup" horizontal label="Indirizzo residenza" :label-cols="3" label-for="indirizzo_residenza">
                                                    <input ref="autocomplete" class="form-control" type="text" v-model="form.residenza.indirizzo">
                                                </b-form-group>
                                                
                                                <b-form-group id="paeseDomicilioGroup" horizontal label="Paese di domicilio" :label-cols="3" label-for="paese_domicilio">
                                                    <autocomplete-paese :suggestions="paesi" id="paese_domicilio" ref="paese_domicilio" @select="onPaeseDomicilioSelect" @change="onPaeseDomicilioChange" v-model="form.domicilio.paese"></autocomplete-paese>
                                                </b-form-group>
                                                
                                                <b-form-group horizontal label="Luogo di domicilio" :label-cols="3">
                                                    <autocomplete-comune v-show="form.domicilio.paese == 118" id="comune_domicilio" ref="comune_domicilio" @select="onComuneDomicilioSelect" @change="onComuneDomicilioChange" @changeCap="onCapDomicilioChange" :show_cap="true"></autocomplete-comune>
                                                    <b-form-input v-show="form.domicilio.paese != 118" id="citta_domicilio" v-model="form.domicilio.citta" placeholder="Città di domicilio"></b-form-input>
                                                </b-form-group>
                                                
                                                <b-form-group id="indirizzoDomGroup" horizontal label="Indirizzo domicilio" :label-cols="3" label-for="indirizzo_domicilio">
                                                    <input ref="autocomplete_dom" class="form-control" type="text" v-model="form.domicilio.indirizzo">
                                                </b-form-group>
                                                
                                                <b-form-group id="emailGroup" horizontal label="Email" :label-cols="3" label-for="email">
                                                    <b-form-input id="email" type="email" v-model="form.email.valore"></b-form-input>
                                                </b-form-group>
        
                                                <b-form-group id="pecGroup" horizontal label="Email PEC" :label-cols="3" label-for="pec">
                                                    <b-form-input id="pec" type="email" v-model="form.pec.valore"></b-form-input>
                                                </b-form-group>
                                                
                                                <b-form-group id="telefonoGroup" horizontal label="Telefono" :label-cols="3" label-for="telefono">
                                                    <b-form-input id="telefono" type="tel" v-model="form.telefono.valore"></b-form-input>
                                                </b-form-group>
                                                
                                                <b-form-group id="telefono2Group" horizontal label="Telefono 2" :label-cols="3" label-for="telefono2">
                                                    <b-form-input id="telefono2" type="tel" v-model="form.telefono2.valore"></b-form-input>
                                                </b-form-group>
                                                
                                                <b-form-group id="idGpsGroup" horizontal label="ID GPS" :label-cols="3" label-for="id_gps">
                                                    <b-form-input id="id_gps" type="text" v-model="form.giocatore.id_gps"></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <!-- Anagrafica Generale - Edit - Football fields END -->
                                        </div>

                                    </fieldset>
                                    <!-- Anagrafica Generale END -->

                                    <!-- Body Metrics -->
                                    <fieldset v-show="$route.query.tab == 'body-metrics'">
                                        <!-- View -->
                                        <div v-show="!enableEdit">
                                            <table class="table table-sm table-giocatore mb-0 p-0">
                                                <tr> 
                                                    <td>Altezza</td> 
                                                    <td>
                                                        <span v-if="currentPlayer.persona.info_fisica">{{ currentPlayer.persona.info_fisica.altezza }} cm</span>
                                                    </td> 
                                                </tr>
                                                <tr> 
                                                    <td>Peso</td> 
                                                    <td>
                                                        <span v-if="currentPlayer.persona.info_fisica">{{ currentPlayer.persona.info_fisica.peso }} kg</span>
                                                    </td> 
                                                </tr>
                                                <tr> 
                                                    <td>Piede</td> 
                                                    <td>
                                                        <span v-if="currentPlayer.persona.giocatore">{{ piede[currentPlayer.persona.giocatore.piede] }}</span>
                                                    </td> 
                                                </tr>
                                                <tr> 
                                                    <td>Numero Piede</td> 
                                                    <td>
                                                        <span v-if="currentPlayer.persona.info_fisica">{{ currentPlayer.persona.info_fisica.num_piede }}</span>
                                                    </td> 
                                                </tr>
                                                <tr> 
                                                    <td>Baricentro</td> 
                                                    <td>
                                                        <span v-if="currentPlayer.persona.info_fisica">{{ etichette.baricentro[parseInt(currentPlayer.persona.info_fisica.baricentro)] }}</span>
                                                    </td> 
                                                </tr>
                                                <tr> 
                                                    <td>Somatotipo</td> 
                                                    <td>
                                                        <span v-if="currentPlayer.persona.info_fisica">{{ etichette.somatotipo[parseInt(currentPlayer.persona.info_fisica.somatotipo)] }}</span>
                                                    </td> 
                                                </tr>
                                                <tr> 
                                                    <td>Costituzione</td> 
                                                    <td>
                                                        <span v-if="currentPlayer.persona.info_fisica">{{ etichette.costituzione[parseInt(currentPlayer.persona.info_fisica.costituzione)] }}</span>
                                                    </td> 
                                                </tr>
                                                <!-- Body Metrics - View - Football fields -->
                                                <template v-if="auth.isUserEnable('rosa_tab_corporatura')">
                                                    <tr> 
                                                        <td>Max Heart Rate</td> 
                                                        <td>
                                                            <span v-if="currentPlayer.persona.info_fisica">{{ currentPlayer.persona.info_fisica.max_heart_rate }}</span>
                                                        </td> 
                                                    </tr>
                                                </template>
                                                <!-- Body Metrics - View - Football fields END -->
                                            </table>  
                                        </div>
                                        <!-- Edit -->
                                        <div v-show="enableEdit">
                                            <b-form-group id="altezzaGroup" horizontal label="Altezza (cm)" :label-cols="3" label-for="altezza">
                                                <b-form-input id="altezza" type="text" v-model="form.info_fisica.altezza"></b-form-input>
                                            </b-form-group>
                                            <b-form-group id="pesoGroup" horizontal label="Peso (kg)" :label-cols="3" label-for="peso">
                                                <b-form-input id="peso" type="text" v-model="form.info_fisica.peso"></b-form-input>
                                            </b-form-group>
                                            <b-form-group id="piedeGroup" horizontal label="Piede" :label-cols="3" label-for="piede">
                                                <b-form-radio-group id="piede" v-model="form.giocatore.piede">
                                                    <b-form-radio value="0">Destro</b-form-radio>
                                                    <b-form-radio value="1">Sinistro</b-form-radio>
                                                    <b-form-radio value="2">Ambidestro</b-form-radio>
                                                </b-form-radio-group>
                                            </b-form-group>
                                            <b-form-group id="numPiedeGroup" horizontal label="Numero Piede" :label-cols="3" label-for="numero_piede">
                                                <b-form-input id="num_piede" type="text" v-model="form.info_fisica.num_piede"></b-form-input>
                                            </b-form-group>
                                            <b-form-group id="baricentroGroup" horizontal label="Baricentro" :label-cols="3">
                                                <b-form-select v-model="form.info_fisica.baricentro">
                                                    <option :value="null"></option>
                                                    <option value="0">Basso</option>
                                                    <option value="1">Medio</option>
                                                    <option value="2">Alto</option>
                                                </b-form-select>
                                            </b-form-group>  
                                            <b-form-group id="somatotipoGroup" horizontal label="Somatotipo" :label-cols="3">
                                                <b-form-select v-model="form.info_fisica.somatotipo">
                                                    <option :value="null"></option>
                                                    <option value="0">Normolineo</option>
                                                    <option value="1">Brevilineo</option>
                                                    <option value="2">Longilineo</option>
                                                </b-form-select>
                                            </b-form-group>
                                            <b-form-group id="costituzioneGroup" horizontal label="Costituzione" :label-cols="3">
                                                <b-form-select v-model="form.info_fisica.costituzione">
                                                    <option :value="null"></option>
                                                    <option value="0">Esile</option>
                                                    <option value="1">Media</option>
                                                    <option value="2">Robusta</option>
                                                </b-form-select>
                                            </b-form-group>
                                            <!-- Body Metrics - Edit - Football fields -->
                                            <div v-show="auth.isUserEnable('rosa_tab_corporatura_edit')">
                                                <b-form-group id="maxHeartRateGroup" horizontal label="Max Heart Rate" :label-cols="3" label-for="max_heart_rate">
                                                    <b-form-input id="max_heart_rate" type="text" v-model="form.info_fisica.max_heart_rate"></b-form-input>
                                                </b-form-group>
                                            </div>
                                            <!-- Body Metrics - Edit - Football fields END -->
                                        </div>
                                    </fieldset>

                                    <!-- Carriera -->
                                    <fieldset v-show="$route.query.tab == 'carriera'">
                                        <!-- View -->
                                        <div v-show="!enableEdit">
                                            <table class="table table-sm table-giocatore mb-0 p-0">
                                                
                                                <tr v-if="currentPlayer.persona.last_carriera_sportiva"> 
                                                    <td>Numero Maglia</td> 
                                                    <td>
                                                        {{ currentPlayer.persona.last_carriera_sportiva.maglia }}
                                                    </td> 
                                                </tr>
                                                <tr> 
                                                    <td>Ruolo Ideale</td> 
                                                    <td> 
                                                        {{ currentPlayer.persona.last_carriera_sportiva && currentPlayer.persona.last_carriera_sportiva.ruolo_ideale.length > 0 ? currentPlayer.persona.last_carriera_sportiva.ruolo_ideale[0].nome : '' }}
                                                    </td> 
                                                </tr>

                                                <!-- Carriera - View - Football fields -->
                                                <template v-if="auth.isUserEnable('rosa_tab_carriera')">
                                                    <tr> 
                                                        <td>Matricola FIGC</td> 
                                                        <td>
                                                            <span v-if="currentPlayer.persona.info_extra">{{ currentPlayer.persona.info_extra.matricola_figc }}</span>
                                                        </td> 
                                                    </tr>
                                                    <tr> 
                                                        <td>N. tessera</td> 
                                                        <td>
                                                            <span v-if="tessera">{{ tessera.numero }}</span>
                                                        </td>
                                                    </tr>
                                                    <tr> 
                                                        <td>Ultima Visita Medica / Data Emissione Certificato</td> 
                                                        <td>
                                                            <span v-if="idoneita">{{ idoneita.emissione | formatDate }}</span>
                                                        </td> 
                                                    </tr>
                                                    <tr> 
                                                        <td>Prossima Visita Medica / Data Scadenza Certificato</td> 
                                                        <td>
                                                            <span v-if="idoneita">{{ idoneita.scadenza | formatDate }}</span>
                                                        </td> 
                                                    </tr>
                                                    <tr> 
                                                        <td>Visita Medica Intermedia</td> 
                                                        <td>
                                                            <span v-if="idoneita">{{ idoneita.documento_tipo_glo_id == 10 ? getVisitaMedicaIntermedia(idoneita.scadenza) : 'NO' }}</span>
                                                        </td> 
                                                    </tr>
                                                </template>
                                                <!-- Carriera - View - Football fields END -->
                                            </table>
                                        </div>
                                        <!-- Edit -->
                                        <div v-show="enableEdit">
                                            
                                            <b-form-group id="magliaGroup" horizontal label="Numero maglia" :label-cols="3">
                                                <b-form-select v-model="form.last_carriera_sportiva.maglia">
                                                    <option :value="null"></option>
                                                    <option v-for="(value, index) in 99" :value="value" :key="index">{{value}}</option>
                                                </b-form-select>
                                            </b-form-group>
                                            
                                            <b-form-group id="ruoloIdealeGroup" horizontal label="Ruolo ideale" :label-cols="3">
                                                <b-form-select v-model="form.last_carriera_sportiva.ruolo_ideale_id" :options="ruoli" value-field="id" text-field="nome"></b-form-select>
                                            </b-form-group>
                                            
                                            <b-form-group id="sistemaGiocoIdealeGroup" horizontal label="Sistema di gioco ideale" :label-cols="3">
                                                <b-form-select v-model="form.giocatore.sistema_gioco_id" :options="sistemi" value-field="id" text-field="sistema_gioco"></b-form-select>
                                            </b-form-group>
                                            
                                            <b-form-group id="altriRuoliGroup" horizontal label="Altri ruoli" :label-cols="3">
                                                <div id="altri_ruoli">{{altri_ruoli_lbl != "" ? altri_ruoli_lbl : "&nbsp"}}</div>
                                            </b-form-group>

                                            <!-- Carriera - Edit - Football fields -->
                                            <div v-show="auth.isUserEnable('rosa_tab_carriera_edit')">
                                                
                                                <b-form-group id="matrFigcGroup" horizontal label="Matricola FIGC" :label-cols="3" label-for="matricola_figc">
                                                    <b-form-input id="matricola_figc" type="text" v-model="form.info_extra.matricola_figc"></b-form-input>
                                                </b-form-group>
                                                
                                                <b-form-group id="numTesseraGroup" horizontal label="Numero Tessera" :label-cols="3" label-for="num_tessera">
                                                    <b-form-input id="num_tessera" type="text" v-model="form.documenti.tessera.numero"></b-form-input>
                                                </b-form-group>

                                                <b-form-group v-if="auth.isUserEnable('egnyte')" horizontal label="Documento Tessera" :label-cols="3">
                                                    <b-row>
                                                        <b-col sm="6" v-if="form.documenti.tessera.path">
                                                            <b-button variant="primary" @click="downloadDoc(form.documenti.tessera.id)">Visualizza file <i class="fa fa-search" aria-hidden="true"></i></b-button>
                                                            <b-form-checkbox v-model="form.documenti.tessera.delete" class="ml-3">
                                                                Rimuovi da Egnyte
                                                            </b-form-checkbox>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <b-form-group horizontal label="Nuovo file" :label-cols="3">
                                                                <b-form-file
                                                                v-model="documenti_files.tessera"
                                                                :state="Boolean(documenti_files.tessera)"
                                                                placeholder="Scegli un file da caricare..."
                                                                drop-placeholder="Cancella file..."
                                                                ></b-form-file>
                                                            </b-form-group>
                                                        </b-col>
                                                    </b-row>
                                                </b-form-group>
                                                
                                                <b-form-group id="giovaniliVivaioGroup" horizontal :label-cols="3">
                                                    <b-form-checkbox id="giovanili" v-model="form.giocatore.cresciuto_giovanili">
                                                        Cresciuto nelle giovanili
                                                    </b-form-checkbox>
                                                    <b-form-checkbox id="vivaio_italiano" v-model="form.giocatore.cresciuto_vivaio_italiano">
                                                        Cresciuto in un vivaio italiano
                                                    </b-form-checkbox>
                                                    <b-form-checkbox id="risiede_convitto" v-model="form.info_economica.in_convitto">
                                                        Risiede convitto
                                                    </b-form-checkbox>
                                                </b-form-group>
                                                
                                                <b-form-group id="provaGroup" horizontal label="Giocatore in prova" :label-cols="3" label-for="prova">
                                                    <b-form-radio-group id="prova" v-model="form.last_carriera_sportiva.prova">
                                                        <b-form-radio :value="0">No</b-form-radio>
                                                        <b-form-radio :value="1">Si</b-form-radio>
                                                    </b-form-radio-group>
                                                </b-form-group>
                                                
                                                <div v-if="form.last_carriera_sportiva.prova">
                                                    <fieldset>
                                                        <legend>Periodi di prova</legend>
                                                        <b-form-group id="provaPeriodiGroup">
                                                            <b-form-row v-for="(item, index) in form.prova_periodi" :key="index" class="mb-3 pt-3 border-bottom">
                                                                <b-col cols="6">
                                                                    <b-form-group horizontal label="Data inizio" :label-cols="3">
                                                                        <b-form-input type="date" v-model="item.inizio"></b-form-input>
                                                                    </b-form-group>
                                                                </b-col>
                                                                <b-col cols="6">
                                                                    <b-form-group horizontal label="Data fine" :label-cols="3" class="text-right">
                                                                        <b-form-input type="date" v-model="item.fine"></b-form-input>
                                                                    </b-form-group>
                                                                </b-col>
                                                                <b-col cols="12">
                                                                    <b-form-group v-if="auth.isUserEnable('egnyte')" horizontal label="Nulla osta" :label-cols="3">
                                                                        <b-row>
                                                                            <b-col sm="6" v-if="item.path">
                                                                                <b-button variant="primary" @click="downloadDoc(item.id)">Visualizza file <i class="fa fa-search" aria-hidden="true"></i></b-button>
                                                                                <b-form-checkbox v-model="item.delete" class="ml-3">
                                                                                    Rimuovi da Egnyte
                                                                                </b-form-checkbox>
                                                                            </b-col>
                                                                            <b-col sm="6">
                                                                                <b-form-group horizontal label="Nuovo file" :label-cols="3">
                                                                                    <b-form-file
                                                                                    v-model="item.file"
                                                                                    :state="Boolean(item.file)"
                                                                                    placeholder="Scegli un file da caricare..."
                                                                                    drop-placeholder="Cancella file..."
                                                                                    ></b-form-file>
                                                                                </b-form-group>
                                                                            </b-col>
                                                                        </b-row>
                                                                    </b-form-group>
                                                                </b-col>
                                                                <b-col cols="12">
                                                                    <b-form-group class="text-right">
                                                                        <b-button variant="danger" size="sm" @click="removePeriodoProva(item)"><i class="fa fa-trash fa-fw"></i> Rimuovi Periodo</b-button>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-form-row>
                                                            
                                                            <b-button variant="outline-primary" @click="addPeriodoProva"><i class="fa fa-plus fa-fw"></i> Aggiungi Periodo</b-button>
                                                        </b-form-group>
                                                    </fieldset>
                                                    <fieldset>
                                                        <legend>Carriera</legend>
                                                        <b-form-group id="provaCarrieraGroup">
                                                            <b-form-row v-for="(item, index) in form.prova_carriera" :key="index" class="mb-3 pt-3 border-bottom">
                                                                <b-col cols="4">
                                                                    <b-form-group horizontal label="Stagione" :label-cols="3">
                                                                        <b-form-select v-model="item.id_stagione" :options="stagioni" value-field="id" text-field="stagione"></b-form-select>
                                                                    </b-form-group>
                                                                </b-col>
                                                                <b-col cols="8">
                                                                    <b-form-group horizontal label="Società" :label-cols="3" class="text-right">
                                                                        <b-form-input type="text" v-model="item.societa"></b-form-input>
                                                                    </b-form-group>
                                                                </b-col>
                                                                <b-col cols="12">
                                                                    <b-form-group class="text-right">
                                                                        <b-button variant="danger" size="sm" @click="removeCarrieraProva(item)"><i class="fa fa-trash fa-fw"></i> Rimuovi Carriera</b-button>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-form-row>
                                                            
                                                            <b-button variant="outline-primary" @click="addCarrieraProva"><i class="fa fa-plus fa-fw"></i> Aggiungi Carriera</b-button>
                                                        </b-form-group>
                                                    </fieldset>
                                                    <fieldset>
                                                        <legend>Altri documenti</legend>
                                                        <b-form-group id="provaDocGroup">
                                                            <b-form-row v-for="(item, index) in form.prova_documenti" :key="index" class="mb-3 pt-3 border-bottom">
                                                                <b-col cols="12">
                                                                    <b-form-group horizontal label="Tipo documento" :label-cols="3">
                                                                        <b-form-input type="text" v-model="item.nome"></b-form-input>
                                                                    </b-form-group>
                                                                </b-col>
                                                                <b-col cols="12">
                                                                    <b-form-group v-if="auth.isUserEnable('egnyte')" horizontal label="Documento" :label-cols="3">
                                                                        <b-row>
                                                                            <b-col sm="6" v-if="item.path">
                                                                                <b-button variant="primary" @click="downloadDoc(item.id)">Visualizza file <i class="fa fa-search" aria-hidden="true"></i></b-button>
                                                                                <b-form-checkbox v-model="item.delete" class="ml-3">
                                                                                    Rimuovi da Egnyte
                                                                                </b-form-checkbox>
                                                                            </b-col>
                                                                            <b-col sm="6">
                                                                                <b-form-group horizontal label="Nuovo file" :label-cols="3">
                                                                                    <b-form-file
                                                                                    v-model="item.file"
                                                                                    :state="Boolean(item.file)"
                                                                                    placeholder="Scegli un file da caricare..."
                                                                                    drop-placeholder="Cancella file..."
                                                                                    ></b-form-file>
                                                                                </b-form-group>
                                                                            </b-col>
                                                                        </b-row>
                                                                    </b-form-group>
                                                                </b-col>
                                                                <b-col cols="12">
                                                                    <b-form-group class="text-right">
                                                                        <b-button variant="danger" size="sm" @click="removeDocumentoProva(item)"><i class="fa fa-trash fa-fw"></i> Rimuovi Documento</b-button>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-form-row>
                                                            
                                                            <b-button variant="outline-primary" @click="addDocumentoProva"><i class="fa fa-plus fa-fw"></i> Aggiungi Documento</b-button>
                                                        </b-form-group>
                                                    </fieldset>
                                                </div>
                                                
                                                <div class="modulo">
                                                    <div class="campo">
                                                        <img src="/assets/images/campo.svg">
                                                    </div>
                                                    <div v-for="r in ruoli" :class="'player ' + r.sigla.toLowerCase()" v-on:click="selezionaRuolo(r.id)" :title="r.ruolo">
                                                        <span :class="'ruolo' + (form.last_carriera_sportiva.altri_ruoli_ids.indexOf(r.id) > -1 ? ' on' : (form.last_carriera_sportiva.ruolo_ideale_id == r.id ? ' ide' : ''))" style="font-size:1rem;">{{ r.sigla }}<div :class="'ovale' + (form.last_carriera_sportiva.altri_ruoli_ids.indexOf(r.id) > -1 ? ' bg_on' : (form.last_carriera_sportiva.ruolo_ideale_id == r.id ? ' bg_ide' : ''))"></div></span>
                                                    </div>
                                                </div>

                                            </div>
                                            <!-- Carriera - Edit - Football fields END -->
                                        </div>
                                    </fieldset>
                                    
                                    <!-- dati economici -->
                                    <fieldset v-show="$route.query.tab == 'dati-economici'">

                                        <!-- View -->
                                        <div v-show="!enableEdit">
                                            <table class="table table-sm table-giocatore mb-0 p-0">

                                                <tr> 
                                                    <td>Contratto</td> 
                                                    <td>
                                                        {{currentPlayer.persona.info_economica && currentPlayer.persona.info_economica.has_contratto ? 'SI' : 'NO' }}
                                                    </td> 
                                                </tr>
                                                <tr> 
                                                    <td>Scadenza Contratto</td> 
                                                    <td>
                                                        <span v-if="contratto"> {{ contratto.scadenza | formatDate }} </span>
                                                    </td> 
                                                </tr>
                                                <tr> 
                                                    <td>Valore Gicatore</td> 
                                                    <td>
                                                        {{ currentPlayer.persona.info_economica && currentPlayer.persona.info_economica.valore && currentPlayer.persona.info_economica.valore > 0 ? '€ ' + currentPlayer.persona.info_economica.valore : 'N/D' }}
                                                    </td> 
                                                </tr>

                                                <!-- Dati Economici - View - Football fields -->
                                                <template v-if="auth.isUserEnable('rosa_tab_dati_economici')">
                                                    
                                                    <tr v-for="(agente, index) in agentiComputed"> 
                                                        <td>
                                                            Agente {{ index > 0 ? 'genitore ' + (index + 1) : '' }}
                                                        </td> 
                                                        <td>
                                                            {{ agente.cognome + ' ' + agente.nome }}
                                                        </td> 
                                                    </tr>
                                                    <tr> 
                                                        <td>Rimborso spese</td> 
                                                        <td>
                                                            {{ currentPlayer.persona.info_economica && currentPlayer.persona.info_economica.rimborso_spese ? 'SI' : 'NO' }}
                                                        </td> 
                                                    </tr>
                                                    <tr> 
                                                        <td>Stipendio/Rimborso lordo fisso annuo</td> 
                                                        <td> 
                                                            {{ currentPlayer.persona.contratto && currentPlayer.persona.contratto.stipendio_lordo && currentPlayer.persona.contratto.stipendio_lordo > 0 ? '€ ' + currentPlayer.persona.contratto.stipendio_lordo : 'N/D'}}
                                                        </td> 
                                                    </tr>
                                                    <tr> 
                                                        <td>IBAN</td> 
                                                        <td>
                                                            {{ currentPlayer.persona.info_economica ? currentPlayer.persona.info_economica.iban : ''}}
                                                        </td> 
                                                    </tr> 
                                                    <tr> 
                                                        <td>Intestatario IBAN</td> 
                                                        <td>
                                                            {{ currentPlayer.persona.info_economica ?  currentPlayer.persona.info_economica.intestatario_iban : '' }}
                                                        </td> 
                                                    </tr>
                                                    <tr> 
                                                        <td>Scuola AS Roma</td> 
                                                        <td>
                                                            {{ currentPlayer.persona.giocatore && currentPlayer.persona.giocatore.frequenta_scuola_asroma ? 'SI' : 'NO' }}
                                                        </td> 
                                                    </tr>
                                                    <tr> 
                                                        <td>Scuola a carico AS Roma</td> 
                                                        <td>
                                                            {{ currentPlayer.persona.info_economica && currentPlayer.persona.info_economica.scuola_a_carico ? 'SI' : 'NO' }}
                                                        </td>
                                                    </tr>

                                                </template>
                                                <!-- Dati Economici - View - Football fields END -->
                                            </table>  
                                        </div>

                                        <!-- Edit -->
                                        <div v-show="enableEdit">
                                            
                                            <b-form-group id="contrattoGroup" horizontal label="Contratto" :label-cols="3" label-for="contratto">
                                                <b-form-radio-group id="contratto" v-model="form.info_economica.has_contratto">
                                                    <b-form-radio :value="0">No</b-form-radio>
                                                    <b-form-radio :value="1">Si</b-form-radio>
                                                </b-form-radio-group>
                                            </b-form-group>

                                            <b-form-group id="scadenzaContrattoGroup" horizontal label="Scadenza Contratto" :label-cols="3">
                                                <b-form-input id="scadenza_contratto" type="date" v-model="form.documenti.contratto.scadenza"></b-form-input>
                                            </b-form-group>

                                            <b-form-group id="valoreGroup" horizontal label="Valore giocatore" :label-cols="3" label-for="valore">	
                                                <b-form-input id="valore" type="text" v-model="form.info_economica.valore"></b-form-input>
                                            </b-form-group>

                                            <!-- Dati Economici - Edit - Football fields -->
                                            <div v-show="auth.isUserEnable('rosa_tab_dati_economici_edit')">
                                                <b-form-group v-if="auth.isUserEnable('egnyte')" horizontal label="Documento Contratto" :label-cols="3">
                                                    <b-row>
                                                        <b-col sm="6" v-if="form.documenti.contratto.path">
                                                            <b-button variant="primary" @click="downloadDoc(form.documenti.contratto.id)">Visualizza file <i class="fa fa-search" aria-hidden="true"></i></b-button>
                                                            <b-form-checkbox v-model="form.documenti.contratto.delete" class="ml-3">
                                                                Rimuovi da Egnyte
                                                            </b-form-checkbox>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <b-form-group horizontal label="Nuovo file" :label-cols="3">
                                                                <b-form-file
                                                                v-model="documenti_files.contratto"
                                                                :state="Boolean(documenti_files.contratto)"
                                                                placeholder="Scegli un file da caricare..."
                                                                drop-placeholder="Cancella file..."
                                                                ></b-form-file>
                                                            </b-form-group>
                                                        </b-col>
                                                    </b-row>
                                                </b-form-group>

                                                <b-form-group id="agenteGroup" horizontal label="Agente" :label-cols="3" label-for="agente">
                                                    <div class="d-flex">
                                                        <div class="flex-grow-1 mr-1">
                                                            <autocomplete-nome :suggestions="agenti" id="agente" @select="onAgenteSelect" @change="onAgenteChange" v-model="form.agente.id" cols="10" ref="agente" placeholder="Cognome" field="cognome"></autocomplete-nome>
                                                        </div>
                                                        <div class="flex-grow-1 mr-1">
                                                            <b-form-input type="text" v-model="form.agente.nome" placeholder="Nome"></b-form-input>
                                                        </div>
                                                        <div class="flex-shrink-0">
                                                            <b-button variant="outline-primary" v-b-toggle.agente_pnl>Maggiori info</b-button>
                                                        </div>
                                                    </div>
                                                    <b-collapse id="agente_pnl" @shown="updateImageAgente">
                                                        <b-card class="p-1 my-1">
                                                            <picture-input ref="pictureAgente" @change="onAvatarAgenteChange" width="150" height="150" size="10" class="mb-1" :customStrings="customStrings" :prefill="avatar_agente"></picture-input>
                                                            <b-form-group horizontal label="Nazionalità" :label-cols="3">
                                                                <autocomplete-paese :suggestions="paesi" id="paese_agente1" ref="paese_agente1" @select="onPaeseAgente1Select" @change="onPaeseAgente1Change" v-model="form.agente.id_paese"></autocomplete-paese>
                                                            </b-form-group>
                                                            <b-form-group horizontal label="Luogo di nascita" :label-cols="3">
                                                                <b-form-input v-model="form.agente.luogo_nascita"></b-form-input>
                                                            </b-form-group>
                                                            <b-form-group horizontal label="Data di nascita" :label-cols="3">
                                                                <b-form-input type="date" v-model="form.agente.data_nascita"></b-form-input>
                                                            </b-form-group>
                                                            <b-form-group horizontal label="Indirizzo" :label-cols="3">
                                                                <b-form-input v-model="form.agente.indirizzo.indirizzo"></b-form-input>
                                                            </b-form-group>
                                                            <b-form-group id="emailAgenteGroup" horizontal label="Email" :label-cols="3" label-for="emailAgente">
                                                                <b-form-input id="emailAgente" type="email" v-model="form.agente.email.valore"></b-form-input>
                                                            </b-form-group>
                                                            <b-form-group id="telefono1AgenteGroup" horizontal label="Telefono 1" :label-cols="3" label-for="telefono1Agente">
                                                                <b-form-input id="telefono1Agente" type="tel" v-model="form.agente.telefono1.valore"></b-form-input>
                                                            </b-form-group>
                                                            <b-form-group id="telefono2AgenteGroup" horizontal label="Telefono 2" :label-cols="3" label-for="telefono2Agente">
                                                                <b-form-input id="telefono2Agente" type="tel" v-model="form.agente.telefono2.valore"></b-form-input>
                                                            </b-form-group>
                                                        </b-card>
                                                    </b-collapse>
                                                </b-form-group>
                                                
                                                <b-form-group id="agente2Group" horizontal label="Agente genitore 2" :label-cols="3" label-for="agente2">
                                                    <div class="d-flex">
                                                        <div class="flex-grow-1 mr-1">
                                                            <autocomplete-nome :suggestions="agenti" id="agente2" @select="onAgente2Select" @change="onAgente2Change" v-model="form.agente2.id" cols="10" ref="agente2" placeholder="Cognome" field="cognome"></autocomplete-nome>
                                                        </div>
                                                        <div class="flex-grow-1 mr-1">
                                                            <b-form-input type="text" v-model="form.agente2.nome" placeholder="Nome"></b-form-input>
                                                        </div>
                                                        <div class="flex-shrink-0">
                                                            <b-button variant="outline-primary" v-b-toggle.agente2_pnl>Maggiori info</b-button>
                                                        </div>
                                                    </div>
                                                    <b-collapse id="agente2_pnl" @shown="updateImageAgente2">
                                                        <b-card class="p-1 my-1">
                                                            <picture-input ref="pictureAgente2" @change="onAvatarAgente2Change" width="150" height="150" size="10" class="mb-1" :customStrings="customStrings" :prefill="avatar_agente2"></picture-input>
                                                            <b-form-group horizontal label="Nazionalità" :label-cols="3">
                                                                <autocomplete-paese :suggestions="paesi" id="paese_agente2" ref="paese_agente2" @select="onPaeseAgente2Select" @change="onPaeseAgente2Change" v-model="form.agente2.id_paese"></autocomplete-paese>
                                                            </b-form-group>
                                                            <b-form-group horizontal label="Luogo di nascita" :label-cols="3">
                                                                <b-form-input v-model="form.agente2.luogo_nascita"></b-form-input>
                                                            </b-form-group>
                                                            <b-form-group horizontal label="Data di nascita" :label-cols="3">
                                                                <b-form-input type="date" v-model="form.agente2.data_nascita"></b-form-input>
                                                            </b-form-group>
                                                            <b-form-group horizontal label="Indirizzo" :label-cols="3">
                                                                <b-form-input v-model="form.agente2.indirizzo.indirizzo"></b-form-input>
                                                            </b-form-group>
                                                            <b-form-group horizontal label="Email" :label-cols="3">
                                                                <b-form-input type="email" v-model="form.agente2.email.valore"></b-form-input>
                                                            </b-form-group>
                                                            <b-form-group horizontal label="Telefono 1" :label-cols="3">
                                                                <b-form-input type="tel" v-model="form.agente2.telefono1.valore"></b-form-input>
                                                            </b-form-group>
                                                            <b-form-group horizontal label="Telefono 2" :label-cols="3">
                                                                <b-form-input type="tel" v-model="form.agente2.telefono2.valore"></b-form-input>
                                                            </b-form-group>
                                                        </b-card>
                                                    </b-collapse>
                                                </b-form-group>

                                                <b-form-group v-if="auth.isUserEnable('egnyte')" horizontal label="Mandato agente" :label-cols="3">
                                                    <b-row>
                                                        <b-col sm="6" v-if="form.documenti.agente1.path">
                                                            <b-button variant="primary" @click="downloadDoc(form.documenti.agente1.id)">Visualizza file <i class="fa fa-search" aria-hidden="true"></i></b-button>
                                                            <b-form-checkbox v-model="form.documenti.agente1.delete" class="ml-3">
                                                                Rimuovi da Egnyte
                                                            </b-form-checkbox>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <b-form-group horizontal label="Nuovo file" :label-cols="3">
                                                                <b-form-file
                                                                v-model="documenti_files.agente1"
                                                                :state="Boolean(documenti_files.agente1)"
                                                                placeholder="Scegli un file da caricare..."
                                                                drop-placeholder="Cancella file..."
                                                                ></b-form-file>
                                                            </b-form-group>
                                                        </b-col>
                                                    </b-row>
                                                </b-form-group>

                                                <b-form-group v-if="auth.isUserEnable('egnyte')" horizontal label="Mandato agente 2" :label-cols="3">
                                                    <b-row>
                                                        <b-col sm="6" v-if="form.documenti.agente2.path">
                                                            <b-button variant="primary" @click="downloadDoc(form.documenti.agente2.id)">Visualizza file <i class="fa fa-search" aria-hidden="true"></i></b-button>
                                                            <b-form-checkbox v-model="form.documenti.agente2.delete" class="ml-3">
                                                                Rimuovi da Egnyte
                                                            </b-form-checkbox>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <b-form-group horizontal label="Nuovo file" :label-cols="3">
                                                                <b-form-file
                                                                v-model="documenti_files.agente2"
                                                                :state="Boolean(documenti_files.agente2)"
                                                                placeholder="Scegli un file da caricare..."
                                                                drop-placeholder="Cancella file..."
                                                                ></b-form-file>
                                                            </b-form-group>
                                                        </b-col>
                                                    </b-row>
                                                </b-form-group>
                                                
                                                <b-form-group id="rimborsoGroup" horizontal label="Rimborso spese" :label-cols="3" label-for="rimborso">
                                                    <b-form-radio-group id="rimborso" v-model="form.info_economica.rimborso_spese">
                                                        <b-form-radio :value="0">No</b-form-radio>
                                                        <b-form-radio :value="1">Si</b-form-radio>
                                                    </b-form-radio-group>
                                                </b-form-group>

                                                <b-form-group id="stipendioGroup" horizontal label="Stipendio/Rimborso lordo fisso annuo" :label-cols="3" label-for="stipendio">
                                                    <b-form-input id="stipendio" type="text" v-model="form.contratto.stipendio_lordo"></b-form-input>
                                                </b-form-group>

                                                <b-form-group id="ibanGroup" horizontal label="IBAN" :label-cols="3" label-for="iban">
                                                    <b-form-input id="iban" type="text" v-model="form.info_economica.iban"></b-form-input>
                                                </b-form-group>

                                                <b-form-group id="intestatarioGroup" horizontal label="Intestatario IBAN" :label-cols="3" label-for="intestatario">
                                                    <b-form-input id="intestatario" type="text" v-model="form.info_economica.intestatario_iban"></b-form-input>
                                                </b-form-group>

                                                <b-form-group id="scuolaGroup" horizontal label="Scuola AS Roma" :label-cols="3" label-for="scuola">
                                                    <b-form-radio-group id="scuola" v-model="form.giocatore.frequenta_scuola_asroma">
                                                        <b-form-radio :value="0">No</b-form-radio>
                                                        <b-form-radio :value="1">Si</b-form-radio>
                                                    </b-form-radio-group>
                                                </b-form-group>

                                                <b-form-group id="scuolaACaricoGroup" horizontal label="Scuola a carico AS Roma" :label-cols="3" label-for="scuola_a_carico">
                                                    <b-form-radio-group id="scuola_a_carico" v-model="form.info_economica.scuola_a_carico">
                                                        <b-form-radio :value="0">No</b-form-radio>
                                                        <b-form-radio :value="1">Si</b-form-radio>
                                                    </b-form-radio-group>
                                                </b-form-group>
                                            </div>
                                            <!-- Dati Economici - Edit - Football fields END -->
                                        </div>
                                        
                                    </fieldset>
                                    
                                    <!-- Documenti -->
                                    <fieldset v-show="$route.query.tab == 'documenti'">

                                        <h5 class="card-subtitle mb-3 color-rosso">Documenti</h5>

                                        <!-- View -->
                                        <div v-show="!enableEdit">
                                            <table class="table table-sm table-giocatore mb-0 p-0">
                                                <!-- Documenti - View - Football fields -->
                                                <template v-if="auth.isUserEnable('rosa_tab_documenti')">

                                                    <tr v-for="(passaporto, index) in passaporti"> 
                                                        <td>
                                                            Passaporto {{ index + 1 }} 
                                                            <flag v-if="passaporto.paese" :country="passaporto.paese.sigla" :size="16"></flag> 
                                                        </td> 
                                                        <td> 
                                                            <span style="color:#000000;font-weight:normal;"> n°: </span> 
                                                            {{ passaporto.numero }} 
                                                            <span style="color:#000000;font-weight:normal;margin-left:20px;"> scade il: </span> 
                                                            {{ passaporto.scadenza | formatDate}} 
                                                            <b-button 
                                                                v-if="auth.isUserEnable('egnyte') && passaporto.path" 
                                                                @click="downloadDoc(passaporto.id)" class="ml-2"
                                                                size="sm" variant="primary" title="Visualizza file" 
                                                            >
                                                                    <i class="fa fa-search" aria-hidden="true"></i>
                                                            </b-button>
                                                        </td> 
                                                    </tr>
                                                    <tr v-if="cartaidentita"> 
                                                        <td>Carta d'identità </td> 
                                                        <td> 
                                                            <span style="color:#000000;font-weight:normal;"> n°: </span> 
                                                            {{ cartaidentita.numero }} 
                                                            <span style="color:#000000;font-weight:normal;margin-left:20px;"> scade il: </span> 
                                                            {{ cartaidentita.scadenza | formatDate}} 
                                                            <b-button 
                                                                v-if="auth.isUserEnable('egnyte') && cartaidentita.path" 
                                                                @click="downloadDoc(cartaidentita.id)" class="ml-2"
                                                                size="sm" variant="primary" title="Visualizza file" 
                                                            >
                                                                <i class="fa fa-search" aria-hidden="true"></i>
                                                            </b-button>
                                                        </td> 
                                                    </tr>
                                                    <tr v-if="codicefiscale"> 
                                                        <td>Tessera sanitaria / Codice fiscale </td> 
                                                        <td> 
                                                            <span style="color:#000000;font-weight:normal;"> n°: </span> 
                                                            {{ codicefiscale.numero }} 
                                                            <span style="color:#000000;font-weight:normal;margin-left:20px;"> scade il: </span> 
                                                            {{ codicefiscale.scadenza | formatDate}} 
                                                            <b-button 
                                                                v-if="auth.isUserEnable('egnyte') && codicefiscale.path" 
                                                                @click="downloadDoc(codicefiscale.id)" class="ml-2"
                                                                size="sm" variant="primary" title="Visualizza file" 
                                                            >
                                                                <i class="fa fa-search" aria-hidden="true"></i>
                                                            </b-button>
                                                        </td> 
                                                    </tr>
                                                    <tr v-for="(patente, index) in patenti"> 
                                                        <td>
                                                            Patente {{ index + 1 }} <flag v-if="patente.paese" :country="patente.paese.sigla" :size="16"></flag> 
                                                        </td> 
                                                        <td> 
                                                            <span style="color:#000000;font-weight:normal;"> n°: </span> 
                                                            {{ patente.numero }} 
                                                            <span style="color:#000000;font-weight:normal;margin-left:20px;"> scade il: </span> 
                                                            {{ patente.scadenza | formatDate}} 
                                                            <b-button 
                                                                v-if="auth.isUserEnable('egnyte') && patente.path" 
                                                                @click="downloadDoc(patente.id)" class="ml-2"
                                                                size="sm" variant="primary" title="Visualizza file" 
                                                            >
                                                                <i class="fa fa-search" aria-hidden="true"></i>
                                                            </b-button>
                                                        </td> 
                                                    </tr>
                                                    <tr v-if="circolazione"> 
                                                        <td>Permesso di circolazione </td>
                                                        <td> 
                                                            <span style="color:#000000;font-weight:normal;margin-left:20px;"> scade il: </span> 
                                                            {{ circolazione.scadenza | formatDate}} 
                                                            <b-button 
                                                                v-if="auth.isUserEnable('egnyte') && circolazione.path" 
                                                                @click="downloadDoc(circolazione.id)" class="ml-2"
                                                                size="sm" variant="primary" title="Visualizza file" 
                                                            >
                                                                <i class="fa fa-search" aria-hidden="true"></i>
                                                            </b-button>
                                                        </td> 
                                                    </tr>
                                                    <tr v-if="soggiorno"> 
                                                        <td>Permesso di soggiorno </td> 
                                                        <td> 
                                                            <span style="color:#000000;font-weight:normal;margin-left:20px;"> scade il: </span> 
                                                            {{ soggiorno.scadenza | formatDate}} 
                                                            <b-button 
                                                                v-if="auth.isUserEnable('egnyte') && soggiorno.path" 
                                                                @click="downloadDoc(soggiorno.id)" 
                                                                size="sm" variant="primary" title="Visualizza file" class="ml-2"
                                                            >
                                                                <i class="fa fa-search" aria-hidden="true"></i>
                                                            </b-button>
                                                        </td> 
                                                    </tr>
                                                    <tr v-for="(mandato, index) in mandati"> 
                                                        <td>Mandato agente {{ index > 0 ? (index + 1) : ''}}</td> 
                                                        <td>  
                                                            <b-button
                                                                v-if="auth.isUserEnable('egnyte') && mandato.path" 
                                                                @click="downloadDoc(mandato.id)" class="ml-2"
                                                                size="sm" variant="primary" title="Visualizza file" 
                                                            >
                                                                <i class="fa fa-search" aria-hidden="true"></i>
                                                            </b-button>
                                                        </td> 
                                                    </tr>
                                                    <tr v-if="idoneita && idoneita.path && auth.isUserEnable('egnyte')">
                                                        <td>Certificato idoneità sportiva </td> 
                                                        <td>
                                                            <span style="color:#000000;font-weight:normal;margin-left:20px;"> scade il: </span> 
                                                            {{ idoneita.scadenza | formatDate}} 
                                                            <b-button 
                                                                v-if="auth.isUserEnable('egnyte') && idoneita.path" 
                                                                @click="downloadDoc(idoneita.id)" class="ml-2"
                                                                size="sm" variant="primary" title="Visualizza file" 
                                                            >
                                                                <i class="fa fa-search" aria-hidden="true"></i>
                                                            </b-button>
                                                        </td>
                                                    </tr>
                                                    <tr v-if="tessera && tessera.path && auth.isUserEnable('egnyte')"> 
                                                        <td>Tessera </td> 
                                                        <td> 
                                                            <b-button v-if="auth.isUserEnable('egnyte') && tessera.path" 
                                                                size="sm" variant="primary" title="Visualizza file"
                                                                @click="downloadDoc(tessera.id)" class="ml-2"
                                                            >
                                                                <i class="fa fa-search" aria-hidden="true"></i>
                                                            </b-button>
                                                        </td>
                                                    </tr>
                                                    <tr v-if="contratto && auth.isUserEnable('egnyte') && auth.isUserEnable('contratti')"> 
                                                        <td>Contratto </td> 
                                                        <td> 
                                                            <span style="color:#000000;font-weight:normal;margin-left:20px;"> scade il: </span> 
                                                            {{ contratto.scadenza | formatDate}} 
                                                            <b-button 
                                                                v-if="auth.isUserEnable('egnyte') && auth.isUserEnable('contratti') && contratto.path" 
                                                                size="sm" variant="primary" title="Visualizza file" 
                                                                @click="downloadDoc(contratto.id)" class="ml-2"
                                                            >
                                                                <i class="fa fa-search" aria-hidden="true"></i>
                                                            </b-button>
                                                        </td>
                                                    </tr>
                                                    <tr v-if="greenpass"> 
                                                        <td>Green Pass</td> 
                                                        <td> 
                                                            <span style="color:#000000;font-weight:normal;margin-left:20px;"> scade il: </span> 
                                                            {{ greenpass.scadenza | formatDate}} 
                                                            <b-button 
                                                                v-if="auth.isUserEnable('egnyte') && greenpass.path" 
                                                                size="sm" variant="primary" title="Visualizza file" 
                                                                @click="downloadDoc(greenpass.id)" class="ml-2"
                                                            >
                                                                <i class="fa fa-search" aria-hidden="true"></i>
                                                            </b-button>
                                                        </td> 
                                                    </tr>
                                                </template>
                                                <!-- Documenti - View - Football fields END -->
                                            </table>  
                                        </div>

                                        <!-- Edit -->
                                        <div v-show="enableEdit">
                                            <!-- Documenti - Edit - Football fields -->
                                            <div v-show="auth.isUserEnable('rosa_tab_documenti_edit')">
                                                <b-card bg-variant="transparent" class="mb-2">
                                                    <h5 class="card-subtitle mb-3 color-rosso">Passaporto 1</h5>
                                                    <b-row>
                                                        <b-col sm="4">
                                                            <b-form-group horizontal label="Nazione" :label-cols="3" label-text-align="right">
                                                                <autocomplete-paese :suggestions="paesi" id="passaporto1" ref="passaporto1" @select="onPassaporto1Select" @change="onPassaporto1Change" v-model="form.documenti.passaporto1.nazione"></autocomplete-paese>
                                                            </b-form-group>
                                                        </b-col>
                                                        <b-col sm="4">
                                                            <b-form-group horizontal label="Numero" :label-cols="3" label-text-align="right">
                                                                <b-form-input
                                                                v-model="form.documenti.passaporto1.numero"
                                                                placeholder="Numero"
                                                                ></b-form-input>
                                                            </b-form-group>
                                                        </b-col>
                                                        <b-col sm="4">
                                                            <b-form-group horizontal label="Scadenza" :label-cols="3" label-text-align="right">
                                                                <b-form-input
                                                                v-model="form.documenti.passaporto1.scadenza"
                                                                type="date"
                                                                ></b-form-input>
                                                            </b-form-group>
                                                        </b-col>
                                                        <b-col sm="6" v-if="auth.isUserEnable('egnyte') && form.documenti.passaporto1.path">
                                                            <b-button variant="primary" @click="downloadDoc(form.documenti.passaporto1.id)">Visualizza file <i class="fa fa-search" aria-hidden="true"></i></b-button>
                                                            <b-form-checkbox v-model="form.documenti.passaporto1.delete" class="ml-3">
                                                                Rimuovi da Egnyte
                                                            </b-form-checkbox>
                                                        </b-col>
                                                        <b-col sm="6" v-if="auth.isUserEnable('egnyte')">
                                                            <b-form-group horizontal label="Nuovo file" :label-cols="3">
                                                                <b-form-file
                                                                v-model="documenti_files.passaporto1"
                                                                :state="Boolean(documenti_files.passaporto1)"
                                                                placeholder="Scegli un file da caricare..."
                                                                drop-placeholder="Cancella file..."
                                                                ></b-form-file>
                                                            </b-form-group>
                                                        </b-col>
                                                    </b-row>
                                                </b-card>
                                                <b-card bg-variant="transparent" class="mb-2">
                                                    <h5 class="card-subtitle mb-3 color-rosso">Passaporto 2</h5>
                                                    <b-row>
                                                        <b-col sm="4">
                                                            <b-form-group horizontal label="Nazione" :label-cols="3" label-text-align="right">
                                                                <autocomplete-paese :suggestions="paesi" id="passaporto2" ref="passaporto2" @select="onPassaporto2Select" @change="onPassaporto2Change" v-model="form.documenti.passaporto2.nazione"></autocomplete-paese>
                                                            </b-form-group>
                                                        </b-col>
                                                        <b-col sm="4">
                                                            <b-form-group horizontal label="Numero" :label-cols="3" label-text-align="right">
                                                                <b-form-input
                                                                v-model="form.documenti.passaporto2.numero"
                                                                placeholder="Numero"
                                                                ></b-form-input>
                                                            </b-form-group>
                                                        </b-col>
                                                        <b-col sm="4">
                                                            <b-form-group horizontal label="Scadenza" :label-cols="3" label-text-align="right">
                                                                <b-form-input
                                                                v-model="form.documenti.passaporto2.scadenza"
                                                                type="date"
                                                                ></b-form-input>
                                                            </b-form-group>
                                                        </b-col>
                                                        <b-col sm="6" v-if="auth.isUserEnable('egnyte') && form.documenti.passaporto2.path">
                                                            <b-button variant="primary" @click="downloadDoc(form.documenti.passaporto2.id)">Visualizza file <i class="fa fa-search" aria-hidden="true"></i></b-button>
                                                            <b-form-checkbox v-model="form.documenti.passaporto2.delete" class="ml-3">
                                                                Rimuovi da Egnyte
                                                            </b-form-checkbox>
                                                        </b-col>
                                                        <b-col sm="6" v-if="auth.isUserEnable('egnyte')">
                                                            <b-form-group horizontal label="Nuovo file" :label-cols="3">
                                                                <b-form-file
                                                                v-model="documenti_files.passaporto2"
                                                                :state="Boolean(documenti_files.passaporto2)"
                                                                placeholder="Scegli un file da caricare..."
                                                                drop-placeholder="Cancella file..."
                                                                ></b-form-file>
                                                            </b-form-group>
                                                        </b-col>
                                                    </b-row>
                                                </b-card>
                                                <b-card bg-variant="transparent" class="mb-2">
                                                    <h5 class="card-subtitle mb-3 color-rosso">Carta d'identità</h5>
                                                    <b-row>
                                                        <b-col sm="6">
                                                            <b-form-group horizontal label="Numero" :label-cols="3" label-text-align="right">
                                                                <b-form-input
                                                                v-model="form.documenti.cartaidentita.numero"
                                                                placeholder="Numero"
                                                                ></b-form-input>
                                                            </b-form-group>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <b-form-group horizontal label="Scadenza" :label-cols="3" label-text-align="right">
                                                                <b-form-input
                                                                v-model="form.documenti.cartaidentita.scadenza"
                                                                type="date"
                                                                ></b-form-input>
                                                            </b-form-group>
                                                        </b-col>
                                                        <b-col sm="6" v-if="auth.isUserEnable('egnyte') && form.documenti.cartaidentita.path">
                                                            <b-button variant="primary" @click="downloadDoc(form.documenti.cartaidentita.id)">Visualizza file <i class="fa fa-search" aria-hidden="true"></i></b-button>
                                                            <b-form-checkbox v-model="form.documenti.cartaidentita.delete" class="ml-3">
                                                                Rimuovi da Egnyte
                                                            </b-form-checkbox>
                                                        </b-col>
                                                        <b-col sm="6" v-if="auth.isUserEnable('egnyte')">
                                                            <b-form-group horizontal label="Nuovo file" :label-cols="3">
                                                                <b-form-file
                                                                v-model="documenti_files.cartaidentita"
                                                                :state="Boolean(documenti_files.cartaidentita)"
                                                                placeholder="Scegli un file da caricare..."
                                                                drop-placeholder="Cancella file..."
                                                                ></b-form-file>
                                                            </b-form-group>
                                                        </b-col>
                                                    </b-row>
                                                </b-card>
                                                <b-card bg-variant="transparent" class="mb-2">
                                                    <h5 class="card-subtitle mb-3 color-rosso">Tessera sanitaria / Codice fiscale</h5>
                                                    <b-row>
                                                        <b-col sm="6">
                                                            <b-form-group horizontal label="Codice fiscale" :label-cols="3" label-text-align="right">
                                                                <b-form-input
                                                                v-model="form.documenti.codicefiscale.numero"
                                                                placeholder="Codice fiscale"
                                                                ></b-form-input>
                                                            </b-form-group>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <b-form-group horizontal label="Scadenza" :label-cols="3" label-text-align="right">
                                                                <b-form-input
                                                                v-model="form.documenti.codicefiscale.scadenza"
                                                                type="date"
                                                                ></b-form-input>
                                                            </b-form-group>
                                                        </b-col>
                                                        <b-col sm="6" v-if="auth.isUserEnable('egnyte') && form.documenti.codicefiscale.path">
                                                            <b-button variant="primary" @click="downloadDoc(form.documenti.codicefiscale.id)">Visualizza file <i class="fa fa-search" aria-hidden="true"></i></b-button>
                                                            <b-form-checkbox v-model="form.documenti.codicefiscale.delete" class="ml-3">
                                                                Rimuovi da Egnyte
                                                            </b-form-checkbox>
                                                        </b-col>
                                                        <b-col sm="6" v-if="auth.isUserEnable('egnyte')">
                                                            <b-form-group horizontal label="Nuovo file" :label-cols="3">
                                                                <b-form-file
                                                                v-model="documenti_files.codicefiscale"
                                                                :state="Boolean(documenti_files.codicefiscale)"
                                                                placeholder="Scegli un file da caricare..."
                                                                drop-placeholder="Cancella file..."
                                                                ></b-form-file>
                                                            </b-form-group>
                                                        </b-col>
                                                    </b-row>
                                                </b-card>
                                                <b-card bg-variant="transparent" class="mb-2">
                                                    <h5 class="card-subtitle mb-3 color-rosso">Patente di guida 1</h5>
                                                    <b-row>
                                                        <b-col sm="4">
                                                            <b-form-group horizontal label="Nazione" :label-cols="3" label-text-align="right">
                                                                <autocomplete-paese :suggestions="paesi" id="patente1" ref="patente1" @select="onPatente1Select" @change="onPatente1Change" v-model="form.documenti.patente1.nazione"></autocomplete-paese>
                                                            </b-form-group>
                                                        </b-col>
                                                        <b-col sm="4">
                                                            <b-form-group horizontal label="Numero" :label-cols="3" label-text-align="right">
                                                                <b-form-input
                                                                v-model="form.documenti.patente1.numero"
                                                                placeholder="Numero"
                                                                ></b-form-input>
                                                            </b-form-group>
                                                        </b-col>
                                                        <b-col sm="4">
                                                            <b-form-group horizontal label="Scadenza" :label-cols="3" label-text-align="right">
                                                                <b-form-input
                                                                v-model="form.documenti.patente1.scadenza"
                                                                type="date"
                                                                ></b-form-input>
                                                            </b-form-group>
                                                        </b-col>
                                                        <b-col sm="6" v-if="auth.isUserEnable('egnyte') && form.documenti.patente1.path">
                                                            <b-button variant="primary" @click="downloadDoc(form.documenti.patente1.id)">Visualizza file <i class="fa fa-search" aria-hidden="true"></i></b-button>
                                                            <b-form-checkbox v-model="form.documenti.patente1.delete" class="ml-3">
                                                                Rimuovi da Egnyte
                                                            </b-form-checkbox>
                                                        </b-col>
                                                        <b-col sm="6" v-if="auth.isUserEnable('egnyte')">
                                                            <b-form-group horizontal label="Nuovo file" :label-cols="3">
                                                                <b-form-file
                                                                v-model="documenti_files.patente1"
                                                                :state="Boolean(documenti_files.patente1)"
                                                                placeholder="Scegli un file da caricare..."
                                                                drop-placeholder="Cancella file..."
                                                                ></b-form-file>
                                                            </b-form-group>
                                                        </b-col>
                                                    </b-row>
                                                </b-card>
                                                <b-card bg-variant="transparent" class="mb-2">
                                                    <h5 class="card-subtitle mb-3 color-rosso">Patente di guida 2</h5>
                                                    <b-row>
                                                        <b-col sm="4">
                                                            <b-form-group horizontal label="Nazione" :label-cols="3" label-text-align="right">
                                                                <autocomplete-paese :suggestions="paesi" id="patente2" ref="patente2" @select="onPatente2Select" @change="onPatente2Change" v-model="form.documenti.patente2.nazione"></autocomplete-paese>
                                                            </b-form-group>
                                                        </b-col>
                                                        <b-col sm="4">
                                                            <b-form-group horizontal label="Numero" :label-cols="3" label-text-align="right">
                                                                <b-form-input
                                                                v-model="form.documenti.patente2.numero"
                                                                placeholder="Numero"
                                                                ></b-form-input>
                                                            </b-form-group>
                                                        </b-col>
                                                        <b-col sm="4">
                                                            <b-form-group horizontal label="Scadenza" :label-cols="3" label-text-align="right">
                                                                <b-form-input
                                                                v-model="form.documenti.patente2.scadenza"
                                                                type="date"
                                                                ></b-form-input>
                                                            </b-form-group>
                                                        </b-col>
                                                        <b-col sm="6" v-if="auth.isUserEnable('egnyte') && form.documenti.patente2.path">
                                                            <b-button variant="primary" @click="downloadDoc(form.documenti.patente2.id)">Visualizza file <i class="fa fa-search" aria-hidden="true"></i></b-button>
                                                            <b-form-checkbox v-model="form.documenti.patente2.delete" class="ml-3">
                                                                Rimuovi da Egnyte
                                                            </b-form-checkbox>
                                                        </b-col>
                                                        <b-col sm="6" v-if="auth.isUserEnable('egnyte')">
                                                            <b-form-group horizontal label="Nuovo file" :label-cols="3">
                                                                <b-form-file
                                                                v-model="documenti_files.patente2"
                                                                :state="Boolean(documenti_files.patente2)"
                                                                placeholder="Scegli un file da caricare..."
                                                                drop-placeholder="Cancella file..."
                                                                ></b-form-file>
                                                            </b-form-group>
                                                        </b-col>
                                                    </b-row>
                                                </b-card>
                                                <b-card bg-variant="transparent" class="mb-2">
                                                    <h5 class="card-subtitle mb-3 color-rosso">Permesso di circolazione</h5>
                                                    <b-row>
                                                        <b-col sm="4">
                                                            <b-form-group horizontal label="Scadenza" :label-cols="3" label-text-align="right">
                                                                <b-form-input
                                                                v-model="form.documenti.circolazione.scadenza"
                                                                type="date"
                                                                ></b-form-input>
                                                            </b-form-group>
                                                        </b-col>
                                                        <b-col sm="8">
                                                        </b-col>
                                                        <b-col sm="6" v-if="auth.isUserEnable('egnyte') && form.documenti.circolazione.path">
                                                            <b-button variant="primary" @click="downloadDoc(form.documenti.circolazione.id)">Visualizza file <i class="fa fa-search" aria-hidden="true"></i></b-button>
                                                            <b-form-checkbox v-model="form.documenti.circolazione.delete" class="ml-3">
                                                                Rimuovi da Egnyte
                                                            </b-form-checkbox>
                                                        </b-col>
                                                        <b-col sm="6" v-if="auth.isUserEnable('egnyte')">
                                                            <b-form-group horizontal label="Nuovo file" :label-cols="3">
                                                                <b-form-file
                                                                v-model="documenti_files.circolazione"
                                                                :state="Boolean(documenti_files.circolazione)"
                                                                placeholder="Scegli un file da caricare..."
                                                                drop-placeholder="Cancella file..."
                                                                ></b-form-file>
                                                            </b-form-group>
                                                        </b-col>
                                                    </b-row>
                                                </b-card>
                                                <b-card bg-variant="transparent" class="mb-2">
                                                    <h5 class="card-subtitle mb-3 color-rosso">Permesso di soggiorno</h5>
                                                    <b-row>
                                                        <b-col sm="4">
                                                            <b-form-group horizontal label="Scadenza" :label-cols="3" label-text-align="right">
                                                                <b-form-input
                                                                v-model="form.documenti.soggiorno.scadenza"
                                                                type="date"
                                                                ></b-form-input>
                                                            </b-form-group>
                                                        </b-col>
                                                        <b-col sm="8">
                                                        </b-col>
                                                        <b-col sm="6" v-if="form.documenti.soggiorno.path">
                                                            <b-button variant="primary" @click="downloadDoc(form.documenti.soggiorno.id)">Visualizza file <i class="fa fa-search" aria-hidden="true"></i></b-button>
                                                            <b-form-checkbox v-model="form.documenti.soggiorno.delete" class="ml-3">
                                                                Rimuovi da Egnyte
                                                            </b-form-checkbox>
                                                        </b-col>
                                                        <b-col sm="6" v-if="auth.isUserEnable('egnyte')">
                                                            <b-form-group horizontal label="Nuovo file" :label-cols="3">
                                                                <b-form-file
                                                                v-model="documenti_files.soggiorno"
                                                                :state="Boolean(documenti_files.soggiorno)"
                                                                placeholder="Scegli un file da caricare..."
                                                                drop-placeholder="Cancella file..."
                                                                ></b-form-file>
                                                            </b-form-group>
                                                        </b-col>
                                                    </b-row>
                                                </b-card>
                                                <b-card bg-variant="transparent" class="mb-2">
                                                    <h5 class="card-subtitle mb-3 color-rosso">Green Pass</h5>
                                                    <b-row>
                                                        <b-col sm="4">
                                                            <b-form-group horizontal label="Scadenza" :label-cols="3" label-text-align="right">
                                                                <b-form-input
                                                                v-model="form.documenti.greenpass.scadenza"
                                                                type="date"
                                                                ></b-form-input>
                                                            </b-form-group>
                                                        </b-col>
                                                        <b-col sm="8">
                                                        </b-col>
                                                        <b-col sm="6" v-if="form.documenti.greenpass.path">
                                                            <b-button variant="primary" @click="downloadDoc(form.documenti.greenpass.id)">Visualizza file <i class="fa fa-search" aria-hidden="true"></i></b-button>
                                                            <b-form-checkbox v-model="form.documenti.greenpass.delete" class="ml-3">
                                                                Rimuovi da Egnyte
                                                            </b-form-checkbox>
                                                        </b-col>
                                                        <b-col sm="6" v-if="auth.isUserEnable('egnyte')">
                                                            <b-form-group horizontal label="Nuovo file" :label-cols="3">
                                                                <b-form-file
                                                                v-model="documenti_files.greenpass"
                                                                :state="Boolean(documenti_files.greenpass)"
                                                                placeholder="Scegli un file da caricare..."
                                                                drop-placeholder="Cancella file..."
                                                                ></b-form-file>
                                                            </b-form-group>
                                                        </b-col>
                                                    </b-row>
                                                </b-card>
                                            </div>
                                            <!-- Documenti - Edit - Football fields END -->
                                        </div>
                                        
                                    </fieldset>

                                    <!-- Providers -->
                                    <fieldset v-show="$route.query.tab == 'providers'">
                                        <b-card>
                                            <b-row v-if="$route.params.persona_id">
                                                <b-col>
                                                    <entita-api-manager
                                                        :entityId="$route.params.persona_id" 
                                                        entity="Persona"
                                                        :readonly="!enableEdit"
                                                    />
                                                </b-col>
                                            </b-row>
                                            <div v-else>
                                                Devi prima salvare l'anagrafica giocatore per poter aggiungere un provider!		
                                            </div>
                                        </b-card>
                                    </fieldset>

                                    <!-- Parenti -->
                                    <fieldset v-show="$route.query.tab == 'parenti'">

                                        <h5 class="card-subtitle mb-3 color-rosso">Parenti</h5>

                                        <!-- View -->
                                        <div v-show="!enableEdit">
                                            <table class="table table-sm table-giocatore mb-0 p-0">
                                                <!-- Parenti - View - Football fields -->
                                                <template v-if="auth.isUserEnable('rosa_tab_parenti')">
                                                    <tr v-for="parente in parenti"> 
                                                        <td>{{ parente.tipo.nome.charAt(0).toUpperCase() + parente.tipo.nome.slice(1) }}</td> 
                                                        <td>{{ parente.relazionante.nome }} {{ parente.relazionante.cognome }} </td>
                                                    </tr>
                                                </template>
                                                <!-- Parenti - View - Football fields END -->
                                            </table>  
                                        </div>
                                        <!-- Edit -->
                                        <div v-show="enableEdit">
                                            
                                            <!-- Parenti - Edit - Football fields -->
                                            <div v-show="auth.isUserEnable('rosa_tab_generali_edit')">
                                                <b-form-group id="parentiGroup">
                                                    <b-form-row v-for="(item, index) in form.parenti" :key="index" class="mb-3 pt-3 border-bottom">
                                                        <b-col>
                                                            <b-form-group horizontal label="Relazione" :label-cols="3">
                                                                <b-form-select v-model="item.relazione" :options="relazioni" value-field="id" text-field="nome"></b-form-select>
                                                            </b-form-group>
                                                            <b-form-group horizontal label="Nome" :label-cols="3">
                                                                <b-form-input type="text" v-model="item.nome"></b-form-input>
                                                            </b-form-group>
                                                            <b-form-group horizontal label="Cognome" :label-cols="3">
                                                                <b-form-input type="text" v-model="item.cognome"></b-form-input>
                                                            </b-form-group>
                                                            <b-form-group horizontal label="Email" :label-cols="3">
                                                                <b-form-input type="email" v-model="item.email.valore"></b-form-input>
                                                            </b-form-group>
                                                            <b-form-group horizontal label="Telefono" :label-cols="3">
                                                                <b-form-input type="number" v-model="item.telefono.valore"></b-form-input>
                                                            </b-form-group>
                                                            <b-form-group class="text-right">
                                                                <b-button variant="danger" size="sm" @click="removeParente(item)"><i class="fa fa-trash fa-fw"></i> Rimuovi parente</b-button>
                                                            </b-form-group>
                                                        </b-col>
                                                    </b-form-row>
                                                    <b-button variant="outline-primary" @click="addParente"><i class="fa fa-plus fa-fw"></i> Aggiungi Parente</b-button>
                                                </b-form-group>
                                            </div>
                                            <!-- Parenti - Edit - Football fields END -->
                                        </div>

                                    </fieldset>
                                    
                                    <!-- Player Access -->
                                    <fieldset v-show="$route.query.tab == 'player-access'">

                                        <h5 class="card-subtitle mb-3 color-rosso">Player Access</h5>

                                        <!-- View -->
                                        <div v-show="!enableEdit">
                                            <table class="table table-sm table-giocatore mb-0 p-0" v-if="currentPlayer.persona.player_access">
                                                
                                                <!-- Player Access - View - Football fields -->
                                                <template v-if="auth.isUserEnable('rosa_tab_player_access')">
                                                    <tr> 
                                                        <td>Personal engagements</td> 
                                                        <td>{{currentPlayer.persona.player_access.personal_engagements}}</td>
                                                    </tr>
                                                    <tr> 
                                                        <td>Restrictions / Notes</td> 
                                                        <td>{{ currentPlayer.persona.player_access.restrictions }}</td> 
                                                    </tr>
                                                    <tr> 
                                                        <td>Ranking</td> 
                                                        <td>{{ currentPlayer.persona.player_access.ranking }}</td> 
                                                    </tr>
                                                </template>
                                                <!-- Player Access - View - Football fields END -->

                                            </table>
                                            <div v-else>
                                                Il giocatore non ha nessun dato disponibile!
                                            </div>
                                        </div>
                                        
                                        <!-- Edit -->
                                        <div v-show="enableEdit">
                                            
                                            <!-- Player Access - Edit - Football fields -->
                                            <div v-show="auth.isUserEnable('rosa_tab_player_access_edit')">

                                                <b-form-group horizontal label="Personal engagements" :label-cols="3">
                                                    <b-form-input type="text" v-model="form.player_access.personal_engagements"></b-form-input>
                                                </b-form-group>

                                                <b-form-group horizontal label="Restrictions / Notes" :label-cols="3">
                                                    <b-form-input type="text" v-model="form.player_access.restrictions"></b-form-input>
                                                </b-form-group>

                                                <b-form-group horizontal label="Ranking" :label-cols="3">
                                                    <b-form-select v-model="form.player_access.ranking">
                                                        <option :value="0">-- Select --</option>
                                                        <option :value="1">1</option>
                                                        <option :value="2">2</option>
                                                        <option :value="3">3</option>
                                                    </b-form-select>
                                                </b-form-group>

                                            </div>
                                            <!-- Player Access - Edit - Football fields END -->
                                        </div>

                                    </fieldset>
                                    
                                    <!-- Whiteboard -->
                                    <fieldset v-show="$route.query.tab == 'whiteboard'">

                                        <h5 class="card-subtitle mb-3 color-rosso">Whiteboard</h5>

                                        <!-- View -->
                                        <div v-show="!enableEdit">                                                
                                            <!-- Player Access - View - Football fields -->
                                            <div v-if="auth.isUserEnable('rosa_tab_whiteboard')">
                                                <div class="my-3">
                                                    <legend class="bv-no-focus-ring col-form-label pt-0">Avatar</legend>
                                                    <img v-if="image_board"
                                                        style="max-width: 150px; height: 150px; border: 1px solid #ddd; border-radius: 0.25rem; padding: 0.25rem;"
                                                        :src="image_board" 
                                                    />
                                                    <div v-else>Nessun immagine inserita per la whiteboard</div>
                                                </div>

                                                <div class="my-3">
                                                    <legend class="bv-no-focus-ring col-form-label pt-0">Biografia</legend>
                                                    <div v-if="currentPlayer.persona.info_extra && currentPlayer.persona.info_extra.info">{{ currentPlayer.persona.info_extra.info }}</div>
                                                    <div v-else>Nessuna biogragia inserita per la whiteboard</div>
                                                </div>
                                            </div>
                                            <!-- Player Access - View - Football fields END -->
                                        </div>
                                        
                                        <!-- Edit -->
                                        <div v-show="enableEdit">
                                            
                                            <!-- Player Access - Edit - Football fields -->
                                            <div  v-show="auth.isUserEnable('rosa_tab_whiteboard_edit')">

                                                <b-form-group horizontal label="Avatar" :key="'ba_' + random">
                                                    <picture-input 
                                                        ref="pictureBoard" 
                                                        width="150" height="150" size="10" class="my-1" 
                                                        @change="onBoardAvatarChange" 
                                                        :customStrings="customStrings" 
                                                        :prefill="board_avatar"
                                                    >
                                                    </picture-input>
                                                </b-form-group>
                                                <b-form-group horizontal label="Biografia">
                                                    <b-form-textarea
                                                        id="textarea"
                                                        v-model="form.info_extra.info"
                                                        rows="6"
                                                    ></b-form-textarea>
                                                </b-form-group>

                                            </div>
                                            <!-- Player Access - Edit - Football fields END -->
                                        </div>

                                    </fieldset>
                                       
                                    <!-- Medical -->
                                    <fieldset v-show="$route.query.tab == 'medical'">

                                        <h5 class="card-subtitle mb-3 color-rosso">Medical</h5>

                                        <!-- View -->
                                        <div v-show="!enableEdit">
                                            <b-tabs pills v-if="auth.isUserEnable('rosa_tab_medical') && currentPlayer.persona.cronologia_medica.length > 0">
                                                <b-tab :title="tipo.gruppo" v-for="tipo in cronologia_medica_tipi" v-if="currentPlayer.persona.cronologia_medica.filter((obj) => obj.cronologia_medica_tipo.id == tipo.id).length > 0">
                                                    <b-col v-if="tipo.id == 1">
                                                        <table class="table table-sm table-giocatore" v-if="anamnesi_medica_selected">
                                                            <tr v-if="anamnesi_medica_selected.data"> <td>Data</td> <td>{{ anamnesi_medica_selected.data | formatDate }}</td></tr>
                                                            <tr v-if="anamnesi_medica_selected.nome"> <td>Nome</td> <td>{{ anamnesi_medica_selected.nome }}</td></tr>
                                                            <tr v-if="anamnesi_medica_selected.anamnesi_medica.gruppo_sanguigno"> <td>Gruppo sanguigno</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.gruppo_sanguigno }}</td></tr>
                                                            <tr v-if="anamnesi_medica_selected.anamnesi_medica.visita_leva_abile !== null"> <td>Visita leva abile</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.visita_leva_abile ? 'Si' : 'No' }}</td></tr>
                                                            <tr v-if="anamnesi_medica_selected.anamnesi_medica.tipo_studio"> <td>Tipo studio</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.tipo_studio }}</td></tr>
                                                            <tr v-if="anamnesi_medica_selected.anamnesi_medica.altri_sport"> <td>Altri sport</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.altri_sport }}</td></tr>
                                                            <tr v-if="anamnesi_medica_selected.anamnesi_medica.appetito_normale !== null"> <td>Appetito normale</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.appetito_normale ? 'Si' : 'No' }}</td></tr>
                                                            <tr v-if="anamnesi_medica_selected.anamnesi_medica.digestione_normale !== null"> <td>Digestione normale</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.digestione_normale ? 'Si' : 'No' }}</td></tr>
                                                            <tr v-if="anamnesi_medica_selected.anamnesi_medica.alvo_normale !== null"> <td>Alvo normale</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.alvo_normale ? 'Si' : 'No' }}</td></tr>
                                                            <tr v-if="anamnesi_medica_selected.anamnesi_medica.diuresi_normale !== null"> <td>Diuresi normale</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.diuresi_normale ? 'Si' : 'No' }}</td></tr>
                                                            <tr v-if="anamnesi_medica_selected.anamnesi_medica.sonno_normale !== null"> <td>Sonno normale</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.sonno_normale ? 'Si' : 'No' }}</td></tr>
                                                            <tr v-if="anamnesi_medica_selected.anamnesi_medica.fumo !== null"> <td>Fumo</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.fumo ? 'Si' : 'No' }}</td></tr>
                                                            <tr v-if="anamnesi_medica_selected.anamnesi_medica.alcol !== null"> <td>Alcol</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.alcol ? 'Si' : 'No' }}</td></tr>
                                                            <tr v-if="anamnesi_medica_selected.anamnesi_medica.caffe !== null"> <td>Caffe</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.caffe ? 'Si' : 'No' }}</td></tr>
                                                            <tr v-if="anamnesi_medica_selected.anamnesi_medica.nota_anamnesi_fisiologica"><td>Anamnesi fisiologica</td> <td><span class="color-rosso ml-1"><i :id="'nota_anamnesi_fisiologica_' + anamnesi_medica_selected.id" class="fa fa-info-circle" aria-hidden="true" title="Nota"></i></span><b-popover title="Anamnesi fisiologica" :target="'nota_anamnesi_fisiologica_' + anamnesi_medica_selected.id" triggers="hover" placement="right">{{ anamnesi_medica_selected.anamnesi_medica.nota_anamnesi_fisiologica }}</b-popover></td></tr>
                                                            <tr v-if="anamnesi_medica_selected.anamnesi_medica.anamnesi_familiare"> <td>Anamnesi familiare</td> <td><span class="color-rosso ml-1"><i :id="'anamnesi_familiare_' + anamnesi_medica_selected.id" class="fa fa-info-circle" aria-hidden="true" title="Nota"></i></span><b-popover title="Anamnesi familiare" :target="'anamnesi_familiare_' + anamnesi_medica_selected.id" triggers="hover" placement="top">{{ anamnesi_medica_selected.anamnesi_medica.anamnesi_familiare }}</b-popover></td></tr>
                                                            <tr v-if="anamnesi_medica_selected.anamnesi_medica.vaccinazione_antitetanica !== null"> <td>Vaccinazione antitetanica</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.vaccinazione_antitetanica ? 'Si' : 'No' }}</td></tr>
                                                            <tr v-if="anamnesi_medica_selected.anamnesi_medica.vaccinazione_antiepatite_virale_b !== null"> <td>Vaccinazione antiepatite virale B</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.vaccinazione_antiepatite_virale_b ? 'Si' : 'No' }}</td></tr>
                                                            <tr v-if="anamnesi_medica_selected.anamnesi_medica.altre_visite_idoneita_agonistica !== null"> <td>Altre visite idoneità agonistica</td> <td>{{ anamnesi_medica_selected.anamnesi_medica.altre_visite_idoneita_agonistica ? 'Si' : 'No' }}</td></tr>
                                                            <tr v-if="anamnesi_medica_selected.anamnesi_medica.dichiarato_idoneo_per_meno_di_12_mesi !== null"> <td>Dichiarato idoneo per meno di 12 mesi <span class="color-rosso ml-1"><i v-if="anamnesi_medica_selected.anamnesi_medica.nota_idoneo_per_meno_di_12_mesi" :id="'nota_idoneo_per_meno_di_12_mesi_' + anamnesi_medica_selected.id" class="fa fa-info-circle" aria-hidden="true" title="Nota"></i></span><b-popover title="Nota idoneo per meno di 12 mesi" :target="'nota_idoneo_per_meno_di_12_mesi_' + anamnesi_medica_selected.id" triggers="hover" placement="right">{{ anamnesi_medica_selected.anamnesi_medica.nota_idoneo_per_meno_di_12_mesi }}</b-popover></td> <td>{{ anamnesi_medica_selected.anamnesi_medica.dichiarato_idoneo_per_meno_di_12_mesi ? 'Si' : 'No' }}</td></tr>
                                                            <tr v-if="anamnesi_medica_selected.anamnesi_medica.dichiarato_inidoneo !== null"> <td>Dichiarato inidoneo <span class="color-rosso ml-1"><i v-if="anamnesi_medica_selected.anamnesi_medica.nota_inidoneo" :id="'nota_inidoneo_' + anamnesi_medica_selected.id" class="fa fa-info-circle" aria-hidden="true" title="Nota"></i></span><b-popover title="Nota inidoneo" :target="'nota_inidoneo_' + anamnesi_medica_selected.id" triggers="hover" placement="right">{{ anamnesi_medica_selected.anamnesi_medica.nota_inidoneo }}</b-popover></td> <td>{{ anamnesi_medica_selected.anamnesi_medica.dichiarato_inidoneo ? 'Si' : 'No' }}</td></tr>
                                                            <tr v-if="anamnesi_medica_selected.anamnesi_medica.provvedimenti_disciplinari_doping !== null"> <td>Provvedimenti disciplinari doping <span class="color-rosso ml-1"><i v-if="anamnesi_medica_selected.anamnesi_medica.nota_doping" :id="'nota_doping_' + anamnesi_medica_selected.id" class="fa fa-info-circle" aria-hidden="true" title="Nota"></i></span><b-popover title="Nota doping" :target="'nota_doping_' + anamnesi_medica_selected.id" triggers="hover" placement="right">{{ anamnesi_medica_selected.anamnesi_medica.nota_doping }}</b-popover> </td> <td>{{ anamnesi_medica_selected.anamnesi_medica.provvedimenti_disciplinari_doping ? 'Si' : 'No' }}</td></tr>
                                                        </table>
                                                        <b-pagination v-if="currentPlayer.persona.cronologia_medica.filter((obj) => obj.cronologia_medica_tipo.id == tipo.id).length > 1" size="sm" align="center" :total-rows="currentPlayer.persona.cronologia_medica.filter((obj) => obj.cronologia_medica_tipo.id == tipo.id).length" v-model="anamnesi_medica_count" :per-page="1" @change="selectAnamnesiMedica"></b-pagination>
                                                    </b-col>
                                                    <b-col v-else>
                                                        <table class="table table-sm">
                                                            <tr>
                                                                <th v-if="tipo.has_data">Data</th>
                                                                <th v-if="tipo.has_nome">Nome</th>
                                                                <th v-if="tipo.has_data_scadenza">Scadenza</th>
                                                                <th v-if="tipo.has_numero_lotto">N° lotto</th>
                                                                <th v-if="tipo.has_data_rinnovo">Rinnovo</th>
                                                                <th v-if="tipo.has_nota">Nota</th>
                                                            </tr>
                                                            <tr v-for="item in currentPlayer.persona.cronologia_medica.filter((obj) => obj.cronologia_medica_tipo.id == tipo.id)"> 
                                                                <td v-if="tipo.has_data">{{ item.data | formatDate }}</td>
                                                                <td v-if="tipo.has_nome">{{ item.nome }}</td>
                                                                <td v-if="tipo.has_data_scadenza">{{ item.data_scadenza | formatDate }}</td>
                                                                <td v-if="tipo.has_numero_lotto">{{ item.numero_lotto }}</td>
                                                                <td v-if="tipo.has_data_rinnovo">{{ item.data_rinnovo | formatDate }}</td>
                                                                <td v-if="tipo.has_nota"><span class="color-rosso ml-1"><i v-if="item.nota" :id="'nota_' + item.id" class="fa fa-info-circle" aria-hidden="true" title="Nota"></i></span><b-popover title="Nota" :target="'nota_' + item.id" triggers="hover" placement="right">{{ item.nota }}</b-popover></td>
                                                            </tr>
                                                        </table>
                                                    </b-col>
                                                </b-tab>
                                            </b-tabs>
                                            <div v-else>
                                                Il giocatore non ha visite mediche registrate 
                                            </div>
                                        </div>

                                        <!-- Edit -->
                                        <div v-show="enableEdit">
                                            
                                            <!-- Medical - Edit - Football fields -->
                                            <div v-show="auth.isUserEnable('rosa_tab_medical_edit')">

                                                <b-card bg-variant="transparent" class="mb-2">
                                                    <h5 class="card-subtitle mb-3 color-rosso">Visite mediche</h5>
                                                    <b-row>
                                                        <b-col sm="4">
                                                            <b-form-group id="ultimaVisitaMedicaGroup" horizontal label="Ultima" :label-cols="3" label-for="ultima_visita_medica">
                                                                <b-form-input id="ultima_visita_medica" type="date" v-model="form.documenti.idoneita.emissione"></b-form-input>
                                                            </b-form-group>
                                                        </b-col>
                                                        <b-col sm="4">
                                                            <b-form-group id="prossimaVisitaMedicaGroup" horizontal label="Prossima" :label-cols="3" label-for="prossima_visita">
                                                                <b-form-input id="prossima_visita" type="date" v-model="form.documenti.idoneita.scadenza"></b-form-input>
                                                            </b-form-group>
                                                        </b-col>
                                                        <b-col sm="4">
                                                            <b-form-checkbox v-model="form.documenti.idoneita.intermedia" class="mt-2 ml-3">
                                                                Controllo medico intermedio
                                                            </b-form-checkbox>
                                                        </b-col>
                                                    </b-row>
                                                    <b-form-group v-if="auth.isUserEnable('egnyte')" horizontal label="Certificato idoneità sportiva" :label-cols="3">
                                                        <b-row>
                                                            <b-col sm="6" v-if="form.documenti.idoneita.path">
                                                                <b-button variant="primary" @click="downloadDoc(form.documenti.idoneita.id)">Visualizza file <i class="fa fa-search" aria-hidden="true"></i></b-button>
                                                                <b-form-checkbox v-model="form.documenti.idoneita.delete" class="ml-3">
                                                                    Rimuovi da Egnyte
                                                                </b-form-checkbox>
                                                            </b-col>
                                                            <b-col sm="6">
                                                                <b-form-group horizontal label="Nuovo file" :label-cols="3">
                                                                    <b-form-file
                                                                    v-model="documenti_files.idoneita"
                                                                    :state="Boolean(documenti_files.idoneita)"
                                                                    placeholder="Scegli un file da caricare..."
                                                                    drop-placeholder="Cancella file..."
                                                                    ></b-form-file>
                                                                </b-form-group>
                                                            </b-col>
                                                        </b-row>
                                                    </b-form-group>
                                                </b-card>
											    
                                                <h5 class="card-subtitle my-3 color-rosso" v-b-toggle="['m-1', 'm-2', 'm-3', 'm-4', 'm-5', 'm-6', 'm-7', 'm-8', 'm-9', 'm-10', 'm-11', 'm-12', 'm-13', 'm-14', 'm-15', 'm-16']">Cronologia medica</h5>
											
                                                <b-card bg-variant="transparent" class="mb-2" v-for="tipo in cronologia_medica_tipi" v-if="form.cronologia_medica.filter((obj) => obj.cronologia_medica_tipo.id == tipo.id).length > 0">
                                                    <b-form-group>

                                                        <h5 v-b-toggle="'m-' + tipo.id">{{ tipo.gruppo }}</h5>
                                                        <b-collapse :id="'m-' + tipo.id">
                                                            <b-form-row v-for="(item, index) in form.cronologia_medica.filter((obj) => obj.cronologia_medica_tipo.id == tipo.id)" :key="index" class="mb-3 pt-3 border-bottom">
                                                                <b-col>
                                                                    <div v-if="item.cronologia_medica_tipo.id == 1">
                                                                        <b-row>
                                                                            <b-col>
                                                                                <b-form-group label="Data">
                                                                                    <b-form-input type="date" v-model="item.data"></b-form-input>	
                                                                                </b-form-group>
                                                                            </b-col>
                                                                            <b-col>
                                                                                <b-form-group label="Nome">
                                                                                    <b-form-input type="text" v-model="item.nome"></b-form-input>	
                                                                                </b-form-group>
                                                                            </b-col>
                                                                        </b-row>
                                                                        <b-row>
                                                                            <b-col cols="4">
                                                                                <b-form-group label="Gruppo sanguigno">
                                                                                    <b-form-input type="text" v-model="item.anamnesi_medica.gruppo_sanguigno"></b-form-input>	
                                                                                </b-form-group>
                                                                            </b-col>
                                                                            <b-col cols="2">
                                                                                <b-form-group label="Visita leva abile">
                                                                                    <b-form-radio-group v-model="item.anamnesi_medica.visita_leva_abile">
                                                                                        <b-form-radio :value="1">Si</b-form-radio>
                                                                                        <b-form-radio :value="0">No</b-form-radio>
                                                                                    </b-form-radio-group>
                                                                                </b-form-group>
                                                                            </b-col>
                                                                            <b-col>
                                                                                <b-form-group label="Tipo di studio">
                                                                                    <b-form-radio-group v-model="item.anamnesi_medica.tipo_studio">
                                                                                        <b-form-radio value="scuola obbligo">scuola obbligo</b-form-radio>
                                                                                        <b-form-radio value="scuola media superiore">scuola media superiore</b-form-radio>
                                                                                        <b-form-radio value="laures">laurea</b-form-radio>
                                                                                    </b-form-radio-group>
                                                                                </b-form-group>
                                                                            </b-col>
                                                                        </b-row>
                                                                        <b-form-group label="Altri sport">
                                                                            <b-form-textarea
                                                                            id="textarea"
                                                                            v-model="item.anamnesi_medica.altri_sport"
                                                                            rows="2"
                                                                            ></b-form-textarea>
                                                                        </b-form-group>
                                                                        <b-row>
                                                                            <b-col>
                                                                                <b-form-group label="Appetito normale">
                                                                                    <b-form-radio-group v-model="item.anamnesi_medica.appetito_normale">
                                                                                        <b-form-radio :value="1">Si</b-form-radio>
                                                                                        <b-form-radio :value="0">No</b-form-radio>
                                                                                    </b-form-radio-group>
                                                                                </b-form-group>
                                                                            </b-col>
                                                                            <b-col>
                                                                                <b-form-group label="Digestione normale">
                                                                                    <b-form-radio-group v-model="item.anamnesi_medica.digestione_normale">
                                                                                        <b-form-radio :value="1">Si</b-form-radio>
                                                                                        <b-form-radio :value="0">No</b-form-radio>
                                                                                    </b-form-radio-group>
                                                                                </b-form-group>
                                                                            </b-col>
                                                                            <b-col>
                                                                                <b-form-group label="Alvo normale">
                                                                                    <b-form-radio-group v-model="item.anamnesi_medica.alvo_normale">
                                                                                        <b-form-radio :value="1">Si</b-form-radio>
                                                                                        <b-form-radio :value="0">No</b-form-radio>
                                                                                    </b-form-radio-group>
                                                                                </b-form-group>
                                                                            </b-col>
                                                                            <b-col>
                                                                                <b-form-group label="Diuresi normale">
                                                                                    <b-form-radio-group v-model="item.anamnesi_medica.diuresi_normale">
                                                                                        <b-form-radio :value="1">Si</b-form-radio>
                                                                                        <b-form-radio :value="0">No</b-form-radio>
                                                                                    </b-form-radio-group>
                                                                                </b-form-group>
                                                                            </b-col>
                                                                        </b-row>
                                                                        <b-row>
                                                                            <b-col>
                                                                                <b-form-group label="Sonno normale">
                                                                                    <b-form-radio-group v-model="item.anamnesi_medica.sonno_normale">
                                                                                        <b-form-radio :value="1">Si</b-form-radio>
                                                                                        <b-form-radio :value="0">No</b-form-radio>
                                                                                    </b-form-radio-group>
                                                                                </b-form-group>
                                                                            </b-col>
                                                                            <b-col>
                                                                                <b-form-group label="Fumo">
                                                                                    <b-form-radio-group v-model="item.anamnesi_medica.fumo">
                                                                                        <b-form-radio :value="1">Si</b-form-radio>
                                                                                        <b-form-radio :value="0">No</b-form-radio>
                                                                                    </b-form-radio-group>
                                                                                </b-form-group>
                                                                            </b-col>
                                                                            <b-col>
                                                                                <b-form-group label="Alcol">
                                                                                    <b-form-radio-group v-model="item.anamnesi_medica.alcol">
                                                                                        <b-form-radio :value="1">Si</b-form-radio>
                                                                                        <b-form-radio :value="0">No</b-form-radio>
                                                                                    </b-form-radio-group>
                                                                                </b-form-group>
                                                                            </b-col>
                                                                            <b-col>
                                                                                <b-form-group label="Caffe">
                                                                                    <b-form-radio-group v-model="item.anamnesi_medica.caffe">
                                                                                        <b-form-radio :value="1">Si</b-form-radio>
                                                                                        <b-form-radio :value="0">No</b-form-radio>
                                                                                    </b-form-radio-group>
                                                                                </b-form-group>
                                                                            </b-col>
                                                                        </b-row>
                                                                        <b-form-group horizontal label="Note anamnesi fisiologica">
                                                                            <b-form-textarea
                                                                            id="textarea"
                                                                            v-model="item.anamnesi_medica.nota_anamnesi_fisiologica"
                                                                            rows="5"
                                                                            ></b-form-textarea>
                                                                        </b-form-group>
                                                                        <b-form-group label="Anamnesi familiare">
                                                                            <b-form-textarea
                                                                            id="textarea"
                                                                            v-model="item.anamnesi_medica.anamnesi_familiare"
                                                                            rows="5"
                                                                            ></b-form-textarea>
                                                                        </b-form-group>
                                                                        <b-row>
                                                                            <b-col>
                                                                                <b-form-group label="Vaccinazione antitetanica">
                                                                                    <b-form-radio-group v-model="item.anamnesi_medica.vaccinazione_antitetanica">
                                                                                        <b-form-radio :value="1">Si</b-form-radio>
                                                                                        <b-form-radio :value="0">No</b-form-radio>
                                                                                    </b-form-radio-group>
                                                                                </b-form-group>
                                                                            </b-col>
                                                                            <b-col>
                                                                                <b-form-group label="Vaccinazione antiepatite virale B">
                                                                                    <b-form-radio-group v-model="item.anamnesi_medica.vaccinazione_antiepatite_virale_b">
                                                                                        <b-form-radio :value="1">Si</b-form-radio>
                                                                                        <b-form-radio :value="0">No</b-form-radio>
                                                                                    </b-form-radio-group>
                                                                                </b-form-group>
                                                                            </b-col>
                                                                            <b-col>
                                                                                <b-form-group label="Sottoposto ad altre visite di idoneità agonistica">
                                                                                    <b-form-radio-group v-model="item.anamnesi_medica.altre_visite_idoneita_agonistica">
                                                                                        <b-form-radio :value="1">Si</b-form-radio>
                                                                                        <b-form-radio :value="0">No</b-form-radio>
                                                                                    </b-form-radio-group>
                                                                                </b-form-group>
                                                                            </b-col>
                                                                        </b-row>
                                                                        <b-row>
                                                                            <b-col cols="5">
                                                                                <b-form-group label="Dichiarato idoneo per periodi inferiori a 12 mesi">
                                                                                    <b-form-radio-group v-model="item.anamnesi_medica.dichiarato_idoneo_per_meno_di_12_mesi">
                                                                                        <b-form-radio :value="1">Si</b-form-radio>
                                                                                        <b-form-radio :value="0">No</b-form-radio>
                                                                                    </b-form-radio-group>
                                                                                </b-form-group>
                                                                            </b-col>
                                                                            <b-col>
                                                                                <b-form-group>
                                                                                    <b-form-textarea
                                                                                    id="textarea"
                                                                                    v-model="item.anamnesi_medica.nota_idoneo_per_meno_di_12_mesi"
                                                                                    rows="3"
                                                                                    ></b-form-textarea>
                                                                                </b-form-group>
                                                                            </b-col>
                                                                        </b-row>
                                                                        <b-row>
                                                                            <b-col cols="5">
                                                                                <b-form-group label="Dichiarato inidoneo">
                                                                                    <b-form-radio-group v-model="item.anamnesi_medica.dichiarato_inidoneo">
                                                                                        <b-form-radio :value="1">Si</b-form-radio>
                                                                                        <b-form-radio :value="0">No</b-form-radio>
                                                                                    </b-form-radio-group>
                                                                                </b-form-group>
                                                                            </b-col>
                                                                            <b-col>
                                                                                <b-form-group>
                                                                                    <b-form-textarea
                                                                                    id="textarea"
                                                                                    v-model="item.anamnesi_medica.nota_inidoneo"
                                                                                    rows="3"
                                                                                    ></b-form-textarea>
                                                                                </b-form-group>
                                                                            </b-col>
                                                                        </b-row>
                                                                        <b-row>
                                                                            <b-col cols="5">
                                                                                <b-form-group label="Provvedimenti disciplinari per doping">
                                                                                    <b-form-radio-group v-model="item.anamnesi_medica.provvedimenti_disciplinari_doping">
                                                                                        <b-form-radio :value="1">Si</b-form-radio>
                                                                                        <b-form-radio :value="0">No</b-form-radio>
                                                                                    </b-form-radio-group>
                                                                                </b-form-group>
                                                                            </b-col>
                                                                            <b-col>
                                                                                <b-form-group>
                                                                                    <b-form-textarea
                                                                                    id="textarea"
                                                                                    v-model="item.anamnesi_medica.nota_doping"
                                                                                    rows="3"
                                                                                    ></b-form-textarea>
                                                                                </b-form-group>
                                                                            </b-col>
                                                                        </b-row>
                                                                    </div>
                                                                    <div v-else>
                                                                        <b-row>
                                                                            <b-col>
                                                                                <b-form-group label="Data" v-if="tipo.has_data">
                                                                                    <b-form-input type="date" v-model="item.data"></b-form-input>	
                                                                                </b-form-group>
                                                                            </b-col>
                                                                            <b-col>
                                                                                <b-form-group label="Nome" v-if="tipo.has_nome">
                                                                                    <b-form-input type="text" v-model="item.nome"></b-form-input>	
                                                                                </b-form-group>
                                                                            </b-col>
                                                                        </b-row>
                                                                        <b-form-group label="Nota" v-if="tipo.has_nota">
                                                                            <b-form-textarea
                                                                            id="textarea"
                                                                            v-model="item.nota"
                                                                            rows="3"
                                                                            ></b-form-textarea>
                                                                        </b-form-group>
                                                                        <b-row>
                                                                            <b-col>
                                                                                <b-form-group label="Data scadenza" v-if="tipo.has_data_scadenza">
                                                                                    <b-form-input type="date" v-model="item.data_scadenza"></b-form-input>	
                                                                                </b-form-group>
                                                                            </b-col>
                                                                            <b-col>
                                                                                <b-form-group label="Numero lotto" v-if="tipo.has_numero_lotto">
                                                                                    <b-form-input type="text" v-model="item.numero_lotto"></b-form-input>	
                                                                                </b-form-group>
                                                                            </b-col>
                                                                            <b-col>
                                                                                <b-form-group label="Data rinnovo" v-if="tipo.has_data_rinnovo">
                                                                                    <b-form-input type="date" v-model="item.data_rinnovo"></b-form-input>	
                                                                                </b-form-group>
                                                                            </b-col>
                                                                        </b-row>
                                                                    </div>
                                                                    <b-form-group>
                                                                        <b-form-row v-for="(doc, indexDoc) in item.documenti" :key="indexDoc" class="mb-3 pt-3 border-bottom">
                                                                            <b-col cols="6">
                                                                                <b-form-group label="Tipo documento">
                                                                                    <b-form-input type="text" v-model="doc.nome"></b-form-input>
                                                                                </b-form-group>
                                                                            </b-col>
                                                                            <b-col>
                                                                                <b-form-group v-if="auth.isUserEnable('egnyte')" label="Documento">
                                                                                    <b-row>
                                                                                        <b-col sm="4" v-if="doc.path">
                                                                                            <b-button size="sm" variant="primary" @click="downloadDoc(doc.id)">Visualizza file <i class="fa fa-search" aria-hidden="true"></i></b-button>
                                                                                        </b-col>
                                                                                        <b-col sm="8">
                                                                                            <b-form-group>
                                                                                                <b-form-file
                                                                                                v-model="doc.file"
                                                                                                :state="Boolean(doc.file)"
                                                                                                placeholder="Scegli un file da caricare..."
                                                                                                drop-placeholder="Cancella file..."
                                                                                                ></b-form-file>
                                                                                            </b-form-group>
                                                                                        </b-col>
                                                                                    </b-row>
                                                                                </b-form-group>
                                                                            </b-col>
                                                                            <b-col cols="12">
                                                                                <b-form-group class="text-left">
                                                                                    <b-button variant="danger" size="sm" @click="removeDocCronologiaMedica(item, doc)"><i class="fa fa-trash fa-fw"></i> Rimuovi Documento</b-button>
                                                                                </b-form-group>
                                                                            </b-col>
                                                                        </b-form-row>

                                                                        <b-button size="sm" variant="outline-primary" @click="addDocCronologiaMedica(item)"><i class="fa fa-plus fa-fw"></i> Aggiungi Documento</b-button>
                                                                    </b-form-group>

                                                                    <b-form-group class="text-right">
                                                                        <b-button variant="danger" size="sm" @click="removeCronologiaMedica(item)"><i class="fa fa-trash fa-fw"></i> Rimuovi {{ tipo.nome }}</b-button>
                                                                    </b-form-group>
                                                                </b-col>
                                                            </b-form-row>
                                                            <b-form-group class="text-right">
                                                                <b-button variant="outline-primary" size="sm" @click="selectCronologiaMedica(tipo.id)"><i class="fa fa-plus fa-fw"></i> Aggiungi {{ tipo.nome }}</b-button>
                                                            </b-form-group>
                                                        </b-collapse>
                                                    </b-form-group>
                                                </b-card>
                                                
                                                <b-row>
                                                    <b-col>
                                                        <b-form-select v-model="cronologia_medica_tipo_sel">
                                                            <option :value="0">-- Tipo cronologia medica --</option>
                                                            <option v-for="tipo in cronologia_medica_tipi" :value="tipo.id">{{ tipo.nome }}</option>
                                                        </b-form-select>
                                                    </b-col>
                                                    <b-col>
                                                        <b-button variant="outline-primary" @click="addCronologiaMedica"><i class="fa fa-plus fa-fw"></i> Aggiungi Cronologia Medica</b-button>
                                                    </b-col>
                                                </b-row>

                                            </div>
                                            <!-- Medical - Edit - Football fields END -->
                                        </div>

                                    </fieldset>

                                    <!-- Lingue -->
                                    <fieldset v-show="$route.query.tab == 'lingue'">

                                        <h5 class="card-subtitle mb-3 color-rosso">Lingue</h5>

                                        <!-- View -->
                                        <div v-show="!enableEdit">
                                            <table class="table table-sm table-giocatore mb-0 p-0">
                                                
                                                <!-- Lingue - View - Football fields -->
                                                <template v-if="auth.isUserEnable('rosa_tab_lingue')">
                                                    <tr v-for="lingua in currentPlayer.persona.lingue"> 
                                                        <td>
                                                            {{ lingua.paese ? lingua.paese.lingua : 'N/D' }}
                                                        </td> 
                                                        <td>
                                                            {{ getLinguaLevel(lingua.level) ? getLinguaLevel(lingua.level).label : 'N/D' }}
                                                            <span class="color-rosso ml-1">
                                                                <i :id="'level_' + lingua.id" class="fa fa-info-circle" aria-hidden="true" title="Info"></i>
                                                            </span>
                                                            <b-popover title="Info" :target="'level_' + lingua.id" triggers="hover" placement="top">
                                                                {{ getLinguaLevel(lingua.level) ? getLinguaLevel(lingua.level).description : 'N/D'}}
                                                            </b-popover>
                                                        </td>
                                                    </tr>
                                                </template>
                                                <!-- Lingue - View - Football fields END -->
                                            </table>
                                        </div>
                                        <!-- Edit -->
                                        <div v-show="enableEdit">
                                            
                                            <!-- Lingue - Edit - Football fields -->
                                            <b-form-group id="lingueGroup" v-show="auth.isUserEnable('rosa_tab_lingue_edit')">
                                                <b-form-row v-for="(item, index) in form.lingue" :key="index" class="mb-3 pt-3 border-bottom">
                                                    <b-col>
                                                        <b-form-group horizontal label="Lingua" :label-cols="3">
                                                            <multiselect v-model="item.paese" track-by="id" label="lingua" :options="lingue" :multiple="false" :searchable="true" placeholder="Seleziona lingua" :max="4">
                                                                <template slot="option" slot-scope="props">
                                                                    <flag :country="props.option.sigla" :size="24"></flag><span class="ml-2">{{ props.option.lingua }}</span>
                                                                </template>
                                                            </multiselect>
                                                        </b-form-group>
                                                        <b-form-group id="levelGroup" horizontal label="Livello" :label-cols="3">
                                                            <b-form-select v-model="item.level" :options="lingua_levels" value-field="value" text-field="label">
                                                                <template #first>
                                                                    <b-form-select-option :value="null" disabled>-- Seleziona un livello --</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                            <small>{{ getDescriptionLinguaLevel(item.level) }}</small>
                                                        </b-form-group>
                                                        <b-form-group class="text-right">
                                                            <b-button variant="danger" size="sm" @click="removeLingua(item)"><i class="fa fa-trash fa-fw"></i> Rimuovi lingua</b-button>
                                                        </b-form-group>
                                                    </b-col>
                                                </b-form-row>
                                                <b-button variant="outline-primary" @click="addLingua"><i class="fa fa-plus fa-fw"></i> Aggiungi Lingua</b-button>
                                            </b-form-group>
                                            <!-- Lingue - Edit - Football fields END -->
                                        </div>
                                    </fieldset>
                                    <!-- Lingue END -->

                                    <!-- Edit buttons -->
                                    <div class="d-flex align-items-center mt-4" v-if="isFormEditable">

                                        <!-- Enable Edit Button -->
                                        <div v-if="!enableEdit">
                                            <b-button 
                                                variant="primary"                        
                                                @click="enableEdit=true"
                                            >
                                                <i class="fa fa-edit" aria-hidden="true"></i>
                                            </b-button>
                                        </div>
                                        <div v-else="enableEdit">
                                            <b-button 
                                                variant="secondary"
                                                @click="enableEdit=false"
                                            >
                                                <i class="fa fa-times" aria-hidden="true"></i>
                                            </b-button>
                                            <!-- Save button -->
                                            <b-button 
                                                type="submit"
                                                variant="primary"
                                                class="text-uppercase"
                                                :disabled="false"
                                            >
                                                <strong>Salva Informazioni</strong>
                                            </b-button>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </b-col>

                        <b-col cols="3" md="3" class="text-center" align-self="end" v-show="$route.query.tab == 'body-metrics'">
                            <div class="outsideWrapper">
                                <div class="insideWrapper">
                                    <div id="morf" style="margin-bottom: 10px;" class="coveredImage" v-if="form.info_fisica.baricentro && form.info_fisica.somatotipo && form.info_fisica.costituzione">
                                        <img :src="sagoma" :style="'height:' + altezza_sagoma + 'px!important;margin-top:' + (550 - altezza_sagoma) +'px'"/>
                                        <div id="h_body" :style="'margin-top:' + (550 - altezza_sagoma) + 'px'" v-if="form.info_fisica.altezza > 0">H: {{form.info_fisica.altezza}} cm</div>
                                        <div id="b_body" :style="'margin-top:' + (550 - altezza_sagoma) + 'px'">B: {{etichette.baricentro[form.info_fisica.baricentro]}}</div>
                                        <div id="s_body" :style="'margin-top:' + (550 - altezza_sagoma) + 'px'">S: {{etichette.somatotipo[form.info_fisica.somatotipo]}}</div>
                                        <div id="c_body" :style="'margin-top:' + (550 - altezza_sagoma) + 'px'">C: {{etichette.costituzione[form.info_fisica.costituzione]}}</div>
                                    </div>
                                    <canvas width="250px" height="550px" id="mycanvas" ref="mycanvas" class="coveringCanvas"></canvas>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-container>
            </div>
        </b-overlay>
            
        <b-modal id="searchPlayerModal" ref="searchPlayerModal" title="Ricerca giocatore" :size="view_search_persona ? 'lg' : 'md'">
            <b-row>
                <b-col v-if="view_search_persona" class="border-right pr-2">
                    <h5>AS Roma System</h5>
                    <div v-if="is_search_persona"><p>Attendere... <img src="/assets/varie/loading.gif" style="width: 100px;" /></p></div>
                    <p v-if="!is_search_persona && !persone.length">Nessun giocatore trovato su AS Roma System!</p>
                    <div v-if="!is_search_persona && persone.length">
                        <p>Trovati {{persone.length}} giocatori!, su AS Roma System</p>
                        <hr/>
                        <div style="height: 400px; overflow-y: scroll; overflow-x: hidden;">
                            <div v-for="(persona, index) in persone" :key="index" class="row mb-2" style="border-bottom: 1px solid #E5E5E5; cursor: pointer;" v-on:click="selezionaPersona(persona.id)">
                                <div class="col-md-3 my-1 mr-3" style="text-align: center;">
                                    <img :src="persona.avatar_url" height="100"/>
                                </div>
                                <div class="col-md-6 my-1" style="font-size:0.85rem">
                                    <h6 style="display: inline;">{{ persona.cognome }} {{ persona.nome }}</h6><br />
                                    <span class="text-muted" v-if="persona.nascita_paese"><flag v-if="persona.nascita_paese.sigla" :country="persona.nascita_paese.sigla" :size="16"></flag> {{ persona.nascita_paese.paese }} </span><br />
                                    <span class="text-muted">{{ persona.nascita_data | formatDate }}</span><br />
                                    <span class="text-muted">{{ persona.last_carriera_sportiva && persona.last_carriera_sportiva.ruoli && persona.last_carriera_sportiva.ruoli.length > 0 ? persona.last_carriera_sportiva.ruoli[0].nome : '' }}</span><br />
                                    <span class="text-muted" v-if="persona.last_carriera_sportiva && persona.last_carriera_sportiva.squadra"><flag v-if="persona.last_carriera_sportiva.squadra.paese.sigla" :country="persona.last_carriera_sportiva.squadra.paese.sigla" :size="16"></flag> {{ persona.last_carriera_sportiva.squadra.officialName }} {{persona.last_carriera_sportiva.stagione ? ' - ' + persona.last_carriera_sportiva.stagione.stagione : ''}}</span><br />     
                                </div>
                                <div class="col-md-2 my-1 align-self-center" style="text-align: center;">
                                    <img v-if="persona.last_carriera_sportiva && persona.last_carriera_sportiva.squadra" :src="persona.last_carriera_sportiva.squadra.logo_url" width="70" />
                                </div>
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col class="pl-2">
                    <h5>Wyscout</h5>
                    <div v-if="is_search"><p>Attendere... <img src="/assets/varie/loading.gif" style="width: 100px;" /></p></div>
                    <p v-if="!is_search && !players.length">Nessun giocatore trovato su Wyscout!</p>
                    <div v-if="!is_search && players.length">
                        <p>Trovati {{players.length}} giocatori!, su Wyscout</p>
                        <hr/>
                        <div style="height: 400px; overflow-y: scroll; overflow-x: hidden;">
                            <div v-for="(player, index) in players" :key="index" class="row mb-2" style="border-bottom: 1px solid #E5E5E5; cursor: pointer;" v-on:click="selezionaGiocatore(player)">
                                <div class="col-md-3 my-1 mr-3" style="text-align: center;">
                                    <img v-if="player.imageDataURL" :src="player.imageDataURL"  height="100" />
                                </div>
                                <div class="col-md-6 my-1" style="font-size:0.85rem">
                                    <h6 style="display: inline;">{{ player.lastName }} {{ player.firstName }}</h6><br />
                                    <span class="text-muted" v-if="player.birthArea"><flag v-if="player.birthArea.alpha2code" :country="player.birthArea.alpha2code" :size="16"></flag> {{ player.birthArea.name }} </span><br />
                                    <span class="text-muted">{{ player.birthDate | formatDate }}</span><br />
                                    <span class="text-muted">{{ player.role.name }}</span><br />
                                    <span class="text-muted" v-if="player.currentTeam"><flag v-if="player.currentTeam.area.alpha2code" :country="player.currentTeam.area.alpha2code" :size="16"></flag> {{ player.currentTeam.officialName }}</span><br />     
                                </div>
                                <div class="col-md-2 my-1 align-self-center" style="text-align: center;">
                                    <img v-if="player.currentTeam" :src="player.currentTeam.imageDataURL" width="70" />
                                </div>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-modal>	
            
        <b-modal id="searchTeamModal" ref="searchTeamModal" title="Ricerca squadra" ok-only ok-title="Chiudi">
            <div v-if="show_aree">
                <span>Seleziona un'area: </span>
                <div style="height: 250px; overflow-y: scroll; overflow-x: hidden;">
                    <div v-if="aree.length == 0"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
                    <b-row>
                        <b-col cols="3" v-for="area in aree" v-bind:key="area.id" style="padding: 0.2rem">
                            <b-card class="small text-center" style="cursor: pointer;height:100%" @click="selezionaArea(area.sigla3)">
                                <flag :country="area.sigla" :size="24"></flag><br/>
                                {{area.paese}}
                            </b-card>
                        </b-col>
                    </b-row>
                </div>
            </div>
            <div v-if="show_competizioni">
                <div v-if="is_search"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
                <div v-if="!is_search && competizioni.length == 0"><h5>Nessuna competizione trovata!</h5></div>
                <div v-if="competizioni.length > 0">
                    <span>Seleziona una competizione: </span>
                    <div style="height: 250px; overflow-y: scroll; overflow-x: hidden;">
                        <b-row>
                            <b-col cols="4" v-for="(comp, index) in competizioni" v-bind:key="index" style="padding: 0.2rem">
                                <b-card class="small text-center" style="cursor: pointer;height:100%" @click="selezionaCompetizione(comp)">
                                    <strong>{{comp.name}}</strong><br/>
                                    <span class="font-size-xs color-rosso">{{$i18n.t(comp.format)}}</span><br/>
                                </b-card>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </div>
            <div v-if="show_squadre">
                <div v-if="is_search"><h4>Attendere... <img src="/assets/varie/loading.gif" style="width: 150px;" /></h4></div>
                <div v-if="!is_search && squadre.length == 0"><h5>Nessuna squadra trovata!</h5></div>
                <div v-if="squadre.length > 0">
                    <span>Seleziona una squadra: </span>
                    <div style="height: 250px; overflow-y: scroll; overflow-x: hidden;">
                        <b-row>
                            <b-col cols="4" v-for="team in squadre" v-bind:key="team.id" style="padding: 0.2rem">
                                <b-card class="small text-center py-1" style="cursor: pointer;height:100%;" @click="selezionaSquadra(team)">
                                    <img :src="team.imageDataURL" width="50" /><br/>
                                    <strong>{{team.officialName}}</strong><br/>
                                </b-card>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </div>
        </b-modal>
        
        <b-modal id="waitingModal" ref="waitingModal" ok-only>
            <div align="center">
                <h4>Attendere salvataggio dei dati...</h4>
                <img src="/assets/varie/loading.gif" style="width: 150px;" />
            </div>
        </b-modal>

        <b-modal id="checkPersonaModal" ref="checkPersonaModal" title="Attenzione!" ok-only ok-title="Chiudi">
            <b-row>
                <b-col class="border-right pr-2" v-if="$route.params.persona_id || persona_id">
                    <p>Sono già presenti su AS Roma System i seguenti individui con questo Wyscout ID ({{ form.wyscout.chiave }}):</p>
                    <hr/>
                    <div style="height: 250px; overflow-y: scroll; overflow-x: hidden;">
                        <div v-for="(persona, index) in persone" :key="index" class="row mb-2" style="border-bottom: 1px solid #E5E5E5; cursor: pointer;">
                            <div class="col-md-3 my-1 mr-3" style="text-align: center;">
                                <img :src="persona.avatar_url" height="100"/>
                            </div>
                            <div class="col-md-6 my-1" style="font-size:0.85rem">
                                <h6 style="display: inline;">{{ persona.cognome }} {{ persona.nome }}</h6><br />
                                <span class="text-muted" v-if="persona.nascita_paese"><flag v-if="persona.nascita_paese.sigla" :country="persona.nascita_paese.sigla" :size="16"></flag> {{ persona.nascita_paese.paese }} </span><br />
                                <span class="text-muted">{{ persona.nascita_data | formatDate }}</span><br />
                                <span class="text-muted">{{ persona.last_carriera_sportiva && persona.last_carriera_sportiva.ruoli && persona.last_carriera_sportiva.ruoli.length > 0 ? persona.last_carriera_sportiva.ruoli[0].nome : '' }}</span><br />
                                <span class="text-muted" v-if="persona.last_carriera_sportiva && persona.last_carriera_sportiva.squadra"><flag v-if="persona.last_carriera_sportiva.squadra.paese.sigla" :country="persona.last_carriera_sportiva.squadra.paese.sigla" :size="16"></flag> {{ persona.last_carriera_sportiva.squadra.officialName }}</span><br />     
                            </div>
                            <div class="col-md-2 my-1 align-self-center" style="text-align: center;">
                                <img v-if="persona.last_carriera_sportiva && persona.last_carriera_sportiva.squadra" :src="persona.last_carriera_sportiva.squadra.logo_url" width="70" />
                            </div>
                        </div>
                    </div>
                    <div>Premi su <b-button variant="link" class="py-0" @click.prevent="forceSubmit"><strong>SALVA</strong></b-button> per forzare comunque l'aggiornamento del Wyscout ID sull'anagrafica del giocatore</div>
                </b-col>
                <b-col class="border-right pr-2" v-else>
                    <p>Sono già presenti su AS Roma System i seguenti individui corrispondenti ai campi inseriti, seleziona un'anagrafica tra quelle disponibili se ritieni corrisponda al giocatore che vuoi inserire</p>
                    <hr/>
                    <div style="height: 250px; overflow-y: scroll; overflow-x: hidden;">
                        <div v-for="(persona, index) in persone" :key="index" class="row mb-2 row_persona" style="border-bottom: 1px solid #E5E5E5; cursor: pointer;" v-on:click="selezionaPersona(persona.id)">
                            <div class="col-md-3 my-1 mr-3" style="text-align: center;">
                                <img :src="persona.avatar_url" height="100"/>
                            </div>
                            <div class="col-md-6 my-1" style="font-size:0.85rem">
                                <h6 style="display: inline;">{{ persona.cognome }} {{ persona.nome }}</h6><br />
                                <span class="text-muted" v-if="persona.nascita_paese"><flag v-if="persona.nascita_paese.sigla" :country="persona.nascita_paese.sigla" :size="16"></flag> {{ persona.nascita_paese.paese }} </span><br />
                                <span class="text-muted">{{ persona.nascita_data | formatDate }}</span><br />
                                <span class="text-muted">{{ persona.last_carriera_sportiva && persona.last_carriera_sportiva.ruoli && persona.last_carriera_sportiva.ruoli.length > 0 ? persona.last_carriera_sportiva.ruoli[0].nome : '' }}</span><br />
                                <span class="text-muted" v-if="persona.last_carriera_sportiva && persona.last_carriera_sportiva.squadra"><flag v-if="persona.last_carriera_sportiva.squadra.paese.sigla" :country="persona.last_carriera_sportiva.squadra.paese.sigla" :size="16"></flag> {{ persona.last_carriera_sportiva.squadra.officialName }}</span><br />     
                            </div>
                            <div class="col-md-2 my-1 align-self-center" style="text-align: center;">
                                <img v-if="persona.last_carriera_sportiva && persona.last_carriera_sportiva.squadra" :src="persona.last_carriera_sportiva.squadra.logo_url" width="70" />
                            </div>
                        </div>
                        <div class="row mb-2 row_persona" :style="'border-bottom: 1px solid #E5E5E5; cursor: pointer;background-color:' + (show_force_submit ? '#ffff00' : '')" v-on:click="show_force_submit = true">
                            <div class="col-md-3 my-1 mr-3" style="text-align: center;">
                            </div>
                            <div class="col-md-6 my-1 py-4" style="font-size:0.85rem">
                                <h6 style="display: inline;">Nessuna corrispodenza trovata.<br />Crea nuova anagrafica.</h6>
                            </div>
                            <div class="col-md-2 my-1 align-self-center" style="text-align: center;">
                            </div>
                        </div>
                    </div>
                    <b-button size="sm" v-if="show_force_submit" @click.prevent="forceSubmit"><strong>SALVA</strong></b-button>
                    <!--p>Come vuoi procedere?</p>
                    <p>- Seleziona un'anagrafica tra quelle sopra disponibili se ritieni corrisponda al giocatore che vuoi inserire</p>
                    <p><strong>oppure</strong></p>
                    <div>- Premi su <b-button variant="link" class="py-0" @click.prevent="forceSubmit"><strong>SALVA</strong></b-button> per forzare l'inserimento di una nuova anagrafica</div-->
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
        
<script>
        
import moment from 'moment';
import axios from 'axios';
var Auth = require('../../../../auth.js');
import * as types from '../../../../store/types';
import { mapState } from 'vuex';

export default {
    data: function(){
        return {
            currentPlayer: this.player,
            enableEdit: false,
            anamnesi_medica_count: 1,
			anamnesi_medica_selected: null,
            loading: 0,
            tabIndex: 0,
            image_player: '',
            image_board: '',
            file_avatar_agente: '',
            file_avatar_agente2: '',
            file_avatar: '',
            file_board_avatar: '',
            image_agente: '',
            image_agente2: '',
            is_search: false,
            is_search_persona: false,
            view_search_persona: false,
            players:[],
            persone: [],
            persona_id: null,
            show_force_submit: false,
            form: {			
                isFromScout: true,
                nome: null,
                cognome: null,
                nascita_data: null,
                nascita_paese_id: 0,
                info_extra: {
                    id: 0,
                    nascita_comune: null,
                    nascita_provincia: null,
                    matricola_figc: null,
                    info: null,
                    spoken_languages: null,            	
                },
                wyscout: {
                    id: 0,
                    chiave: null,
                },
                info_fisica: {
                    id: 0,
                    baricentro:	null,
                    somatotipo: null,
                    costituzione: null,
                    altezza: null,
                    peso: null,
                    num_piede: null,
                    max_heart_rate: null	         	
                },	
                giocatore: {
                    id: 0,
                    cresciuto_giovanili: false,
                    cresciuto_vivaio_italiano: false,
                    frequenta_scuola_asroma: false,
                    piede: null,
                    sistema_gioco_id: null,
                    id_gps: null,
                },
                last_carriera_sportiva: {
                    id: 0,
                    maglia: null,
                    ruolo_ideale_id: null,
                    altri_ruoli_ids: [],
                    prova: 0,
                },
                player_access: {
                    id: 0,
                    personal_engagements: null,
                    restrictions: null,
                    ranking: null,
                },	
                agente: {
                    id: 0,
                    cognome: null,
                    nome: null,
                    id_paese: 0,
                    luogo_nascita: null,
                    data_nascita: null,
                    email: {
                        id: 0,
                        valore: null,
                    },
                    telefono1: {
                        id: 0,
                        valore: null,
                    },
                    telefono2:{
                        id: 0,
                        valore: null,
                    },
                    indirizzo: {
                        id: 0,
                        indirizzo: null,
                    }
                },
                agente2: {
                    id: 0,
                    cognome: null,
                    nome: null,
                    id_paese: 0,
                    luogo_nascita: null,
                    data_nascita: null,
                    email: {
                        id: 0,
                        valore: null,
                    },
                    telefono1: {
                        id: 0,
                        valore: null,
                    },
                    telefono2:{
                        id: 0,
                        valore: null,
                    },
                    indirizzo: {
                        id: 0,
                        indirizzo: null,
                    }
                },
                info_economica: {
                    id: 0,
                    valore: null,
                    has_contratto: 0,
                    rimborso_spese: 0,
                    intestatario_iban: null,
                    scuola_a_carico: 0,
                    iban: null,
                    in_convitto: false,
                },
                contratto: {
                    id: 0,
                    stipendio_lordo: null,
                },
                residenza: {
                    id: 0,
                    paese: 0,
                    citta: null,
                    cap: null,
                    provincia: null,
                    indirizzo: null,
                },
                domicilio: {
                    id: 0,
                    paese: 0,
                    citta: null,
                    cap: null,
                    provincia: null,
                    indirizzo: null,
                },
                email: {
                    id: 0,
                    valore: null,
                },
                pec: {
                    id: 0,
                    valore: null,
                },
                telefono: {
                    id: 0,
                    valore: null,
                },
                telefono2: {
                    id: 0,
                    valore: null,
                },
                squadra: null,
                parenti: [],
                documenti: {
                    passaporto1: {
                        id: 0,
                        nazione: 0,
                        numero: null,
                        scadenza: null,
                        path: null,
                        delete: false,
                    },
                    passaporto2: {
                        id: 0,
                        nazione: 0,
                        numero: null,
                        scadenza: null,
                        path: null,
                        delete: false,
                    },
                    cartaidentita: {
                        id: 0,
                        numero: null,
                        scadenza: null,
                        path: null,
                        delete: false,
                    },
                    codicefiscale: {
                        id: 0,
                        numero: null,
                        scadenza: null,
                        path: null,
                        delete: false,
                    },
                    patente1: {
                        id: 0,
                        nazione: 0,
                        numero: null,
                        scadenza: null,
                        path: null,
                        delete: false,
                    },
                    patente2: {
                        id: 0,
                        nazione: 0,
                        numero: null,
                        scadenza: null,
                        path: null,
                        delete: false,
                    },
                    circolazione: {
                        id: 0,
                        scadenza: null,
                        path: null,
                        delete: false,
                    },
                    soggiorno: {
                        id: 0,
                        scadenza: null,
                        path: null,
                        delete: false,
                    },
                    agente1: {
                        id: 0,
                        path: null,
                        delete: false,
                    },
                    agente2: {
                        id: 0,
                        path: null,
                        delete: false,
                    },
                    idoneita: {
                        id: 0,
                        emissione: null,
                        scadenza: null,
                        intermedia: false,
                        path: null,
                        delete: false,
                    },
                    tessera: {
                        id: 0,
                        numero: null,
                        path: null,
                        delete: false,
                    },
                    contratto: {
                        id: 0,
                        scadenza: null,
                        path: null,
                        delete: false,
                    },
                    greenpass: {
                        id: 0,
                        scadenza: null,
                        path: null,
                        delete: false,
                    },
                },
                prova_periodi: [],
                prova_carriera: [],
                prova_documenti: [],
                cronologia_medica: [],
                lingue: [],
            },
            
            documenti_files: {
                passaporto1: null,
                passaporto2: null,
                cartaidentita: null,
                codicefiscale: null,
                patente1: null,
                patente2: null,
                circolazione: null,
                soggiorno: null,
                agente1: null,
                agente2: null,
                idoneita: null,
                tessera: null,
                contratto: null,
                greenpass: null,
            },
            relazioni: [ {id: 1, nome: "Padre"}, {id: 2, nome: "Madre"}, {id: 3, nome: "Tutor"}],
            paesi: [],
            comuni: [],
            agenti: [],
            ruoli: [],
            sistemi: [],
            stagioni: [],
            aree: [],
            competizioni: [],
            squadre: [],
            show_aree: false,
            show_competizioni: false,
            show_squadre: false,
            etichette: {
                baricentro: ['Basso', 'Medio', 'Alto'],
                somatotipo: ['Normolineo', 'Brevilineo', 'Longilineo'],
                costituzione: ['Esile', 'Media', 'Robusta'],
            },
            customStrings: {
                drag: this.$i18n.t('drag'),
                fileSize: this.$i18n.t('fileSize'),
                fileType: this.$i18n.t('fileType'),
            },
            cronologia_medica_tipi: [],
            cronologia_medica_tipo_sel: 0,
            random: Math.random(),
            lingua_levels: [],
        }
    },
            
    methods: {
        fetchDatas: function(){
            
            this.$http.get(this.$store.state.apiEndPoint + '/paesi').then((response) => {
                    if (response.ok) {
                        this.paesi = response.data;
                    }
                }
            );
            
            this.$http.get(this.$store.state.apiEndPoint + '/agenti').then((response) => {
                    if (response.ok){
                        this.agenti = response.data;
                    }
                }
            );
            
            this.$http.get(this.$store.state.apiEndPoint + '/ruoli').then((response) => {
                if (response.ok)
                {
                    this.ruoli = response.data;
                }
            }, (response) => {
            });
            
            this.$http.get(this.$store.state.apiEndPoint + '/sistemi').then((response) => {
                if (response.ok)
                {
                    this.sistemi = response.data;
                    this.sistemi.unshift({id: null, sistema_gioco: ''});
                }
            }, (response) => {
            });
            
            this.$http.get(this.$store.state.apiEndPoint + '/stagioni').then((response) => {
                if (response.ok)
                {
                    this.stagioni = response.data;
                }
            }, (response) => {
            });
            
            this.$http.get(this.$store.state.apiEndPoint + '/cronologia/medica/tipi').then((response) => {
                if (response.ok)
                {
                    this.cronologia_medica_tipi = response.data;
                }
            }, (response) => {
            });
            
            this.$http.get(this.$store.state.apiEndPoint + '/lingua/levels').then((response) => {
                if (response.ok)
                {
                    this.lingua_levels = response.data;
                }
            }, (response) => {
            });
            
            if(this.$route.params.persona_id) {
                this.getGiocatore(this.$route.params.persona_id);
            }
        },
        selectAnamnesiMedica(page) {
			this.anamnesi_medica_selected = this.currentPlayer.persona.cronologia_medica.filter((obj) => obj.cronologia_medica_tipo.id == 1)[page - 1];   
		},
        getLinguaLevel(level) {
			var level = this.lingua_levels.find((item) => item.value == level);
			if(level) {
				return level;
			}
			return null;
		},
        getVisitaMedicaIntermedia: function (date) {
			var m = moment(date);
			if(m.isValid()) {
				return m.subtract(6, 'M').format('DD/MM/YYYY'); 
			}
			return "N/D";
		},
        checkBeforeSubmit() {
            this.loading = true;
            this.show_force_submit = false;
            this.persone = [];
            var checkRequest = false;
            var formData = new FormData();
            if(this.$route.params.persona_id) {
                formData.set('id', this.$route.params.persona_id);  
                if(this.form.wyscout.chiave && this.form.wyscout.chiave != '') {
                    formData.set('wyscout_id', this.form.wyscout.chiave); 
                    checkRequest = true;                      
                } 
            } else if(this.persona_id) {
                formData.set('id', this.persona_id); 
                if(this.form.wyscout.chiave && this.form.wyscout.chiave != '') {
                    formData.set('wyscout_id', this.form.wyscout.chiave); 
                    checkRequest = true;                      
                }  
            } else {
                if(this.form.cognome && this.form.cognome.trim() != "") {
                    formData.set('cognome', this.form.cognome.trim()); 
                    checkRequest = true;                      
                }
                if(this.form.nome && this.form.nome.trim() != "") {
                    formData.set('nome', this.form.nome.trim()); 
                    checkRequest = true;                      
                }
                if(this.form.nascita_data && this.form.nascita_data != '0000-00-00') {
                    formData.set('nascita_data', this.form.nascita_data);
                    checkRequest = true;                       
                }
                if(this.form.wyscout.chiave && this.form.wyscout.chiave != '') {
                    formData.set('wyscout_id', this.form.wyscout.chiave); 
                    checkRequest = true;                      
                }
            }
            if(checkRequest) {
                this.$http.post(this.$store.state.apiEndPoint + '/persona/search', formData)
                    .then((response) => {
                        if (response.ok) {
                            this.persone = response.data;
                            if(this.persone.length > 0) {
                                this.$refs.checkPersonaModal.show();
                                this.loading = false
                            } else {
                                this.onSubmit();
                            }
                        }
                    })
            } else {
                this.onSubmit();
                this.loading = false;
            }
        },
        forceSubmit() {
            if(confirm('Sei sicuro di voler creare una nuova anagrafica ?')) {
                this.$refs.checkPersonaModal.hide();
                this.onSubmit();
            }
        },
        onSubmit: function() {
            this.loading = true;
            var formData = new FormData();
            var path = '/rosa/nuovo';
            if(this.$route.params.persona_id) {
                formData.set('id', this.$route.params.persona_id);
                path  = '/rosa/update';
            }
            if(this.persona_id) {
                formData.set('id', this.persona_id);
                path  = '/rosa/update';
            }
            
            const currentTeamId = ( this.currentPlayer.persona && this.currentPlayer.persona.last_carriera_sportiva ) ? this.currentPlayer.persona.last_carriera_sportiva.squadra_id : this.rosaTeamId
            
            formData.set('team_id', currentTeamId );
            formData.append("form", JSON.stringify(this.form));
            formData.append("file_avatar", this.file_avatar);
            formData.append("file_board_avatar", this.file_board_avatar);
            formData.append("file_avatar_agente", this.file_avatar_agente);
            formData.append("file_avatar_agente2", this.file_avatar_agente2);
            formData.append("file_passaporto1", this.documenti_files.passaporto1);
            formData.append("file_passaporto2", this.documenti_files.passaporto2);
            formData.append("file_cartaidentita", this.documenti_files.cartaidentita);
            formData.append("file_codicefiscale", this.documenti_files.codicefiscale);
            formData.append("file_patente1", this.documenti_files.patente1);
            formData.append("file_patente2", this.documenti_files.patente2);
            formData.append("file_circolazione", this.documenti_files.circolazione);
            formData.append("file_soggiorno", this.documenti_files.soggiorno);
            formData.append("file_agente1", this.documenti_files.agente1);
            formData.append("file_agente2", this.documenti_files.agente2);
            formData.append("file_idoneita", this.documenti_files.idoneita);
            formData.append("file_tessera", this.documenti_files.tessera);
            formData.append("file_contratto", this.documenti_files.contratto);
            formData.append("file_greenpass", this.documenti_files.greenpass);
            
            for(var i in this.form.prova_periodi) {
                if(this.form.prova_periodi[i].inizio && this.form.prova_periodi[i].fine && this.form.prova_periodi[i].fine < this.form.prova_periodi[i].inizio) {
                    alert("ERRORE: la data di fine di un periodo di prova deve essere successiva alla data di inizio!");
                    return;
                }
                formData.append("file_prova_periodi_" + i, this.form.prova_periodi[i].file);
            }
            for(var i in this.form.prova_documenti) {
                formData.append("file_prova_documenti_" + i, this.form.prova_documenti[i].file);
            }
            
            for(var i in this.form.cronologia_medica) {
                var item = this.form.cronologia_medica[i];
                for(var j in item.documenti) {
                    formData.append("file_medical_" + i + '_' + j, item.documenti[j].file);
                }
            }
            
            this.$http.post(this.$store.state.apiEndPoint + path, formData)
                .then((response) => {
                    // success callback
                    if (response.ok)
                    {
                        this.$store.commit(types.ALERTS_PUSH, { msg: response.data.message, status: 1 });                        
                        /* Refetch datas - Trigger this.player watcher for info refetch */
                        this.$emit('refresh');
                    }
                }, 
                (response) => {
                    if (response.status == 422) {
                        this.errors = response.data;
                        for (var index in this.errors) {
                            this.$store.commit(types.ALERTS_PUSH, { msg: this.errors[index], status: 0 });
                        }
                        this.loading = false
                    }
                })

        },		
        onPaeseNascitaSelect(item) {
            this.form.nascita_paese_id = item;
        },
        onPaeseNascitaChange() {
            this.form.nascita_paese_id = 0;
        },
        onPaeseResidenzaSelect(item) {
            this.form.residenza.paese = item;
        },
        onPaeseResidenzaChange() {
            this.form.residenza.paese = 0;
        },
        onPaeseDomicilioSelect(item) {
            this.form.domicilio.paese = item;
        },
        onPaeseDomicilioChange() {
            this.form.domicilio.paese = 0;
        },
        onComuneNascitaSelect(comune, provincia) {
            this.form.info_extra.nascita_comune = comune;
            this.form.info_extra.nascita_provincia = provincia;
        },
        onComuneNascitaChange() {
            this.form.info_extra.nascita_comune = "";
            this.form.info_extra.nascita_provincia = "";
        },
        onComuneResidenzaSelect(comune, provincia, cap) {
            this.form.residenza.citta = comune;
            this.form.residenza.provincia = provincia;
            this.form.residenza.cap = cap;
        },
        onComuneResidenzaChange() {
            this.form.residenza.citta = "";
            this.form.residenza.provincia = "";
            this.form.residenza.cap = "";
        },
        onCapResidenzaChange(value) {
            this.form.residenza.cap = value;
        },
        onComuneDomicilioSelect(comune, provincia, cap) {
            this.form.domicilio.citta = comune;
            this.form.domicilio.provincia = provincia;
            this.form.domicilio.cap = cap;
        },
        onComuneDomicilioChange() {
            this.form.domicilio.citta = "";
            this.form.domicilio.provincia = "";
            this.form.domicilio.cap = "";
        },
        onCapDomicilioChange(value) {
            this.form.domicilio.cap = value;
        },
        onAgenteSelect(id) {
            this.form.agente.id = id;
            for(var index in this.agenti) {
                var agente = this.agenti[index];
                if(agente.id == id) {
                    this.form.agente.cognome = agente.cognome;
                    this.form.agente.nome = agente.nome;
                    for(var i in agente.contatti) {
                        var contatto = agente.contatti[i];
                        if(contatto.contatto.tipo == 'email') {
                            this.form.agente.email.id = contatto.contatto.id;
                            this.form.agente.email.valore = contatto.contatto.valore;
                        }
                    }
                    var telefoni = [];
                    for(var i in agente.contatti) {
                        var contatto = agente.contatti[i];
                        if(contatto.contatto.tipo == 'telefono') {
                            telefoni.push(contatto.contatto);
                        }
                    }
                    if(telefoni.length > 0) {
                        this.form.agente.telefono1.id = telefoni[0].id;
                        this.form.agente.telefono1.valore = telefoni[0].valore;
                    }
                    if(telefoni.length > 1) {
                        this.form.agente.telefono2.id = telefoni[1].id;
                        this.form.agente.telefono2.valore = telefoni[1].valore;
                    }
                    this.form.agente.luogo_nascita = agente.nascita_comune;
                    this.form.agente.data_nascita = agente.nascita_data;
                    if(agente.indirizzi.length > 0 ) {
                        this.form.agente.indirizzo.id = agente.indirizzi[0].indirizzo.id;
                        this.form.agente.indirizzo.indirizzo = agente.indirizzi[0].indirizzo.indirizzo;
                    }
                    if(agente.avatar != null && agente.avatar != "") {
                        this.image_agente = agente.avatar_url;
                    }
                    if(agente.nascita_paese) {
                        this.$refs.paese_agente1.setPaese(agente.nascita_paese.id, agente.nascita_paese.paese);	
                    }
                    break;
                }
            }
        },
        onAgenteChange(value) {
            this.form.agente.id = 0;
            this.form.agente.cognome = value;
            this.form.agente.nome = '';
            this.form.agente.email.id = 0;
            this.form.agente.email.valore = null;
            this.form.agente.telefono1.id = 0;
            this.form.agente.telefono1.valore = null;
            this.form.agente.telefono2.id = 0;
            this.form.agente.telefono2.valore = null;
            this.image_agente = '';
            this.file_avatar_agente = '';
            this.form.agente.luogo_nascita = null;
            this.form.agente.data_nascita = null;
            this.form.agente.indirizzo.id = 0;
            this.form.agente.indirizzo.indirizzo = null;
            this.$refs.paese_agente1.setPaese(0, '');
        },
        searchPlayer: function() {
            this.view_search_persona = this.$route.params.persona_id ? false : true;
            var query = this.form.cognome ? this.form.cognome.trim().replace(/ /g, "_") : '';
            if(query != "") {
                this.players = [];
                this.is_search = true;
                this.$refs.searchPlayerModal.show();
                if(this.form.nome && this.form.nome.trim() != "") {
                    query = query + "_" + this.form.nome.trim().replace(/ /g, "_");                        
                }
                this.$http.get(this.$store.state.apiEndPoint + '/ws/search/player/' + query).then((response) => {
                    
                    if (response.ok)
                    {
                        this.players = response.data;
                        this.is_search = false;
                    }
                }, (response) => {
                    
                });
                if(this.view_search_persona) {
                    this.persone = [];
                    this.is_search_persona = true;
                    var formData = new FormData();
                    if(this.form.cognome && this.form.cognome.trim() != "") {
                        formData.set('cognome', this.form.cognome.trim());                       
                    }
                    if(this.form.nome && this.form.nome.trim() != "") {
                        formData.set('nome', this.form.nome.trim());                       
                    }
                    
                    this.$http.post(this.$store.state.apiEndPoint + '/persona/search', formData).then((response) => {
                        
                        if (response.ok)
                        {
                            this.persone = response.data;
                            this.is_search_persona = false;
                        }
                    }, (response) => {
                        
                    });
                }
            }
        },
        selezionaGiocatore: function (player){
            this.form.wyscout.chiave = player.wyId;
            this.form.cognome = player.lastName;
            this.form.nome = player.firstName;
            this.form.nascita_data = player.birthDate;
            this.form.info_fisica.altezza = player.height;
            this.form.info_fisica.peso = player.weight;
            this.form.giocatore.piede = this.piede(player.foot);
            
            this.image_player = player.imageDataURL;
            if(player.birthArea.alpha2code) {
                for (var index in this.paesi) {
                    var paese = this.paesi[index];
                    if (player.birthArea.alpha2code == paese.sigla) {
                        this.$refs.paese_nascita.setPaese(paese.id, paese.paese)
                        break;
                    }
                }
            }
            
            this.$refs.searchPlayerModal.hide();
        },
        selezionaPersona: function (persona_id) {
            this.persona_id = persona_id;
            this.getGiocatore(this.persona_id);
            this.$refs.searchPlayerModal.hide();
            this.$refs.checkPersonaModal.hide();
        },
        piede: function (foot) {
            if(foot == "right") {        
                return "0";
            }  else if(foot == "left") {
                return "1";
            }  else if(foot == "both") {
                return "2";
            } 
            return 0;          
        },
        updateImageAgente() {
            this.$refs.pictureAgente.onResize();
        },
        updateImageAgente2() {
            this.$refs.pictureAgente2.onResize();
        },
        onAvatarChange(e) {
            if (this.$refs.picturePlayer.file) {
                this.file_avatar = this.$refs.picturePlayer.file;
            } else {
                console.log('FileReader Player API not supported: use the <form> 2')
            }
        },
        onBoardAvatarChange(e) {
            if (this.$refs.pictureBoard.file) {
                this.file_board_avatar = this.$refs.pictureBoard.file;
            } else {
                console.log('FileReader Player API not supported: use the <form> 2')
            }
        },
        onAvatarAgenteChange(e) {
            if (this.$refs.pictureAgente.file) {
                this.file_avatar_agente = this.$refs.pictureAgente.file;
            } else {
                console.log('FileReader Player API not supported: use the <form> 3')
            }
        },
        onAvatarAgente2Change(e) {
            if (this.$refs.pictureAgente2.file) {
                this.file_avatar_agente2 = this.$refs.pictureAgente2.file;
            } else {
                console.log('FileReader Player API not supported: use the <form> 3')
            }
        },
        searchTeam: function() {
            this.show_aree = true;
            this.show_competizioni = false;
            this.show_squadre = false;
            if(this.aree.length == 0) {
                this.$http.get(this.$store.state.apiEndPoint + '/ws/aree').then((response) => {
                    if (response.ok)
                    {
                        this.aree = response.data;
                    }
                }, (response) => {
                    // error callback
                });
            }
            this.$refs.searchTeamModal.show();
        },
        selezionaArea: function(sigla) {
            this.show_aree = false;
            this.competizioni = [];
            this.is_search = true;
            this.show_competizioni = true;
            this.$http.get(this.$store.state.apiEndPoint + '/ws/get/competitions/' + sigla).then((response) => {
                
                if (response.ok)
                {
                    this.competizioni = response.data;
                    this.is_search = false;
                }
            }, (response) => {
                
            });
        },
        selezionaSquadra: function(squadra) {
            this.$refs.searchTeamModal.hide();
            this.players = [];
            this.is_search = true;
            this.view_search_persona = false;
            this.$refs.searchPlayerModal.show();
            this.$http.get(this.$store.state.apiEndPoint + '/ws/get/players/by/team/' + squadra.wyId).then((response) => {
                
                if (response.ok)
                {
                    this.players = response.data;
                    this.is_search = false;
                }
            }, (response) => {
                
            });	
        },
        selezionaCompetizione: function(competizione) {
            
            this.show_competizioni = false;
            this.squadre = [];
            this.is_search = true;
            this.show_squadre = true;
            this.$http.get(this.$store.state.apiEndPoint + '/ws/get/teams/by/competition/' + competizione.wyId).then((response) => {
                
                if (response.ok)
                {
                    this.squadre = response.data;
                    this.is_search = false;
                }
            }, (response) => {
                
            });
        },
        addPeriodoProva: function () {
            this.form.prova_periodi.push({ id: 0, inizio: null, fine: null, path: null, file: '', delete: false });
        },
        removePeriodoProva: function (item) {
            var index = this.form.prova_periodi.indexOf(item);
            this.form.prova_periodi.splice(index, 1);
        },
        addCarrieraProva: function () {
            this.form.prova_carriera.push({ id: 0, id_stagione: null, societa: null });
        },
        removeCarrieraProva: function (item) {
            var index = this.form.prova_carriera.indexOf(item);
            this.form.prova_carriera.splice(index, 1);
        },
        addDocumentoProva: function () {
            this.form.prova_documenti.push({ id: 0, nome: null, path: null, file: '', delete: false });
        },
        removeDocumentoProva: function (item) {
            var index = this.form.prova_documenti.indexOf(item);
            this.form.prova_documenti.splice(index, 1);
        },
        addParente: function () {
            this.form.parenti.push({ id: 0, nome: '', cognome: '', relazione: 0, relazione_id: 0, email: {id: 0, valore: null}, telefono: {id: 0, valore: null} });
        },
        removeParente: function (item) {
            var index = this.form.parenti.indexOf(item);
            this.form.parenti.splice(index, 1);
        },
        addCronologiaMedica: function () {
            if(this.cronologia_medica_tipo_sel == 0) {
                alert('Devi selezionare il tipo di cronologia medica da aggiungere!');
                return;
            }
            var cronologia_medica_tipo = this.cronologia_medica_tipi.find((item) => item.id == this.cronologia_medica_tipo_sel);
            if(this.cronologia_medica_tipo_sel == 1) {
                this.form.cronologia_medica.push({ id: 0, cronologia_medica_tipo: cronologia_medica_tipo, data: null, nome: null, anamnesi_medica: { id: 0, gruppo_sanguigno: null, visita_leva_abile: null, tipo_studio: null, altri_sport: null, appetito_normale: null, digestione_normale: null, alvo_normale: null, diuresi_normale: null, sonno_normale: null, fumo: null, alcol: null, caffe: null, nota_anamnesi_fisiologica: null, anamnesi_familiare: null, vaccinazione_antitetanica: null, vaccinazione_antiepatite_virale_b: null, altre_visite_idoneita_agonistica: null, dichiarato_idoneo_per_meno_di_12_mesi: null, nota_idoneo_per_meno_di_12_mesi: null, dichiarato_inidoneo: null, nota_inidoneo: null, provvedimenti_disciplinari_doping: null, nota_doping: null }, documenti: []});
            } else {
                this.form.cronologia_medica.push({ id: 0, cronologia_medica_tipo: cronologia_medica_tipo, data: null, nome: null, nota: null, data_scadenza: null, numero_lotto: null, data_rinnovo: null, documenti: [] });
            }
        },
        selectCronologiaMedica: function (tipo) {
            this.cronologia_medica_tipo_sel = tipo;
            this.addCronologiaMedica();
        },
        removeCronologiaMedica: function (item) {
            var index = this.form.cronologia_medica.indexOf(item);
            this.form.cronologia_medica.splice(index, 1);
        },
        addDocCronologiaMedica: function (item) {
            var index = this.form.cronologia_medica.indexOf(item);
            this.form.cronologia_medica[index].documenti.push({ id: 0, nome: null, path: null, file: '' });
        },
        removeDocCronologiaMedica: function (item, doc) {
            var index = this.form.cronologia_medica.indexOf(item);
            var indexDoc = this.form.cronologia_medica[index].documenti.indexOf(doc);
            this.form.cronologia_medica[index].documenti.splice(indexDoc, 1);
        },
        addLingua: function () {
            this.form.lingue.push({ id: 0, paese: null, level: null });
        },
        removeLingua: function (item) {
            var index = this.form.lingue.indexOf(item);
            this.form.lingue.splice(index, 1);
        },
        selezionaRuolo: function (ruolo) {
            if(ruolo != this.form.last_carriera_sportiva.ruolo_ideale_id) {        
                if(this.form.last_carriera_sportiva.altri_ruoli_ids.indexOf(ruolo) == -1) {
                    this.form.last_carriera_sportiva.altri_ruoli_ids.push(ruolo);
                } else {
                    this.form.last_carriera_sportiva.altri_ruoli_ids.splice(this.form.last_carriera_sportiva.altri_ruoli_ids.indexOf(ruolo), 1);
                }
            }               
        },
        onPassaporto1Select(item) {
            this.form.documenti.passaporto1.nazione = item;
        },
        onPassaporto1Change() {
            this.form.documenti.passaporto1.nazione = 0;
        },
        onPassaporto2Select(item) {
            this.form.documenti.passaporto2.nazione = item;
        },
        onPassaporto2Change() {
            this.form.documenti.passaporto2.nazione = 0;
        },
        onPatente1Select(item) {
            this.form.documenti.patente1.nazione = item;
        },
        onPatente1Change() {
            this.form.documenti.patente1.nazione = 0;
        },
        onPatente2Select(item) {
            this.form.documenti.patente2.nazione = item;
        },
        onPatente2Change() {
            this.form.documenti.patente2.nazione = 0;
        },
        downloadDoc(id) {
            window.open(this.$store.state.apiEndPoint + '/football/download/egnyte/doc/' + id + "/" + Auth.user.id + "/" + Auth.user.api_token, "_blank");
        },
        onPaeseAgente1Select(item) {
            this.form.agente.id_paese = item;
        },
        onPaeseAgente1Change() {
            this.form.agente.id_paese = 0;
        },
        onPaeseAgente2Select(item) {
            this.form.agente2.id_paese = item;
        },
        onPaeseAgente2Change() {
            this.form.agente2.id_paese = 0;
        },
        onAgente2Select(id) {
            this.form.agente2.id = id;
            for(var index in this.agenti) {
                var agente = this.agenti[index];
                if(agente.id == id) {
                    this.form.agente2.cognome = agente.cognome;
                    this.form.agente2.nome = agente.nome;
                    for(var i in agente.contatti) {
                        var contatto = agente.contatti[i];
                        if(contatto.contatto.tipo == 'email') {
                            this.form.agente2.email.id = contatto.contatto.id;
                            this.form.agente2.email.valore = contatto.contatto.valore;
                        }
                    }
                    var telefoni = [];
                    for(var i in agente.contatti) {
                        var contatto = agente.contatti[i];
                        if(contatto.contatto.tipo == 'telefono') {
                            telefoni.push(contatto.contatto);
                        }
                    }
                    if(telefoni.length > 0) {
                        this.form.agente2.telefono1.id = telefoni[0].id;
                        this.form.agente2.telefono1.valore = telefoni[0].valore;
                    }
                    if(telefoni.length > 1) {
                        this.form.agente2.telefono2.id = telefoni[1].id;
                        this.form.agente2.telefono2.valore = telefoni[1].valore;
                    }
                    this.form.agente2.luogo_nascita = agente.nascita_comune;
                    this.form.agente2.data_nascita = agente.nascita_data;
                    if(agente.indirizzi.length > 0 ) {
                        this.form.agente2.indirizzo.id = agente.indirizzi[0].indirizzo.id;
                        this.form.agente2.indirizzo.indirizzo = agente.indirizzi[0].indirizzo.indirizzo;
                    }
                    if(agente.avatar != null && agente.avatar != "") {
                        this.image_agente2 = agente.avatar_url;
                    }
                    if(agente.nascita_paese) {
                        this.$refs.paese_agente2.setPaese(agente.nascita_paese.id, agente.nascita_paese.paese);	
                    }
                    break;
                }
            }
        },
        onAgente2Change(value) {
            this.form.agente2.id = 0;
            this.form.agente2.cognome = value;
            this.form.agente2.nome = '';
            this.form.agente2.email.id = 0;
            this.form.agente2.email.valore = null;
            this.form.agente2.telefono1.id = 0;
            this.form.agente2.telefono1.valore = null;
            this.form.agente2.telefono2.id = 0;
            this.form.agente2.telefono2.valore = null;
            this.image_agente2 = '';
            this.file_avatar_agente2 = '';
            this.form.agente2.luogo_nascita = null;
            this.form.agente2.data_nascita = null;
            this.form.agente2.indirizzo.id = 0;
            this.form.agente2.indirizzo.indirizzo = null;
            this.$refs.paese_agente2.setPaese(0, '');
        },
        getGiocatore(id) {
            this.loading = true;

            axios.get(`/rosa/` + id, {params: { no_eval: true }})
            .then((res) => {
                if(res.data.wyscout) {
                    this.form.wyscout.id = res.data.wyscout.id;
                    this.form.wyscout.chiave = res.data.wyscout.chiave;
                }

                this.image_player = res.data.avatar ? res.data.avatar_url : null;
                this.image_board = res.data.info_extra && res.data.info_extra.board_avatar ? res.data.board_avatar_url : null;
                this.form.nome = res.data.nome;
                this.form.cognome = res.data.cognome;
                this.form.nascita_data = res.data.nascita_data;
                if(res.data.nascita_paese) {
                    this.$refs.paese_nascita.setPaese(res.data.nascita_paese.id, res.data.nascita_paese.paese);	
                }
                if(res.data.info_extra) {
                    this.form.info_extra.id = res.data.info_extra.id;
                    this.form.info_extra.nascita_comune = res.data.info_extra.nascita_comune;
                    this.form.info_extra.nascita_provincia = res.data.info_extra.nascita_provincia;
                    this.form.info_extra.matricola_figc = res.data.info_extra.matricola_figc;
                    this.form.info_extra.info = res.data.info_extra.info;
                    this.form.info_extra.spoken_languages = JSON.parse(res.data.info_extra.spoken_languages);
                }
                
                /* parenti */
                for(var i in res.data.relazioni) {
                    var item = res.data.relazioni[i];
                    if(item.persona_relazioni_glo_id <= 3) {
                        
                        var telefono = null;
                        var email = null;
                        
                        for(var j in item.relazionante.contatti) {
                            var contatto = item.relazionante.contatti[j];
                            if(contatto.contatto.tipo == 'telefono') {
                                telefono = contatto.contatto;
                            }
                        }
                        
                        for(var j in item.relazionante.contatti) {
                            var contatto = item.relazionante.contatti[j];
                            if(contatto.contatto.tipo == 'email') {
                                email = contatto.contatto;
                            }
                        }
                        
                        this.form.parenti.push({
                            id: item.relazionante.id,
                            nome: item.relazionante.nome,
                            cognome: item.relazionante.cognome,
                            relazione: item.persona_relazioni_glo_id,
                            relazione_id: item.id,
                            telefono: {
                                id: telefono ? telefono.id : 0,
                                valore: telefono ? telefono.valore : null,
                            },
                            email: {
                                id: email ? email.id : 0,
                                valore: email ? email.valore : null,
                            },
                        });
                    }
                }
                if(res.data.info_fisica) {
                    this.form.info_fisica.baricentro = res.data.info_fisica.baricentro;
                    this.form.info_fisica.somatotipo = res.data.info_fisica.somatotipo;
                    this.form.info_fisica.costituzione = res.data.info_fisica.costituzione;
                    this.form.info_fisica.altezza = res.data.info_fisica.altezza;
                    this.form.info_fisica.peso = res.data.info_fisica.peso;
                    this.form.info_fisica.num_piede = res.data.info_fisica.num_piede;
                    this.form.info_fisica.max_heart_rate = res.data.info_fisica.max_heart_rate;
                }
                if(res.data.giocatore) {
                    this.form.giocatore.id = res.data.giocatore.id;
                    this.form.giocatore.piede = res.data.giocatore.piede;
                    this.form.giocatore.cresciuto_giovanili = res.data.giocatore.cresciuto_giovanili ? true : false;
                    this.form.giocatore.cresciuto_vivaio_italiano = res.data.giocatore.cresciuto_vivaio_italiano ? true : false;
                    this.form.giocatore.frequenta_scuola_asroma = res.data.giocatore.frequenta_scuola_asroma;
                    this.form.giocatore.sistema_gioco_id = res.data.giocatore.sistema_gioco_id;
                    this.form.giocatore.id_gps = res.data.giocatore.id_gps;
                }
                  
                if(res.data.last_carriera_sportiva) {
                    this.form.last_carriera_sportiva.id = res.data.last_carriera_sportiva.id;
                    this.form.last_carriera_sportiva.maglia = res.data.last_carriera_sportiva.maglia;
                    if(res.data.last_carriera_sportiva.ruolo_ideale && res.data.last_carriera_sportiva.ruolo_ideale.length > 0) {
                        this.form.last_carriera_sportiva.ruolo_ideale_id = res.data.last_carriera_sportiva.ruolo_ideale[0].id;
                    }
                    if(res.data.last_carriera_sportiva.altri_ruoli) {
                        for (var index in res.data.last_carriera_sportiva.altri_ruoli) {
                            this.form.last_carriera_sportiva.altri_ruoli_ids.push(res.data.last_carriera_sportiva.altri_ruoli[index].id);
                        }
                    }
                }
                if(res.data.player_access) {
                    this.form.player_access.id = res.data.player_access.id;
                    this.form.player_access.personal_engagements = res.data.player_access.personal_engagements;
                    this.form.player_access.restrictions = res.data.player_access.restrictions;
                    this.form.player_access.ranking = res.data.player_access.ranking;
                }
                
                if(res.data.info_economica) {
                    this.form.info_economica.id = res.data.info_economica.id;
                    this.form.info_economica.valore = res.data.info_economica.valore;
                    this.form.info_economica.iban = res.data.info_economica.iban;
                    this.form.info_economica.intestatario_iban = res.data.info_economica.intestatario_iban;
                    this.form.info_economica.has_contratto = res.data.info_economica.has_contratto;
                    this.form.info_economica.rimborso_spese = res.data.info_economica.rimborso_spese;
                    this.form.info_economica.scuola_a_carico = res.data.info_economica.scuola_a_carico;
                    this.form.info_economica.in_convitto = res.data.info_economica.in_convitto ? true : false;
                }
                if(res.data.contratto) {
                    this.form.contratto.id = res.data.contratto.id;
                    this.form.contratto.stipendio_lordo = res.data.contratto.stipendio_lordo;
                }
                
                var agenti = [];
                for(var i in res.data.relazioni) {
                    var item = res.data.relazioni[i];
                    if(item.persona_relazioni_glo_id == 4) {
                        agenti.push(item.relazionante);
                    }
                }
                
                if(agenti.length > 0) {
                    this.$refs.agente.setNome(agenti[0].id, agenti[0].cognome);
                }
                if(agenti.length > 1) {
                    this.$refs.agente2.setNome(agenti[1].id, agenti[1].cognome);
                }
                
                for(var i in res.data.indirizzi) {
                    var item = res.data.indirizzi[i];
                    if(item.indirizzo.tipo == 'residenza') {
                        this.form.residenza.id = item.indirizzo.id;
                        this.form.residenza.citta = item.indirizzo.citta;
                        this.form.residenza.cap = item.indirizzo.cap;
                        this.form.residenza.provincia = item.indirizzo.provincia;
                        if(item.indirizzo.paese) {
                            this.$refs.paese_residenza.setPaese(item.indirizzo.paese.id, item.indirizzo.paese.paese);	
                        }
                        this.form.residenza.indirizzo = item.indirizzo.indirizzo;
                    } else if(item.indirizzo.tipo == 'domicilio') {
                        this.form.domicilio.id = item.indirizzo.id;
                        this.form.domicilio.citta = item.indirizzo.citta;
                        this.form.domicilio.cap = item.indirizzo.cap;
                        this.form.domicilio.provincia = item.indirizzo.provincia;
                        if(item.indirizzo.paese) {
                            this.$refs.paese_domicilio.setPaese(item.indirizzo.paese.id, item.indirizzo.paese.paese);	
                        }
                        this.form.domicilio.indirizzo = item.indirizzo.indirizzo;
                    }
                }
                
                var telefoni = [];
                for(var i in res.data.contatti) {
                    var item = res.data.contatti[i];
                    if(item.contatto.tipo == 'email') {
                        this.form.email.id = item.contatto.id;
                        this.form.email.valore = item.contatto.valore;
                    } else if(item.contatto.tipo == 'pec') {
                        this.form.pec.id = item.contatto.id;
                        this.form.pec.valore = item.contatto.valore;
                    } else if(item.contatto.tipo == 'telefono') {
                        telefoni.push(item.contatto);
                    }
                }
                
                if(telefoni.length > 0) {
                    this.form.telefono.id = telefoni[0].id;
                    this.form.telefono.valore = telefoni[0].valore;
                }
                if(telefoni.length > 1) {
                    this.form.telefono2.id = telefoni[1].id;
                    this.form.telefono2.valore = telefoni[1].valore;
                }
                
                /* passaporti */
                var docs = [];
                for(var i in res.data.documenti) {
                    var item = res.data.documenti[i];
                    if(item.documento.documento_tipo_glo_id == 2) {
                        if(docs.length == 0) {
                            docs.push(item.documento);
                        } else {
                            var found = false;
                            for(var j in docs) {
                                if(docs[j].numero == item.documento.numero) {
                                    found = true;
                                    if(item.documento.scadenza > docs[j].scadenza) {
                                        docs[j] = item.documento;
                                    }
                                    break;
                                }
                            }
                            if(!found) {
                                docs.push(item.documento);
                            }
                        }
                    }
                }
                
                if(docs.length > 0) {
                    this.form.documenti.passaporto1.id = docs[0].id;
                    this.form.documenti.passaporto1.numero = docs[0].numero;
                    this.form.documenti.passaporto1.scadenza = docs[0].scadenza;
                    this.form.documenti.passaporto1.path = docs[0].path;
                    if(docs[0].paese) {
                        this.$refs.passaporto1.setPaese(docs[0].paese.id, docs[0].paese.paese);	
                    }
                }
                if(docs.length > 1) {
                    this.form.documenti.passaporto2.id = docs[1].id;
                    this.form.documenti.passaporto2.numero = docs[1].numero;
                    this.form.documenti.passaporto2.scadenza = docs[1].scadenza;
                    this.form.documenti.passaporto2.path = docs[1].path;
                    if(docs[1].paese) {
                        this.$refs.passaporto2.setPaese(docs[1].paese.id, docs[1].paese.paese);	
                    }
                }
                
                /* carta identita */
                var doc = null;
                for(var i in res.data.documenti) {
                    var item = res.data.documenti[i];
                    if(item.documento.documento_tipo_glo_id == 3) {
                        if(!doc) {
                            doc = item.documento;
                        } else {
                            if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || !doc.scadenza || !item.documento.scadenza) {
                                doc = item.documento;
                            }
                        }
                    }
                }
                if(doc) {
                    this.form.documenti.cartaidentita.id = doc.id;
                    this.form.documenti.cartaidentita.numero = doc.numero;
                    this.form.documenti.cartaidentita.scadenza = doc.scadenza;
                    this.form.documenti.cartaidentita.path = doc.path;
                }
                
                /* codice fiscale */
                var doc = null;
                for(var i in res.data.documenti) {
                    var item = res.data.documenti[i];
                    if(item.documento.documento_tipo_glo_id == 4) {
                        if(!doc) {
                            doc = item.documento;
                        } else {
                            if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || !doc.scadenza || !item.documento.scadenza) {
                                doc = item.documento;
                            }
                        }
                    }
                }
                if(doc) {
                    this.form.documenti.codicefiscale.id = doc.id;
                    this.form.documenti.codicefiscale.numero = doc.numero;
                    this.form.documenti.codicefiscale.scadenza = doc.scadenza;
                    this.form.documenti.codicefiscale.path = doc.path;
                }
                
                /* patenti */
                var docs = [];
                for(var i in res.data.documenti) {
                    var item = res.data.documenti[i];
                    if(item.documento.documento_tipo_glo_id == 5) {
                        if(docs.length == 0) {
                            docs.push(item.documento);
                        } else {
                            var found = false;
                            for(var j in docs) {
                                if(docs[j].numero == item.documento.numero) {
                                    found = true;
                                    if(item.documento.scadenza > docs[j].scadenza) {
                                        docs[j] = item.documento;
                                    }
                                    break;
                                }
                            }
                            if(!found) {
                                docs.push(item.documento);
                            }
                        }
                    }
                }	
                if(docs.length > 0) {
                    this.form.documenti.patente1.id = docs[0].id;
                    this.form.documenti.patente1.numero = docs[0].numero;
                    this.form.documenti.patente1.scadenza = docs[0].scadenza;
                    this.form.documenti.patente1.path = docs[0].path;
                    if(docs[0].paese) {
                        this.$refs.patente1.setPaese(docs[0].paese.id, docs[0].paese.paese);	
                    }
                }
                if(docs.length > 1) {
                    this.form.documenti.patente2.id = docs[1].id;
                    this.form.documenti.patente2.numero = docs[1].numero;
                    this.form.documenti.patente2.scadenza = docs[1].scadenza;
                    this.form.documenti.patente2.path = docs[1].path;
                    if(docs[1].paese) {
                        this.$refs.patente2.setPaese(docs[1].paese.id, docs[1].paese.paese);	
                    }
                }
                
                /* circolazione */
                var doc = null;
                for(var i in res.data.documenti) {
                    var item = res.data.documenti[i];
                    if(item.documento.documento_tipo_glo_id == 6) {
                        if(!doc) {
                            doc = item.documento;
                        } else {
                            if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || !doc.scadenza || !item.documento.scadenza) {
                                doc = item.documento;
                            }
                        }
                    }
                }
                if(doc) {
                    this.form.documenti.circolazione.id = doc.id;
                    this.form.documenti.circolazione.scadenza = doc.scadenza;
                    this.form.documenti.circolazione.path = doc.path;
                }
                
                /* soggiorno */
                var doc = null;
                for(var i in res.data.documenti) {
                    var item = res.data.documenti[i];
                    if(item.documento.documento_tipo_glo_id == 7) {
                        if(!doc) {
                            doc = item.documento;
                        } else {
                            if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || !doc.scadenza || !item.documento.scadenza) {
                                doc = item.documento;
                            }
                        }
                    }
                }
                if(doc) {
                    this.form.documenti.soggiorno.id = doc.id;
                    this.form.documenti.soggiorno.scadenza = doc.scadenza;
                    this.form.documenti.soggiorno.path = doc.path;
                }
                
                /* greenpass */
                var doc = null;
                for(var i in res.data.documenti) {
                    var item = res.data.documenti[i];
                    if(item.documento.documento_tipo_glo_id == 15) {
                        if(!doc) {
                            doc = item.documento;
                        } else {
                            if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || !doc.scadenza || !item.documento.scadenza) {
                                doc = item.documento;
                            }
                        }
                    }
                }
                if(doc) {
                    this.form.documenti.greenpass.id = doc.id;
                    this.form.documenti.greenpass.scadenza = doc.scadenza;
                    this.form.documenti.greenpass.path = doc.path;
                }
                
                /* mandati agenti */
                var docs = [];
                for(var i in res.data.documenti) {
                    var item = res.data.documenti[i];
                    if(item.documento.documento_tipo_glo_id == 8) {
                        docs.push(item.documento);
                    }
                }
                if(docs.length > 0) {
                    this.form.documenti.agente1.id = docs[0].id;
                    this.form.documenti.agente1.path = docs[0].path;
                }
                if(docs.length > 1) {
                    this.form.documenti.agente2.id = docs[1].id;
                    this.form.documenti.agente2.path = docs[1].path;
                }
                
                /* idoneità */
                var doc = null;
                for(var i in res.data.documenti) {
                    var item = res.data.documenti[i];
                    if(item.documento.documento_tipo_glo_id == 9 || item.documento.documento_tipo_glo_id == 10) {
                        if(!doc) {
                            doc = item.documento;
                        } else {
                            if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || !doc.scadenza || !item.documento.scadenza) {
                                doc = item.documento;
                            }
                        }
                    }
                }
                if(doc) {
                    this.form.documenti.idoneita.id = doc.id;
                    this.form.documenti.idoneita.emissione = doc.emissione;
                    this.form.documenti.idoneita.scadenza = doc.scadenza;
                    this.form.documenti.idoneita.intermedia = doc.documento_tipo_glo_id == 10 ? true : false;
                    this.form.documenti.idoneita.path = doc.path;
                }
                
                /* tessera */
                var doc = null;
                for(var i in res.data.documenti) {
                    var item = res.data.documenti[i];
                    if(item.documento.documento_tipo_glo_id == 11) {
                        if(!doc) {
                            doc = item.documento;
                        } else {
                            if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || !doc.scadenza || !item.documento.scadenza) {
                                doc = item.documento;
                            }
                        }
                    }
                }
                if(doc) {
                    this.form.documenti.tessera.id = doc.id;
                    this.form.documenti.tessera.numero = doc.numero;
                    this.form.documenti.tessera.path = doc.path;
                }
                
                /* contratto */
                var doc = null;
                for(var i in res.data.documenti) {
                    var item = res.data.documenti[i];
                    if(item.documento.documento_tipo_glo_id == 12) {
                        if(!doc) {
                            doc = item.documento;
                        } else {
                            if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || !doc.scadenza || !item.documento.scadenza) {
                                doc = item.documento;
                            }
                        }
                    }
                }
                if(doc) {
                    this.form.documenti.contratto.id = doc.id;
                    this.form.documenti.contratto.scadenza = doc.scadenza;
                    this.form.documenti.contratto.path = doc.path;
                }

                if(res.data.last_carriera_sportiva.tipologia == 'prova' || res.data.last_carriera_sportiva.squadra.categoria.name == 'In prova') {
                    this.form.last_carriera_sportiva.prova = 1;
                }
                
                /* periodi prova */
                for(var i in res.data.documenti) {
                    var item = res.data.documenti[i];
                    if(item.documento.documento_tipo_glo_id == 13) {
                        
                        this.form.prova_periodi.push({
                            id: item.documento.id,
                            inizio: item.documento.emissione,
                            fine: item.documento.scadenza,
                            path: item.documento.path,
                            file: '',
                            delete: false,
                        });
                    }
                }

                /* carriera prova */
                for(var i in res.data.carriere_sportive) {
                    var item = res.data.carriere_sportive[i];
                    if(item.societa_id != 1) {
                        this.form.prova_carriera.push({
                            id: item.id,
                            id_stagione: item.stagione_id,
                            societa: item.societa ? item.societa.nome : '',
                        });
                    }
                }

                /* documenti prova */
                for(var i in res.data.documenti) {
                    var item = res.data.documenti[i];
                    if(item.documento.documento_tipo_glo_id == 14) {
                        this.form.prova_documenti.push({
                            id: item.documento.id,
                            nome: item.documento.nome,
                            path: item.documento.path,
                            file: '',
                            delete: false,
                        });
                    }
                } 

                /* cronologia medica */           
                for(var i in res.data.cronologia_medica) {
                    var item = res.data.cronologia_medica[i];
                    var documenti = [];
                    for(var j in item.documenti) {
                        var doc = item.documenti[j];
                        documenti.push({
                            id: doc.documento.id,
                            nome: doc.documento.nome,
                            path: doc.documento.path,
                            file: '',
                        });
                    }
                    item.documenti = documenti;
                    this.form.cronologia_medica.push(item);
                }
                
                /* lingue */
                for(var i in res.data.lingue) {
                    var item = res.data.lingue[i];
                    var findIndex = this.form.lingue.findIndex((el) => el.paese_id == item.paese_id);
                    if(findIndex > -1) {
                        var findItem = this.form.lingue[findIndex];
                        if(item.created_at > findItem.created_at) {
                            this.form.lingue[findIndex] = item;
                        }
                    } else {
                        this.form.lingue.push(item);
                    }
                }
            })
            .catch((err) => err)
            .finally(() => this.loading = false)
        },
        getDescriptionLinguaLevel(level) {
            var level = this.lingua_levels.find((item) => item.value == level);
            if(level) {
                return level.description;
            }
            return null;
        }
    },
                            
    props: ['player'],
    
    filters: {
        formatDate: function (date) {
            var m = moment(date);
            if(m.isValid()) {
                return m.format('DD/MM/YYYY'); 
            }
            return "";
        }
    },
    
    components: {
        pictureInput:      require('../../../layout/picture_input.vue').default,
        autocompletePaese: require('../../../layout/autocomplete_paese.vue').default,
        autocompleteNome:  require('../../../layout/autocomplete_nome.vue').default,
        autocompleteComune:  require('../../../layout/autocomplete_comune.vue').default,
        flag: 			   require('../../../layout/flag.vue').default,
        EntitaApiManager: 	require('@/components/layout/entita_api/manager.vue').default,
    },
    
    computed: {
        auth: function() {
            return Auth;
        },
        isFormEditable() {
            return this.auth.isUserEnable('scout_edit_player')
                || ( this.auth.isUserEnable('rosa_tab_generali_edit') && this.$route.query.tab == 'anagrafica-generale' )
                || ( this.auth.isUserEnable('rosa_tab_corporatura_edit') && this.$route.query.tab == 'body-metrics' )
                || ( this.auth.isUserEnable('rosa_tab_carriera_edit') && this.$route.query.tab == 'carriera' )
                || ( this.auth.isUserEnable('rosa_tab_dati_economici_edit') && this.$route.query.tab == 'dati-economici' )
                || ( this.auth.isUserEnable('rosa_tab_documenti_edit') && this.$route.query.tab == 'documenti' )
                || ( this.auth.isUserEnable('rosa_tab_parenti_edit') && this.$route.query.tab == 'providers' )
                || ( this.auth.isUserEnable('rosa_tab_player_access_edit') && this.$route.query.tab == 'parenti' )
                || ( this.auth.isUserEnable('rosa_tab_whiteboard_edit') && this.$route.query.tab == 'player-access' )
                || ( this.auth.isUserEnable('rosa_tab_medical_edit') && this.$route.query.tab == 'whiteboard' )
                || ( this.auth.isUserEnable('rosa_tab_providers_edit') && this.$route.query.tab == 'medical' )
                || ( this.auth.isUserEnable('rosa_tab_lingue_edit') && this.$route.query.tab == 'lingue' )
        },
        isSocietaAsRoma: function() {
            let isSocietaAsRomaCheck = false
            if( this.currentPlayer && this.currentPlayer.persona && this.currentPlayer.persona.last_carriera_sportiva ){
                isSocietaAsRomaCheck = this.currentPlayer.persona.last_carriera_sportiva.societa_id == 1
            }
            return isSocietaAsRomaCheck;
        },
        avatar: function() {
            if(this.image_player != "") {
                return this.image_player;
            } 
            return '';
        },
        board_avatar: function() {            
            if( this.image_board && this.enableEdit ) {
                return this.image_board;
            } 
            return '';
        },
        avatar_agente: function() {
            if(this.image_agente != "") {
                return this.image_agente;
            } 
            return '';
        },
        avatar_agente2: function() {
            if(this.image_agente2 != "") {
                return this.image_agente2;
            } 
            return '';
        },
        sagoma: function(){
            return "/assets/morfologia/s" + this.form.info_fisica.somatotipo + "b" + this.form.info_fisica.baricentro + "c" + this.form.info_fisica.costituzione + ".png";
        },
        altezza_sagoma: function() {
            var height = 550;
            if(this.form.info_fisica.baricentro > -1 && this.form.info_fisica.somatotipo > -1 && this.form.info_fisica.costituzione > -1) {
                if(!isNaN(this.form.info_fisica.altezza)) {
                    if(this.form.info_fisica.altezza < 190 && this.form.info_fisica.altezza >= 180) {
                        height = 530;
                    }else if(this.form.info_fisica.altezza < 180 && this.form.info_fisica.altezza >= 170) {
                        height = 510;
                    } else if(this.form.info_fisica.altezza < 170 && this.form.info_fisica.altezza >= 160) {
                        height = 490;
                    } else if(this.form.info_fisica.altezza < 160) {
                        height = 470;
                    }
                }
                return height;
            }
        },
        altri_ruoli_lbl: function() {
            var label = "";
            for (var index in this.form.last_carriera_sportiva.altri_ruoli_ids) {
                var id_ruolo = this.form.last_carriera_sportiva.altri_ruoli_ids[index];
                if(id_ruolo > 0 && this.ruoli.length > 0) {
                    label = label + this.ruoli.find(item => item.id == id_ruolo).nome + ", ";
                }
            }
            if(label.length > 0) {
                label = label.substr(0, label.length - 2); 
            }
            return label;
        },
        lingue: function() {
            /*	var lingue = [];
            for(var i in this.paesi) {
            var paese = this.paesi[i];
            var item = {
            id: paese.id,
            nome: paese.lingua ? paese.lingua : paese.paese,
            sigla: paese.sigla,
            }
            lingue.push(item);
            }
            return lingue; */
            return _.sortBy(_.filter(this.paesi, function(a) { return a.lingua; }), ['lingua'],['asc']);
        },
        ...mapState(['rosaTeamId']),
        email: function() {
			for(var i in this.currentPlayer.persona.contatti) {
				var item = this.currentPlayer.persona.contatti[i];
				if(item.contatto.tipo == 'email') {
					return item.contatto.valore;
				}
			}
			return null;
		},
		pec: function() {
			for(var i in this.currentPlayer.persona.contatti) {
				var item = this.currentPlayer.persona.contatti[i];
				if(item.contatto.tipo == 'pec') {
					return item.contatto.valore;
				}
			}
			return null;
		},
		telefoni: function() {
			var telefoni = [];
			for(var i in this.currentPlayer.persona.contatti) {
				var item = this.currentPlayer.persona.contatti[i];
				if(item.contatto.tipo == 'telefono') {
					telefoni.push(item.contatto.valore);
				}
			}
			return telefoni;
		},
		residenza: function() {
			for(var i in this.currentPlayer.persona.indirizzi) {
				var item = this.currentPlayer.persona.indirizzi[i];
				if(item.indirizzo.tipo == 'residenza') {
					return item.indirizzo;
				}
			}
			return null;
		},
		domicilio: function() {
			for(var i in this.currentPlayer.persona.indirizzi) {
				var item = this.currentPlayer.persona.indirizzi[i];
				if(item.indirizzo.tipo == 'domicilio') {
					return item.indirizzo;
				}
			}
			return null;
		},
		contratto: function() {
			var doc = null;
			for(var i in this.currentPlayer.persona.documenti) {
				var item = this.currentPlayer.persona.documenti[i];
				if(item.documento.documento_tipo_glo_id == 12) {
					if(!doc) {
						doc = item.documento;
					} else {
						if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || 
							!doc.scadenza || !item.documento.scadenza) {
							doc = item.documento; 
					    }
				    }
			    }
		    }
            return doc;
		},
        agentiComputed: function() {
            var persone = [];
            for(var i in this.currentPlayer.persona.relazioni) {
                var item = this.currentPlayer.persona.relazioni[i];
                if(item.persona_relazioni_glo_id == 4) {
                    persone.push(item.relazionante);
                }
            }
            return persone;
        },
        tessera: function() {
            var doc = null;
            for(var i in this.currentPlayer.persona.documenti) {
                var item = this.currentPlayer.persona.documenti[i];
                if(item.documento.documento_tipo_glo_id == 11) {
                    if(!doc) {
                        doc = item.documento;
                    } else {
                        if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || !doc.scadenza || !item.documento.scadenza) {
                            doc = item.documento;
                        }
                    }
                }
            }
            return doc;
        },
        idoneita: function() {
            var doc = null;
            for(var i in this.currentPlayer.persona.documenti) {
                var item = this.currentPlayer.persona.documenti[i];
                if(item.documento.documento_tipo_glo_id == 9 || item.documento.documento_tipo_glo_id == 10) {
                    if(!doc) {
                        doc = item.documento;
                    } else {
                        if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || !doc.scadenza || !item.documento.scadenza) {
                            doc = item.documento;
                        }
                    }
                }
            }
            return doc;
        },
        passaporti: function() {
            var docs = [];
            for(var i in this.currentPlayer.persona.documenti) {
                var item = this.currentPlayer.persona.documenti[i];
                if(item.documento.documento_tipo_glo_id == 2) {
                    if(docs.length == 0) {
                        docs.push(item.documento);
                    } else {
                        var found = false;
                        for(var j in docs) {
                            if(docs[j].numero == item.documento.numero) {
                                found = true;
                                if(item.documento.scadenza > docs[j].scadenza) {
                                    docs[j] = item.documento;
                                }
                                break;
                            }
                        }
                        if(!found) {
                            docs.push(item.documento);
                        }
                    }
                }
            }
            return docs;
        },
        cartaidentita: function() {
            var doc = null;
            for(var i in this.currentPlayer.persona.documenti) {
                var item = this.currentPlayer.persona.documenti[i];
                if(item.documento.documento_tipo_glo_id == 3) {
                    if(!doc) {
                        doc = item.documento;
                    } else {
                        if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || !doc.scadenza || !item.documento.scadenza) {
                            doc = item.documento;
                        }
                    }
                }
            }
            return doc;
        },
        codicefiscale: function() {
            var doc = null;
            for(var i in this.currentPlayer.persona.documenti) {
                var item = this.currentPlayer.persona.documenti[i];
                if(item.documento.documento_tipo_glo_id == 4) {
                    if(!doc) {
                        doc = item.documento;
                    } else {
                        if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || !doc.scadenza || !item.documento.scadenza) {
                            doc = item.documento;
                        }
                    }
                }
            }
            return doc;
        },
        patenti: function() {
            var docs = [];
            for(var i in this.currentPlayer.persona.documenti) {
                var item = this.currentPlayer.persona.documenti[i];
                if(item.documento.documento_tipo_glo_id == 5) {
                    if(docs.length == 0) {
                        docs.push(item.documento);
                    } else {
                        var found = false;
                        for(var j in docs) {
                            if(docs[j].numero == item.documento.numero) {
                                found = true;
                                if(item.documento.scadenza > docs[j].scadenza) {
                                    docs[j] = item.documento;
                                }
                                break;
                            }
                        }
                        if(!found) {
                            docs.push(item.documento);
                        }
                    }
                }
            }
            return docs;
        },
        circolazione: function() {
            var doc = null;
            for(var i in this.currentPlayer.persona.documenti) {
                var item = this.currentPlayer.persona.documenti[i];
                if(item.documento.documento_tipo_glo_id == 6) {
                    if(!doc) {
                        doc = item.documento;
                    } else {
                        if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || 
                            !doc.scadenza || !item.documento.scadenza) {
                            doc = item.documento;
                        }
                    }
                }
            }
            return doc;
        },
        soggiorno: function() {
            var doc = null;
            for(var i in this.currentPlayer.persona.documenti) {
                var item = this.currentPlayer.persona.documenti[i];
                if(item.documento.documento_tipo_glo_id == 7) {
                    if(!doc) {
                        doc = item.documento;
                    } else {
                        if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) || 
                            !doc.scadenza || !item.documento.scadenza) {
                            doc = item.documento;
                        }
                    }
                }
            }
            return doc;
        },
        mandati: function() {
            var docs = [];
            for(var i in this.currentPlayer.persona.documenti) {
                var item = this.currentPlayer.persona.documenti[i];
                if(item.documento.documento_tipo_glo_id == 8) {
                    docs.push(item.documento);
                }
            }
            return docs;
        },
        greenpass: function() {
            var doc = null;
            for(var i in this.currentPlayer.persona.documenti) {
                var item = this.currentPlayer.persona.documenti[i];
                if(item.documento.documento_tipo_glo_id == 15) {
                    if(!doc) {
                        doc = item.documento;
                    } else {
                        if((doc.scadenza && item.documento.scadenza && item.documento.scadenza > doc.scadenza) ||  !doc.scadenza || !item.documento.scadenza) {
                            doc = item.documento;
                        }
                    }
                }
            }
            return doc;
        },
        parenti: function() {
            var relazioni = [];
            for(var i in this.currentPlayer.persona.relazioni) {
                var item = this.currentPlayer.persona.relazioni[i];
                if(item.persona_relazioni_glo_id <= 3) {
                    relazioni.push(item);
                }
            }
            return relazioni;
        },
    },
    
    mounted: function() {        
        var context = this.$refs.mycanvas.getContext("2d");
        context.lineWidth = 1;
        context.strokeStyle = "#CCCCCC";
        context.lineCap = "square";
        context.moveTo(0, 0);
        context.lineTo(300, 0);
        context.moveTo(0, 80);
        context.lineTo(300, 80);
        context.moveTo(0, 280);
        context.lineTo(300, 280);
        context.moveTo(0, 550);
        context.lineTo(300, 550);
        context.moveTo(65, 0);
        context.lineTo(65, 550);
        context.moveTo(162, 0);
        context.lineTo(162, 550);
        context.stroke(); 
        
        this.autocomplete = new window.google.maps.places.Autocomplete(
        (this.$refs.autocomplete),
        {types: ['geocode']}
        );
        
        this.autocomplete.addListener('place_changed', () => {
            let place = this.autocomplete.getPlace();
            this.form.residenza.indirizzo = place.formatted_address;
        }); 
        
        this.autocomplete_dom = new window.google.maps.places.Autocomplete(
        (this.$refs.autocomplete_dom),
        {types: ['geocode']}
        );
        
        this.autocomplete_dom.addListener('place_changed', () => {
            let place = this.autocomplete_dom.getPlace();
            this.form.domicilio.indirizzo = place.formatted_address;
        }); 
    },
    
    created: function () {
        this.fetchDatas()
    },
                            
    watch: {
        'form.last_carriera_sportiva.ruolo_ideale_id': function (ruolo) {
            if(this.form.last_carriera_sportiva.altri_ruoli_ids.indexOf(ruolo) > -1) {
                this.form.last_carriera_sportiva.altri_ruoli_ids.splice(this.form.last_carriera_sportiva.altri_ruoli_ids.indexOf(ruolo), 1);
            }
        },
        'form.nascita_paese_id': function () {            
            if(this.$route.params.persona_id && this.form.nascita_paese_id == 118) {
                this.$refs.comune_nascita.setComune(this.form.info_extra.nascita_comune, this.form.info_extra.nascita_provincia);
            }
        },
        'form.residenza.paese': function () {
            if(this.$route.params.persona_id && this.form.residenza.paese == 118) {
                this.$refs.comune_residenza.setComune(this.form.residenza.citta, this.form.residenza.provincia, this.form.residenza.cap);
            }
        },
        'form.domicilio.paese': function () {
            if(this.$route.params.persona_id && this.form.domicilio.paese == 118) {
                this.$refs.comune_domicilio.setComune(this.form.domicilio.citta, this.form.domicilio.provincia, this.form.domicilio.cap);
            }
        },
        'player': function () {
            this.loading = true
            this.fetchDatas();
            setTimeout(() => {
                this.currentPlayer = this.player
                this.loading = false
            }, 2000);
        },
        form: {
            handler: function () {this.errors = []; },
            deep: true
        },
        '$route.query.tab': {
			immediate: true,
			deep: true,
			handler: function() {
                this.enableEdit = false
				if(this.$route.query.tab == 'whiteboard' && this.enableEdit ) {
                    this.random = Math.random();
                }
			}
		},
        enableEdit: {
			immediate: true,
			deep: true,
			handler: function() { 
				if( this.$route.query.tab == 'whiteboard' && this.enableEdit ) {
                    this.random = Math.random();
                }
			}
		},
        currentPlayer: {
			immediate: true,
			deep: true,
			handler: function() {
                if( this.currentPlayer.persona.cronologia_medica && this.currentPlayer.persona.cronologia_medica.filter((obj) => obj.cronologia_medica_tipo.id == 1).length > 0) {
                    this.anamnesi_medica_selected = this.currentPlayer.persona.cronologia_medica.filter((obj) => obj.cronologia_medica_tipo.id == 1)[0];
                }
			}
		},
    },
                            
}
</script>